import styled from "styled-components";
import Divider from "../../common/components/Divider";
import WhiteContentBox from "../../common/components/WhiteContentBox";
import ChatRanking from "./ChatRanking";

function ChatStats() {
  return (
    <WhiteContentBox>
      <Title>채팅 Top 50</Title>
      <Divider />
      <ChatRanking />
    </WhiteContentBox>
  );
}

const Title = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
}));

export default ChatStats;
