import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  PhoneNumber: any;
  Upload: any;
};

export type Announcement = {
  __typename?: "Announcement";
  active: Scalars["Boolean"];
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  onBanner: Scalars["Boolean"];
  summary?: Maybe<Scalars["String"]>;
  thumbnailMedia?: Maybe<Media>;
  title: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type AnnouncementConnection = {
  __typename?: "AnnouncementConnection";
  edges?: Maybe<Array<Maybe<Announcement>>>;
  pageInfo: PageInfo;
};

export type AnnouncementInput = {
  active: Scalars["Boolean"];
  body: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  onBanner: Scalars["Boolean"];
  summary?: InputMaybe<Scalars["String"]>;
  thumbnailMediaInput?: InputMaybe<MediaInput>;
  title: Scalars["String"];
};

export type Caregiver = Profile & {
  __typename?: "Caregiver";
  avatar?: Maybe<Media>;
  /** Caregiver Fields */
  certificateMedia?: Maybe<Media>;
  certificateNumber?: Maybe<Scalars["String"]>;
  /** Cities */
  cities?: Maybe<Array<Maybe<City>>>;
  createdAt: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["Date"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  gender: Gender;
  id: Scalars["ID"];
  invitationCode?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** User Fields */
  phone: Scalars["String"];
  point: Scalars["Int"];
  /** Receivers */
  receivers?: Maybe<Array<Maybe<Receiver>>>;
  updatedAt: Scalars["DateTime"];
  username?: Maybe<Scalars["String"]>;
};

export type CaregiverCommunity = {
  __typename?: "CaregiverCommunity";
  author: Caregiver;
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  media?: Maybe<Array<Maybe<Media>>>;
  replyCount: Scalars["Int"];
  type?: Maybe<CaregiverCommunityType>;
  updatedAt: Scalars["DateTime"];
};

export type CaregiverCommunityAndReplyCount = {
  __typename?: "CaregiverCommunityAndReplyCount";
  caregiver: Caregiver;
  communityCount: Scalars["Int"];
  communityReplyCount: Scalars["Int"];
  infoReplyCount: Scalars["Int"];
};

export type CaregiverCommunityConnection = {
  __typename?: "CaregiverCommunityConnection";
  edges?: Maybe<Array<Maybe<CaregiverCommunity>>>;
  pageInfo: PageInfo;
};

export type CaregiverCommunityInput = {
  authorId?: InputMaybe<Scalars["ID"]>;
  body: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  mediaInputs?: InputMaybe<Array<InputMaybe<MediaInput>>>;
  type?: InputMaybe<CaregiverCommunityType>;
};

export type CaregiverCommunityReply = {
  __typename?: "CaregiverCommunityReply";
  author: Caregiver;
  body?: Maybe<Scalars["String"]>;
  community: CaregiverCommunity;
  createdAt: Scalars["DateTime"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export type CaregiverCommunityReplyConnection = {
  __typename?: "CaregiverCommunityReplyConnection";
  edges?: Maybe<Array<Maybe<CaregiverCommunityReply>>>;
  pageInfo: PageInfo;
};

export type CaregiverCommunityReplyInput = {
  authorId?: InputMaybe<Scalars["ID"]>;
  body?: InputMaybe<Scalars["String"]>;
  communityId: Scalars["ID"];
  id?: InputMaybe<Scalars["ID"]>;
};

export enum CaregiverCommunityType {
  General = "GENERAL",
  Secret = "SECRET",
}

export type CaregiverConnection = {
  __typename?: "CaregiverConnection";
  edges: Array<Maybe<Caregiver>>;
  pageInfo: PageInfo;
};

export type CaregiverInfo = {
  __typename?: "CaregiverInfo";
  body: Scalars["String"];
  cities?: Maybe<Array<Maybe<City>>>;
  createdAt: Scalars["DateTime"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  media?: Maybe<Array<Maybe<Media>>>;
  replyCount: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type CaregiverInfoConnection = {
  __typename?: "CaregiverInfoConnection";
  edges?: Maybe<Array<Maybe<CaregiverInfo>>>;
  pageInfo: PageInfo;
};

export type CaregiverInfoInput = {
  body: Scalars["String"];
  cityIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["ID"]>;
  mediaInputs?: InputMaybe<Array<InputMaybe<MediaInput>>>;
};

export type CaregiverInfoReply = {
  __typename?: "CaregiverInfoReply";
  author: Caregiver;
  body?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  info: CaregiverInfo;
  updatedAt: Scalars["DateTime"];
};

export type CaregiverInfoReplyConnection = {
  __typename?: "CaregiverInfoReplyConnection";
  edges?: Maybe<Array<Maybe<CaregiverInfoReply>>>;
  pageInfo: PageInfo;
};

export type CaregiverInfoReplyInput = {
  authorId?: InputMaybe<Scalars["ID"]>;
  body?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  infoId: Scalars["ID"];
};

export type CaregiverInput = {
  certificateMediaInput?: InputMaybe<MediaInput>;
  certificateNumber?: InputMaybe<Scalars["String"]>;
};

export type CaregiverReceiver = {
  __typename?: "CaregiverReceiver";
  active: Scalars["Boolean"];
  caregiver: Caregiver;
  chat?: Maybe<Chat>;
  createdAt: Scalars["DateTime"];
  hasUnreadMessage: Scalars["Boolean"];
  receiver: Receiver;
  status: RelationStatus;
  updatedAt: Scalars["DateTime"];
};

export type CaregiverReceiverConnection = {
  __typename?: "CaregiverReceiverConnection";
  edges?: Maybe<Array<Maybe<CaregiverReceiver>>>;
  pageInfo: PageInfo;
};

export type Chat = UpdateData & {
  __typename?: "Chat";
  /** Cache redirection purposes in apollo client */
  chatId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  hasUnreadMessage: Scalars["Boolean"];
  id: Scalars["ID"];
  messageCount: Scalars["Int"];
  receiver: Receiver;
  updatedAt: Scalars["DateTime"];
  users?: Maybe<Array<Maybe<ChatUser>>>;
};

export type ChatConnection = {
  __typename?: "ChatConnection";
  edges?: Maybe<Array<Maybe<Chat>>>;
  pageInfo: PageInfo;
};

export type ChatUser = {
  __typename?: "ChatUser";
  caregiver?: Maybe<Caregiver>;
  chat: Chat;
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  joinAt: Scalars["DateTime"];
  lastSeenAt: Scalars["DateTime"];
};

export type City = {
  __typename?: "City";
  cityCode: Scalars["ID"];
  name: Scalars["String"];
  state: State;
};

export type ExampleQuestion = {
  __typename?: "ExampleQuestion";
  active: Scalars["Boolean"];
  answers?: Maybe<Array<Maybe<ExampleQuestionAnswer>>>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  question: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type ExampleQuestionAnswer = {
  __typename?: "ExampleQuestionAnswer";
  active: Scalars["Boolean"];
  answer: Scalars["String"];
  id: Scalars["ID"];
  question: ExampleQuestion;
};

export type ExampleQuestionAnswerInput = {
  active: Scalars["Boolean"];
  answer: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  questionId: Scalars["ID"];
};

export type ExampleQuestionConnection = {
  __typename?: "ExampleQuestionConnection";
  edges: Array<Maybe<ExampleQuestion>>;
  pageInfo: PageInfo;
};

export type ExmapleQuestionInput = {
  active: Scalars["Boolean"];
  id?: InputMaybe<Scalars["ID"]>;
  question: Scalars["String"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"];
  filename: Scalars["String"];
  mimetype: Scalars["String"];
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type Guardian = Profile & {
  __typename?: "Guardian";
  avatar?: Maybe<Media>;
  createdAt: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["Date"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  gender: Gender;
  id: Scalars["ID"];
  invitationCode?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** User Fields */
  phone: Scalars["String"];
  point: Scalars["Int"];
  /** Receiver */
  receivers?: Maybe<Array<Maybe<Receiver>>>;
  updatedAt: Scalars["DateTime"];
  username?: Maybe<Scalars["String"]>;
};

export type GuardianConnection = {
  __typename?: "GuardianConnection";
  edges: Array<Maybe<Guardian>>;
  pageInfo: PageInfo;
};

export type GuardianReceiver = {
  __typename?: "GuardianReceiver";
  active: Scalars["Boolean"];
  chat?: Maybe<Chat>;
  createdAt: Scalars["DateTime"];
  guardian: Guardian;
  hasUnreadMessage: Scalars["Boolean"];
  receiver: Receiver;
  relation?: Maybe<Relation>;
  status: RelationStatus;
  updatedAt: Scalars["DateTime"];
};

export type GuardianReceiverConnection = {
  __typename?: "GuardianReceiverConnection";
  edges?: Maybe<Array<Maybe<GuardianReceiver>>>;
  pageInfo: PageInfo;
};

export type Management = {
  __typename?: "Management";
  address?: Maybe<Scalars["String"]>;
  ceoName?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  copyright?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  escroRegistration?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  invitationPoint: Scalars["Int"];
  kakaoAddress?: Maybe<Scalars["String"]>;
  minimumTip: Scalars["Int"];
  paymentInformation?: Maybe<Scalars["String"]>;
  paymentWarning?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  registrationNumber?: Maybe<Scalars["String"]>;
  shopAddress?: Maybe<Scalars["String"]>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars["String"]>;
  ceoName?: InputMaybe<Scalars["String"]>;
  companyName?: InputMaybe<Scalars["String"]>;
  copyright?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  escroRegistration?: InputMaybe<Scalars["String"]>;
  invitationPoint: Scalars["Int"];
  kakaoAddress?: InputMaybe<Scalars["String"]>;
  minimumTip: Scalars["Int"];
  paymentInformation?: InputMaybe<Scalars["String"]>;
  paymentWarning?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  registrationNumber?: InputMaybe<Scalars["String"]>;
  shopAddress?: InputMaybe<Scalars["String"]>;
};

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  size: Scalars["Int"];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  uri: Scalars["String"];
};

export type MediaInput = {
  file: Scalars["Upload"];
  thumbnailFile?: InputMaybe<Scalars["Upload"]>;
};

export type Message = SystemMessage | UserMessage;

export type MessageConnection = {
  __typename?: "MessageConnection";
  edges?: Maybe<Array<Maybe<Message>>>;
  pageInfo: PageInfo;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["Boolean"]>;
  confirmCaregiverAsReceiver: Scalars["Boolean"];
  confirmGuardianAsReceiver: Scalars["Boolean"];
  /**
   * phoneNumber is neccessary!!!!
   * And it should be like "+821012345678"
   * the phone field inside profile input is needed to and it should be like 01012345678
   */
  createCaregiverAsAdmin?: Maybe<Caregiver>;
  createCaregiverProfile?: Maybe<Caregiver>;
  /**
   * phoneNumber is neccessary!!!!
   * And it should be like "+821012345678"
   * the phone field inside profile input is needed to and it should be like 01012345678
   */
  createGuardianAsAdmin: Guardian;
  createGuardianProfile?: Maybe<Guardian>;
  createReport: Report;
  createTicket: Ticket;
  createWorkHour: WorkHour;
  deleteCaregiverCity: Caregiver;
  deleteCaregiverCommunity: CaregiverCommunity;
  deleteCaregiverCommunityMedia: CaregiverCommunity;
  deleteCaregiverCommunityReply: CaregiverCommunityReply;
  deleteCaregiverInfoAsAdmin: CaregiverInfo;
  deleteCaregiverInfoMedia: CaregiverInfo;
  deleteCaregiverInfoReply: CaregiverInfoReply;
  deleteReceiverRecord: ReceiverRecord;
  deleteReceiverRecordMedia: ReceiverRecord;
  finishWorkHour: WorkHour;
  joinChatAsCaregiver: Chat;
  joinChatAsGuardian: Chat;
  relateReceiverAsCaregiver: CaregiverReceiver;
  relateReceiverAsGuardian: GuardianReceiver;
  requestServiceAsGuardian: ServiceRequest;
  resign: Scalars["Boolean"];
  resignCaregiverAsAdmin: Scalars["Boolean"];
  resignGuardianAsAdmin: Scalars["Boolean"];
  /**
   * Sends a new message. Publishes updateChat and newMessage.
   * When sending text, attachment is not required.
   * When sending media, text is not required.
   */
  sendMessage: Message;
  unrelateReceiverAsCaregiver: CaregiverReceiver;
  unrelateReceiverAsGuardian: GuardianReceiver;
  /**
   * Update announcement
   * If id is given in announcement input, it will update
   * or it will create a new one
   */
  updateAnnouncementAsAdmin: Announcement;
  updateCaregiverCities: Caregiver;
  updateCaregiverCommunity: CaregiverCommunity;
  updateCaregiverCommunityMedia: CaregiverCommunity;
  updateCaregiverCommunityReply: CaregiverCommunityReply;
  updateCaregiverInfoAsAdmin: CaregiverInfo;
  updateCaregiverInfoMedia: CaregiverInfo;
  updateCaregiverInfoReply: CaregiverInfoReply;
  updateCaregiverProfile?: Maybe<Caregiver>;
  /**
   * Update(if id is not sent, it will create) a example question answer.
   *
   *
   * When question does not exist, you can't create or update answer for the question
   */
  updateExampleQuestionAnswerAsAdmin: ExampleQuestionAnswer;
  /**
   * Update(if id is not sent, it will create) a example question.
   *
   *
   * When creating, must create a question first and then you can create answers for the question
   */
  updateExampleQuestionAsAdmin: ExampleQuestion;
  updateGuardianProfile?: Maybe<Guardian>;
  updateLastSeenAt: Chat;
  updateManagementAsAdmin: Management;
  /**
   *  Update user's device token to send push notification
   *
   * if new token is not provided, it only deletes user's message token
   *
   * if old token is not provided, it only adds the message token for the device
   *
   * else, it updates message token for the device
   */
  updateMessagingTokenAsCaregiver: Scalars["Boolean"];
  /**
   *  Update user's device token to send push notification
   *
   * if new token is not provided, it only deletes user's message token
   *
   * if old token is not provided, it only adds the message token for the device
   *
   * else, it updates message token for the device
   */
  updateMessagingTokenAsGuardian: Scalars["Boolean"];
  updateMyNotificationSetting: NotificationSetting;
  /**
   * When creating a new package,
   * DO NOT SEND 'ID' in package input fields
   */
  updatePackageAsAdmin: Package;
  updatePolicyAsAdmin: Policy;
  updateReceiver: Receiver;
  updateReceiverIssueAsGuardian: Receiver;
  updateReceiverRecord: ReceiverRecord;
  updateReceiverRecordMedia: ReceiverRecord;
  updateRelationAsAdmin: Relation;
  updateReportReasonAsAdmin: ReportReason;
  /**
   * When creating a new service,
   * DO NOT SEND 'ID' in service input fields
   */
  updateServiceAsAdmin: Service;
  updateServiceRequestStatusAsCaregiver: ServiceRequest;
  updateServiceRequestStatusAsGuardian: ServiceRequest;
};

export type MutationConfirmCaregiverAsReceiverArgs = {
  code: Scalars["String"];
  urlId: Scalars["ID"];
};

export type MutationConfirmGuardianAsReceiverArgs = {
  code: Scalars["String"];
  urlId: Scalars["ID"];
};

export type MutationCreateCaregiverAsAdminArgs = {
  caregiverInput?: InputMaybe<CaregiverInput>;
  cityCodes?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  phoneNumber: Scalars["String"];
  profileInput: ProfileInput;
};

export type MutationCreateCaregiverProfileArgs = {
  caregiverInput?: InputMaybe<CaregiverInput>;
  profileInput: ProfileInput;
};

export type MutationCreateGuardianAsAdminArgs = {
  phoneNumber: Scalars["String"];
  profileInput: ProfileInput;
};

export type MutationCreateGuardianProfileArgs = {
  profileInput: ProfileInput;
};

export type MutationCreateReportArgs = {
  reportInput: ReportInput;
};

export type MutationCreateTicketArgs = {
  body: Scalars["String"];
  receiverId: Scalars["ID"];
};

export type MutationCreateWorkHourArgs = {
  receiverId: Scalars["ID"];
};

export type MutationDeleteCaregiverCityArgs = {
  cityCode: Scalars["ID"];
};

export type MutationDeleteCaregiverCommunityArgs = {
  communityId: Scalars["ID"];
};

export type MutationDeleteCaregiverCommunityMediaArgs = {
  communityId: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type MutationDeleteCaregiverCommunityReplyArgs = {
  replyId: Scalars["ID"];
};

export type MutationDeleteCaregiverInfoAsAdminArgs = {
  infoId: Scalars["ID"];
};

export type MutationDeleteCaregiverInfoMediaArgs = {
  infoId: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type MutationDeleteCaregiverInfoReplyArgs = {
  replyId: Scalars["ID"];
};

export type MutationDeleteReceiverRecordArgs = {
  recordId: Scalars["ID"];
};

export type MutationDeleteReceiverRecordMediaArgs = {
  mediaId: Scalars["ID"];
  recordId: Scalars["ID"];
};

export type MutationFinishWorkHourArgs = {
  workHourId: Scalars["ID"];
};

export type MutationJoinChatAsCaregiverArgs = {
  chatId: Scalars["ID"];
};

export type MutationJoinChatAsGuardianArgs = {
  chatId: Scalars["ID"];
};

export type MutationRelateReceiverAsCaregiverArgs = {
  receiverId: Scalars["ID"];
};

export type MutationRelateReceiverAsGuardianArgs = {
  receiverId: Scalars["ID"];
  relationId?: InputMaybe<Scalars["ID"]>;
};

export type MutationRequestServiceAsGuardianArgs = {
  requestServiceInput: RequestServiceInput;
};

export type MutationResignArgs = {
  reason?: InputMaybe<Scalars["String"]>;
};

export type MutationResignCaregiverAsAdminArgs = {
  caregiverId: Scalars["ID"];
};

export type MutationResignGuardianAsAdminArgs = {
  guardianId: Scalars["ID"];
};

export type MutationSendMessageArgs = {
  attachment?: InputMaybe<MediaInput>;
  chatId: Scalars["ID"];
  text?: InputMaybe<Scalars["String"]>;
};

export type MutationUnrelateReceiverAsCaregiverArgs = {
  receiverId: Scalars["ID"];
};

export type MutationUnrelateReceiverAsGuardianArgs = {
  receiverId: Scalars["ID"];
};

export type MutationUpdateAnnouncementAsAdminArgs = {
  announcementInput: AnnouncementInput;
};

export type MutationUpdateCaregiverCitiesArgs = {
  cityCodes: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationUpdateCaregiverCommunityArgs = {
  communityInput: CaregiverCommunityInput;
};

export type MutationUpdateCaregiverCommunityMediaArgs = {
  communityId: Scalars["ID"];
  mediaInputs?: InputMaybe<Array<InputMaybe<MediaInput>>>;
};

export type MutationUpdateCaregiverCommunityReplyArgs = {
  caregiverCommunityReplyInput: CaregiverCommunityReplyInput;
};

export type MutationUpdateCaregiverInfoAsAdminArgs = {
  caregiverInfoInput: CaregiverInfoInput;
};

export type MutationUpdateCaregiverInfoMediaArgs = {
  infoId: Scalars["ID"];
  mediaInputs?: InputMaybe<Array<InputMaybe<MediaInput>>>;
};

export type MutationUpdateCaregiverInfoReplyArgs = {
  caregiverInfoReplyInput: CaregiverInfoReplyInput;
};

export type MutationUpdateCaregiverProfileArgs = {
  caregiverInput?: InputMaybe<CaregiverInput>;
  profileInput: ProfileInput;
};

export type MutationUpdateExampleQuestionAnswerAsAdminArgs = {
  exampleQuestionAnswerInput: ExampleQuestionAnswerInput;
};

export type MutationUpdateExampleQuestionAsAdminArgs = {
  exampleQuestionInput: ExmapleQuestionInput;
};

export type MutationUpdateGuardianProfileArgs = {
  profileInput: ProfileInput;
};

export type MutationUpdateLastSeenAtArgs = {
  chatId: Scalars["ID"];
};

export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};

export type MutationUpdateMessagingTokenAsCaregiverArgs = {
  newToken?: InputMaybe<Scalars["String"]>;
  oldToken?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateMessagingTokenAsGuardianArgs = {
  newToken?: InputMaybe<Scalars["String"]>;
  oldToken?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateMyNotificationSettingArgs = {
  notificationSettingInput: NotificationSettingInput;
};

export type MutationUpdatePackageAsAdminArgs = {
  packageInput: PackageInput;
};

export type MutationUpdatePolicyAsAdminArgs = {
  policyInput: PolicyInput;
};

export type MutationUpdateReceiverArgs = {
  receiverInput: ReceiverInput;
};

export type MutationUpdateReceiverIssueAsGuardianArgs = {
  issue: Scalars["String"];
  receiverId: Scalars["ID"];
};

export type MutationUpdateReceiverRecordArgs = {
  receiverRecordInput: ReceiverRecordInput;
};

export type MutationUpdateReceiverRecordMediaArgs = {
  mediaInputs?: InputMaybe<Array<InputMaybe<MediaInput>>>;
  recordId: Scalars["ID"];
};

export type MutationUpdateRelationAsAdminArgs = {
  relationInput: RelationInput;
};

export type MutationUpdateReportReasonAsAdminArgs = {
  reportReasonInput: ReportReasonInput;
};

export type MutationUpdateServiceAsAdminArgs = {
  serviceInput: ServiceInput;
};

export type MutationUpdateServiceRequestStatusAsCaregiverArgs = {
  serviceRequestId: Scalars["ID"];
  status: ServiceRequestStatus;
};

export type MutationUpdateServiceRequestStatusAsGuardianArgs = {
  serviceRequestId: Scalars["ID"];
  status: ServiceRequestStatus;
};

export type NotificationSetting = {
  __typename?: "NotificationSetting";
  announcement: Scalars["Boolean"];
  caregiver?: Maybe<Caregiver>;
  chat: Scalars["Boolean"];
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
};

export type NotificationSettingInput = {
  announcement: Scalars["Boolean"];
  chat: Scalars["Boolean"];
  id?: InputMaybe<Scalars["ID"]>;
};

export type Package = {
  __typename?: "Package";
  active: Scalars["Boolean"];
  finalPrice: Scalars["Int"];
  id: Scalars["ID"];
  initialPrice: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  point: Scalars["Int"];
};

export type PackageInput = {
  active: Scalars["Boolean"];
  finalPrice: Scalars["Int"];
  id?: InputMaybe<Scalars["ID"]>;
  initialPrice: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
  point: Scalars["Int"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars["String"]>;
  first: Scalars["Int"];
};

export type PointTransaction = {
  __typename?: "PointTransaction";
  amount: Scalars["Int"];
  caregiver?: Maybe<Caregiver>;
  createdAt: Scalars["DateTime"];
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  invitedCaregiver?: Maybe<Caregiver>;
  invitedGuardian?: Maybe<Guardian>;
  purchase?: Maybe<Purchase>;
  serviceRequest?: Maybe<ServiceRequest>;
  type: PointTransactionType;
  updatedAt: Scalars["DateTime"];
};

export type PointTransactionConnection = {
  __typename?: "PointTransactionConnection";
  edges: Array<Maybe<PointTransaction>>;
  pageInfo: PageInfo;
};

export enum PointTransactionType {
  Addition = "ADDITION",
  Subtract = "SUBTRACT",
}

export type Policy = {
  __typename?: "Policy";
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  type: PolicyType;
};

export type PolicyInput = {
  body: Scalars["String"];
  type: PolicyType;
};

export enum PolicyType {
  Privacy = "PRIVACY",
  Terms = "TERMS",
}

export type Profile = {
  avatar?: Maybe<Media>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  email?: Maybe<Scalars["String"]>;
  gender: Gender;
  id: Scalars["ID"];
  invitationCode?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phone: Scalars["String"];
  point: Scalars["Int"];
  username?: Maybe<Scalars["String"]>;
};

export type ProfileInput = {
  avatarInput?: InputMaybe<MediaInput>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  email?: InputMaybe<Scalars["String"]>;
  gender: Gender;
  invitationCode?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type Purchase = {
  __typename?: "Purchase";
  cancelReason?: Maybe<Scalars["String"]>;
  cancelledAt?: Maybe<Scalars["DateTime"]>;
  caregiver?: Maybe<Caregiver>;
  createdAt: Scalars["DateTime"];
  finalPrice: Scalars["Int"];
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  impUid: Scalars["String"];
  initialPrice: Scalars["Int"];
  merchantUid: Scalars["String"];
  method: Scalars["String"];
  paidAt?: Maybe<Scalars["DateTime"]>;
  pgProvider?: Maybe<Scalars["String"]>;
  point?: Maybe<Scalars["Int"]>;
  pointPackage: Package;
  status: PurchaseStatus;
  updatedAt: Scalars["DateTime"];
};

export enum PurchaseStatus {
  Cancelled = "CANCELLED",
  Failed = "FAILED",
  Paid = "PAID",
}

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]>;
  /** Get announcement by given announcement id */
  getAnnouncement: Announcement;
  /**
   * Get announcements
   * It has two options, active and onBanner
   * if activeOnly is given, filter active or not(by default, set it as  null)
   * if onBannerOnly is given, filter on Banner only or not(by default set it as null)
   */
  getAnnouncements: AnnouncementConnection;
  getCaregiverCommunitiesByAuthor: CaregiverCommunityConnection;
  getCaregiverCommunitiesByAuthorAsAdmin: CaregiverCommunityConnection;
  getCaregiverCommunitiesByReplyAuthor: CaregiverCommunityConnection;
  getCaregiverCommunitiesByType: CaregiverCommunityConnection;
  getCaregiverCommunitiesByTypeAsAdmin: CaregiverCommunityConnection;
  getCaregiverCommunity: CaregiverCommunity;
  getCaregiverCommunityRepliesByCommunity: CaregiverCommunityReplyConnection;
  getCaregiverCommunityRepliesByCommunityAsAdmin: CaregiverCommunityReplyConnection;
  getCaregiverInfo: CaregiverInfo;
  getCaregiverInfoRepliesByInfo: CaregiverInfoReplyConnection;
  getCaregiverInfos: CaregiverInfoConnection;
  getCaregiverProfile?: Maybe<Caregiver>;
  getCaregiversAsAdmin: CaregiverConnection;
  getCaregiversCommunityAndReplyCountAsAdmin: CaregiverCommunityAndReplyCount;
  getCertificateNumberFromBase64?: Maybe<Scalars["String"]>;
  getChat: Chat;
  /**
   * Get a list of messages in a given chat. Publishes updateChat to notify users
   * that lastSeenAt timestamp is updated.
   *
   * All notifications attached to this chat will be marked as checked.
   *
   * Supports pagination.
   */
  getChatMessages: MessageConnection;
  getChatsOrderByMessagesCountAsAdmin: ChatConnection;
  getCitiesByState: Array<Maybe<City>>;
  /** Get example questions as admin */
  getExmapleQuestionsAsAdmin: ExampleQuestionConnection;
  getGuardianProfile?: Maybe<Guardian>;
  getGuardiansAsAdmin: GuardianConnection;
  getLatestPolicy: Policy;
  /** This query is for client application and admin both! */
  getManagement: Management;
  getMyCaregiverProfile?: Maybe<Caregiver>;
  getMyGuardianProfile?: Maybe<Guardian>;
  getMyNotificationSetting?: Maybe<NotificationSetting>;
  getMyPointTransactionsAsCaregiver: PointTransactionConnection;
  getMyPointTransactionsAsGuardian: PointTransactionConnection;
  getMyReceiverAsCaregiver: CaregiverReceiver;
  getMyReceiverAsGuardian: GuardianReceiver;
  getMyReceiversAsCaregiver: Array<Maybe<CaregiverReceiver>>;
  getMyReceiversAsGuardian: Array<Maybe<GuardianReceiver>>;
  getMyServiceRequestsAsCaregiver: ServiceRequestConnection;
  getMyServiceRequestsAsGuardian: ServiceRequestConnection;
  getPackage: Package;
  /** Admin can use this query to get packages created */
  getPackages: Array<Maybe<Package>>;
  /**
   * This is used if you wanna show or see policy records by type
   * For now, it can be used in the admin console, but later you can use this in the client app
   */
  getPolicies: Array<Maybe<Policy>>;
  getPurchase?: Maybe<Purchase>;
  /** Get random example question to help leaving a record of a receiver */
  getRandomExampleQuestion: ExampleQuestion;
  getReceiver: Receiver;
  getReceiverRecord: ReceiverRecord;
  getReceiverRecords: ReceiverRecordConnection;
  getReceiverRecordsAsAdmin: ReceiverRecordConnection;
  getReceiversAsAdmin: ReceiverConnection;
  getRegistrationNumberFromBase64?: Maybe<Scalars["String"]>;
  getRelationWithReceiverAsGuardian: GuardianReceiver;
  getRelations: Array<Maybe<Relation>>;
  getReportReasons?: Maybe<Array<Maybe<ReportReason>>>;
  getReportsAsAdmin: ReportConnection;
  getResignedUsersAsAdmin: ResignationConnect;
  getService: Service;
  getServiceRequest: ServiceRequest;
  getServices: Array<Maybe<Service>>;
  getStates: Array<Maybe<State>>;
  getTicketsAsAdmin: TicketConnection;
  getTodaysWorkHour?: Maybe<WorkHour>;
  getUserCountsAsAdmin: UserCounts;
  getUserPointTransactionsAsAdmin: PointTransactionConnection;
  getWorkHours: WorkHourConnection;
  getWorkHoursAsAdmin: WorkHourConnection;
  searchCaregiversAsAdmin: CaregiverConnection;
  searchGuardiansAsAdmin: GuardianConnection;
  searchReceivers: ReceiverConnection;
  searchReceiversAsAdmin: ReceiverConnection;
};

export type QueryGetAnnouncementArgs = {
  announcementId: Scalars["ID"];
};

export type QueryGetAnnouncementsArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  onBanner?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetCaregiverCommunitiesByAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  caregiverId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaregiverCommunitiesByAuthorAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  caregiverId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaregiverCommunitiesByReplyAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  caregiverId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaregiverCommunitiesByTypeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<CaregiverCommunityType>;
};

export type QueryGetCaregiverCommunitiesByTypeAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  range?: InputMaybe<RangeInput>;
  type?: InputMaybe<CaregiverCommunityType>;
};

export type QueryGetCaregiverCommunityArgs = {
  communityId: Scalars["ID"];
};

export type QueryGetCaregiverCommunityRepliesByCommunityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  communityId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaregiverCommunityRepliesByCommunityAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  communityId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaregiverInfoArgs = {
  infoId: Scalars["ID"];
};

export type QueryGetCaregiverInfoRepliesByInfoArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  infoId: Scalars["ID"];
};

export type QueryGetCaregiverInfosArgs = {
  after?: InputMaybe<Scalars["String"]>;
  cityCodes?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCaregiverProfileArgs = {
  caregiverId: Scalars["ID"];
};

export type QueryGetCaregiversAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
};

export type QueryGetCaregiversCommunityAndReplyCountAsAdminArgs = {
  caregiverId: Scalars["ID"];
};

export type QueryGetCertificateNumberFromBase64Args = {
  base64: Scalars["String"];
  format: Scalars["String"];
  name: Scalars["String"];
};

export type QueryGetChatArgs = {
  chatId: Scalars["ID"];
};

export type QueryGetChatMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  chatId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetChatsOrderByMessagesCountAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetCitiesByStateArgs = {
  stateCode: Scalars["ID"];
};

export type QueryGetExmapleQuestionsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetGuardianProfileArgs = {
  guardianId: Scalars["ID"];
};

export type QueryGetGuardiansAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
};

export type QueryGetLatestPolicyArgs = {
  type: PolicyType;
};

export type QueryGetMyPointTransactionsAsCaregiverArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetMyPointTransactionsAsGuardianArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetMyReceiverAsCaregiverArgs = {
  receiverId: Scalars["ID"];
};

export type QueryGetMyReceiverAsGuardianArgs = {
  receiverId: Scalars["ID"];
};

export type QueryGetMyServiceRequestsAsCaregiverArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  receiverId: Scalars["ID"];
  status?: InputMaybe<Array<InputMaybe<ServiceRequestStatus>>>;
};

export type QueryGetMyServiceRequestsAsGuardianArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  receiverId: Scalars["ID"];
  status?: InputMaybe<Array<InputMaybe<ServiceRequestStatus>>>;
};

export type QueryGetPackageArgs = {
  packageId: Scalars["ID"];
};

export type QueryGetPoliciesArgs = {
  type: PolicyType;
};

export type QueryGetPurchaseArgs = {
  purchaseId: Scalars["ID"];
};

export type QueryGetRandomExampleQuestionArgs = {
  excludeId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetReceiverArgs = {
  receiverId: Scalars["ID"];
};

export type QueryGetReceiverRecordArgs = {
  recordId: Scalars["ID"];
};

export type QueryGetReceiverRecordsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  range: RangeInput;
  receiverId: Scalars["ID"];
};

export type QueryGetReceiverRecordsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  receiverId: Scalars["ID"];
};

export type QueryGetReceiversAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  cityId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
  stateId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetRegistrationNumberFromBase64Args = {
  base64: Scalars["String"];
  format: Scalars["String"];
  name: Scalars["String"];
};

export type QueryGetRelationWithReceiverAsGuardianArgs = {
  receiverId: Scalars["ID"];
};

export type QueryGetReportReasonsArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<ReportReasonType>;
};

export type QueryGetReportsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetResignedUsersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<ResignationUserType>;
};

export type QueryGetServiceArgs = {
  serviceId: Scalars["ID"];
};

export type QueryGetServiceRequestArgs = {
  serviceRequestId: Scalars["ID"];
};

export type QueryGetTicketsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  receiverId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetTodaysWorkHourArgs = {
  range: RangeInput;
  receiverId: Scalars["ID"];
};

export type QueryGetUserCountsAsAdminArgs = {
  range?: InputMaybe<RangeInput>;
};

export type QueryGetUserPointTransactionsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  caregiverId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  guardianId?: InputMaybe<Scalars["ID"]>;
  range?: InputMaybe<RangeInput>;
};

export type QueryGetWorkHoursArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  range: RangeInput;
  receiverId: Scalars["ID"];
};

export type QueryGetWorkHoursAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  caregiverId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  range?: InputMaybe<RangeInput>;
  receiverId?: InputMaybe<Scalars["ID"]>;
};

export type QuerySearchCaregiversAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
  query?: InputMaybe<Scalars["String"]>;
};

export type QuerySearchGuardiansAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
  query: Scalars["String"];
};

export type QuerySearchReceiversArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QuerySearchReceiversAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
  query: Scalars["String"];
};

export type RangeInput = {
  from: Scalars["DateTime"];
  to: Scalars["DateTime"];
};

export type Receiver = {
  __typename?: "Receiver";
  address?: Maybe<Scalars["String"]>;
  addressDetail?: Maybe<Scalars["String"]>;
  caregivers?: Maybe<Array<Maybe<CaregiverReceiver>>>;
  city?: Maybe<City>;
  createdAt: Scalars["DateTime"];
  dateOfBirth: Scalars["Date"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  gender: Gender;
  guardians?: Maybe<Array<Maybe<GuardianReceiver>>>;
  id: Scalars["ID"];
  issue?: Maybe<Scalars["String"]>;
  lastRecordAt?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  registrationMedia?: Maybe<Media>;
  registrationNumber?: Maybe<Scalars["String"]>;
  state?: Maybe<State>;
  updatedAt: Scalars["DateTime"];
};

export type ReceiverConnection = {
  __typename?: "ReceiverConnection";
  edges?: Maybe<Array<Maybe<Receiver>>>;
  pageInfo: PageInfo;
};

export type ReceiverInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDetail?: InputMaybe<Scalars["String"]>;
  dateOfBirth: Scalars["Date"];
  gender: Gender;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  registrationMediaInput?: InputMaybe<MediaInput>;
  registrationNumber?: InputMaybe<Scalars["String"]>;
};

export type ReceiverRecord = {
  __typename?: "ReceiverRecord";
  author: Caregiver;
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  media?: Maybe<Array<Maybe<Media>>>;
  receiver: Receiver;
  updatedAt: Scalars["DateTime"];
};

export type ReceiverRecordConnection = {
  __typename?: "ReceiverRecordConnection";
  edges?: Maybe<Array<Maybe<ReceiverRecord>>>;
  pageInfo: PageInfo;
};

export type ReceiverRecordInput = {
  authorId?: InputMaybe<Scalars["ID"]>;
  body: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  mediaInputs?: InputMaybe<Array<InputMaybe<MediaInput>>>;
  receiverId?: InputMaybe<Scalars["ID"]>;
};

export type Relation = {
  __typename?: "Relation";
  active: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  sort: Scalars["Int"];
};

export type RelationInput = {
  active: Scalars["Boolean"];
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  sort: Scalars["Int"];
};

export enum RelationStatus {
  Confirmed = "CONFIRMED",
  Waiting = "WAITING",
}

export type Report = {
  __typename?: "Report";
  caregiver?: Maybe<Caregiver>;
  community?: Maybe<CaregiverCommunity>;
  communityReply?: Maybe<CaregiverCommunityReply>;
  createdAt: Scalars["DateTime"];
  customReason?: Maybe<Scalars["String"]>;
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  infoReply?: Maybe<CaregiverInfoReply>;
  reason?: Maybe<ReportReason>;
  reportedCaregiver?: Maybe<Caregiver>;
  updatedAt: Scalars["DateTime"];
};

export type ReportConnection = {
  __typename?: "ReportConnection";
  edges?: Maybe<Array<Maybe<Report>>>;
  pageInfo: PageInfo;
};

export type ReportInput = {
  communityId?: InputMaybe<Scalars["ID"]>;
  communityReplyId?: InputMaybe<Scalars["ID"]>;
  customReason?: InputMaybe<Scalars["String"]>;
  infoReplyId?: InputMaybe<Scalars["ID"]>;
  reasonId?: InputMaybe<Scalars["ID"]>;
  reportedCaregiverId?: InputMaybe<Scalars["ID"]>;
};

export type ReportReason = {
  __typename?: "ReportReason";
  active: Scalars["Boolean"];
  id: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
  type: ReportReasonType;
};

export type ReportReasonInput = {
  active: Scalars["Boolean"];
  id?: InputMaybe<Scalars["ID"]>;
  reason?: InputMaybe<Scalars["String"]>;
  type: ReportReasonType;
};

/**
 * Caregiver = 사용자 차단
 * CaregiverCommunity = 게시판 게시물 신고 (대나무숲/일상주제)
 * CaregiverCommunityReply = 게시말 게시물 댓글 신고
 * CaregiverInfoReply = 정보글 댓글
 */
export enum ReportReasonType {
  Caregiver = "CAREGIVER",
  CaregiverCommunity = "CAREGIVER_COMMUNITY",
  CaregiverCommunityReply = "CAREGIVER_COMMUNITY_REPLY",
  CaregiverInfoReply = "CAREGIVER_INFO_REPLY",
}

export type RequestServiceInput = {
  caregiverId: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  receiverId: Scalars["ID"];
  serviceAt?: InputMaybe<Scalars["DateTime"]>;
  serviceId?: InputMaybe<Scalars["ID"]>;
  type: ServiceRequestType;
};

export type Resignation = {
  __typename?: "Resignation";
  caregiver?: Maybe<Caregiver>;
  createdAt: Scalars["DateTime"];
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type ResignationConnect = {
  __typename?: "ResignationConnect";
  edges?: Maybe<Array<Maybe<Resignation>>>;
  pageInfo: PageInfo;
};

export enum ResignationUserType {
  Caregiver = "Caregiver",
  Guardian = "Guardian",
}

export type Service = {
  __typename?: "Service";
  active: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  price: Scalars["Int"];
  summary: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type ServiceInput = {
  active: Scalars["Boolean"];
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  price: Scalars["Int"];
  summary: Scalars["String"];
};

export type ServiceRequest = {
  __typename?: "ServiceRequest";
  caregiver: Caregiver;
  createdAt: Scalars["DateTime"];
  guardian: Guardian;
  id: Scalars["ID"];
  message?: Maybe<Scalars["String"]>;
  price: Scalars["Int"];
  receiver: Receiver;
  service?: Maybe<Service>;
  serviceAt?: Maybe<Scalars["DateTime"]>;
  status: ServiceRequestStatus;
  type: ServiceRequestType;
  updatedAt: Scalars["DateTime"];
};

export type ServiceRequestConnection = {
  __typename?: "ServiceRequestConnection";
  edges: Array<Maybe<ServiceRequest>>;
  pageInfo: PageInfo;
};

export enum ServiceRequestStatus {
  ServiceAccepted = "SERVICE_ACCEPTED",
  ServiceCancelled = "SERVICE_CANCELLED",
  ServiceDone = "SERVICE_DONE",
  ServiceRejected = "SERVICE_REJECTED",
  ServiceReminded = "SERVICE_REMINDED",
  ServiceRequested = "SERVICE_REQUESTED",
  TipAccepted = "TIP_ACCEPTED",
  TipCancelled = "TIP_CANCELLED",
  TipRejected = "TIP_REJECTED",
  TipRequested = "TIP_REQUESTED",
}

export enum ServiceRequestType {
  Service = "SERVICE",
  Tip = "TIP",
}

export type State = {
  __typename?: "State";
  name: Scalars["String"];
  stateCode: Scalars["ID"];
};

export type Subscription = {
  __typename?: "Subscription";
  _empty?: Maybe<Scalars["String"]>;
  /** Published whenever a new message is sent in a chat. */
  newMessage?: Maybe<Message>;
  /**
   * This subscription publishes CaregiverReceiver / GuardianReceiver / Chat depending
   * on the update made.
   *
   * CaregiverReceiver:
   *   - When CaregiverReceiver is updated, updatedAt and hasUpdate will be udpated.
   *   - When a message is received, updatedAt and hasUnreadMessage are
   *   updated so that dashboard lists can stay in order,
   *   and display existance of unread messages.
   *
   * GuardianReceiver:
   *   - When CaregiverReceiver is updated, updatedAt and hasUpdate will be udpated.
   *   - When a message is received, updatedAt and hasUnreadMessage are
   *   updated so that dashboard lists can stay in order,
   *   and display existance of unread messages.
   *
   * Chat:
   *   - When a new message is sent or users read chat messages,
   *     hasUnreadMessage and time information are updated.
   */
  unifiedUpdate: UnifiedUpdate;
  /** Published whenever a new message is sent or a user reads unread messages. */
  updateChat?: Maybe<Chat>;
  /** Published whenever a user reads unread messages or when new user joins. */
  updateChatUser?: Maybe<ChatUser>;
};

export type SubscriptionNewMessageArgs = {
  chatId: Scalars["ID"];
};

export type SystemMessage = {
  __typename?: "SystemMessage";
  caregiver?: Maybe<Caregiver>;
  chat: Chat;
  createdAt: Scalars["DateTime"];
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  media?: Maybe<Media>;
  serviceRequest?: Maybe<ServiceRequest>;
  text?: Maybe<Scalars["String"]>;
};

export type Ticket = {
  __typename?: "Ticket";
  body: Scalars["String"];
  caregiver?: Maybe<Caregiver>;
  createdAt: Scalars["DateTime"];
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  receiver: Receiver;
  updatedAt: Scalars["DateTime"];
};

export type TicketConnection = {
  __typename?: "TicketConnection";
  edges?: Maybe<Array<Maybe<Ticket>>>;
  pageInfo: PageInfo;
};

export type UnifiedUpdate = CaregiverReceiver | Chat | GuardianReceiver;

export type UpdateData = {
  id: Scalars["ID"];
};

export type UserCounts = {
  __typename?: "UserCounts";
  caregiverCount: Scalars["Int"];
  guardianCount: Scalars["Int"];
  receiverCount: Scalars["Int"];
  resignedUsersCount: Scalars["Int"];
};

export type UserMessage = {
  __typename?: "UserMessage";
  caregiver?: Maybe<Caregiver>;
  chat: Chat;
  createdAt: Scalars["DateTime"];
  guardian?: Maybe<Guardian>;
  id: Scalars["ID"];
  media?: Maybe<Media>;
  text?: Maybe<Scalars["String"]>;
};

export type WorkHour = {
  __typename?: "WorkHour";
  caregiver: Caregiver;
  createdAt: Scalars["DateTime"];
  from?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  receiver: Receiver;
  to?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

export type WorkHourConnection = {
  __typename?: "WorkHourConnection";
  edges?: Maybe<Array<Maybe<WorkHour>>>;
  pageInfo: PageInfo;
};

export type GetAnnouncementQueryVariables = Exact<{
  announcementId: Scalars["ID"];
}>;

export type GetAnnouncementQuery = {
  __typename?: "Query";
  getAnnouncement: {
    __typename?: "Announcement";
    id: string;
    title: string;
    summary?: string | null;
    body: string;
    active: boolean;
    onBanner: boolean;
    createdAt: any;
    updatedAt: any;
    thumbnailMedia?: { __typename?: "Media"; id: string; uri: string } | null;
  };
};

export type GetAnnouncementsQueryVariables = Exact<{
  active?: InputMaybe<Scalars["Boolean"]>;
  onBanner?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetAnnouncementsQuery = {
  __typename?: "Query";
  getAnnouncements: {
    __typename?: "AnnouncementConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "Announcement";
      id: string;
      title: string;
      summary?: string | null;
      body: string;
      active: boolean;
      onBanner: boolean;
      createdAt: any;
      updatedAt: any;
      thumbnailMedia?: { __typename?: "Media"; id: string; uri: string } | null;
    } | null> | null;
  };
};

export type UpdateAnnouncementAsAdminMutationVariables = Exact<{
  announcementInput: AnnouncementInput;
}>;

export type UpdateAnnouncementAsAdminMutation = {
  __typename?: "Mutation";
  updateAnnouncementAsAdmin: {
    __typename?: "Announcement";
    id: string;
    title: string;
    summary?: string | null;
    body: string;
    active: boolean;
    onBanner: boolean;
    createdAt: any;
    updatedAt: any;
    thumbnailMedia?: { __typename?: "Media"; id: string; uri: string } | null;
  };
};

export type GetCaregiversAsAdminQueryVariables = Exact<{
  gender?: InputMaybe<Gender>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetCaregiversAsAdminQuery = {
  __typename?: "Query";
  getCaregiversAsAdmin: {
    __typename?: "CaregiverConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: "Caregiver";
      id: string;
      phone: string;
      gender: Gender;
      point: number;
      name: string;
      username?: string | null;
      dateOfBirth?: any | null;
      certificateNumber?: string | null;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      certificateMedia?: {
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null;
    } | null>;
  };
};

export type GetCaregiverProfileQueryVariables = Exact<{
  caregiverId: Scalars["ID"];
}>;

export type GetCaregiverProfileQuery = {
  __typename?: "Query";
  getCaregiverProfile?: {
    __typename?: "Caregiver";
    id: string;
    phone: string;
    email?: string | null;
    gender: Gender;
    name: string;
    username?: string | null;
    dateOfBirth?: any | null;
    point: number;
    certificateNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
      size: number;
      type: string;
      createdAt: any;
      thumbnailUri?: string | null;
    } | null;
    certificateMedia?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
      size: number;
      type: string;
      createdAt: any;
      thumbnailUri?: string | null;
    } | null;
    receivers?: Array<{
      __typename?: "Receiver";
      id: string;
      name: string;
      phone?: string | null;
      registrationNumber?: string | null;
      dateOfBirth: any;
      gender: Gender;
      address?: string | null;
      addressDetail?: string | null;
      guardians?: Array<{
        __typename?: "GuardianReceiver";
        guardian: {
          __typename?: "Guardian";
          id: string;
          phone: string;
          name: string;
          email?: string | null;
          gender: Gender;
          username?: string | null;
          dateOfBirth?: any | null;
          point: number;
          avatar?: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
            size: number;
            type: string;
            createdAt: any;
            thumbnailUri?: string | null;
          } | null;
        };
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateCaregiverAsAdminMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  profileInput: ProfileInput;
  cityCodes?: InputMaybe<Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>>;
  caregiverInput?: InputMaybe<CaregiverInput>;
}>;

export type CreateCaregiverAsAdminMutation = {
  __typename?: "Mutation";
  createCaregiverAsAdmin?: { __typename?: "Caregiver"; id: string; name: string } | null;
};

export type ResignCaregiverAsAdminMutationVariables = Exact<{
  caregiverId: Scalars["ID"];
}>;

export type ResignCaregiverAsAdminMutation = { __typename?: "Mutation"; resignCaregiverAsAdmin: boolean };

export type GetWorkHoursAsAdminQueryVariables = Exact<{
  receiverId?: InputMaybe<Scalars["ID"]>;
  caregiverId?: InputMaybe<Scalars["ID"]>;
  range?: InputMaybe<RangeInput>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetWorkHoursAsAdminQuery = {
  __typename?: "Query";
  getWorkHoursAsAdmin: {
    __typename?: "WorkHourConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "WorkHour";
      id: string;
      createdAt: any;
      updatedAt: any;
      caregiver: {
        __typename?: "Caregiver";
        id: string;
        phone: string;
        gender: Gender;
        name: string;
        point: number;
        createdAt: any;
        updatedAt: any;
      };
      receiver: {
        __typename?: "Receiver";
        id: string;
        name: string;
        dateOfBirth: any;
        gender: Gender;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
        registrationNumber?: string | null;
        phone?: string | null;
      };
    } | null> | null;
  };
};

export type SearchCaregiversAsAdminQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type SearchCaregiversAsAdminQuery = {
  __typename?: "Query";
  searchCaregiversAsAdmin: {
    __typename?: "CaregiverConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: "Caregiver";
      id: string;
      phone: string;
      gender: Gender;
      point: number;
      name: string;
      username?: string | null;
      dateOfBirth?: any | null;
      certificateNumber?: string | null;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      certificateMedia?: {
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null;
    } | null>;
  };
};

export type GetChatsOrderByMessagesCountAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetChatsOrderByMessagesCountAsAdminQuery = {
  __typename?: "Query";
  getChatsOrderByMessagesCountAsAdmin: {
    __typename?: "ChatConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "Chat";
      id: string;
      chatId: string;
      createdAt: any;
      updatedAt: any;
      messageCount: number;
      hasUnreadMessage: boolean;
      receiver: {
        __typename?: "Receiver";
        id: string;
        name: string;
        dateOfBirth: any;
        gender: Gender;
        createdAt: any;
        updatedAt: any;
      };
      users?: Array<{
        __typename?: "ChatUser";
        id: string;
        lastSeenAt: any;
        joinAt: any;
        caregiver?: {
          __typename?: "Caregiver";
          id: string;
          phone: string;
          gender: Gender;
          name: string;
          point: number;
          createdAt: any;
          updatedAt: any;
          deletedAt?: any | null;
        } | null;
        guardian?: {
          __typename?: "Guardian";
          id: string;
          phone: string;
          gender: Gender;
          name: string;
          point: number;
          createdAt: any;
          updatedAt: any;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};

export type GetCaregiverCommunitiesByTypeAsAdminQueryVariables = Exact<{
  type?: InputMaybe<CaregiverCommunityType>;
  range?: InputMaybe<RangeInput>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetCaregiverCommunitiesByTypeAsAdminQuery = {
  __typename?: "Query";
  getCaregiverCommunitiesByTypeAsAdmin: {
    __typename?: "CaregiverCommunityConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "CaregiverCommunity";
      id: string;
      body: string;
      type?: CaregiverCommunityType | null;
      replyCount: number;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      author: {
        __typename?: "Caregiver";
        name: string;
        gender: Gender;
        email?: string | null;
        phone: string;
        id: string;
        point: number;
        updatedAt: any;
        createdAt: any;
        deletedAt?: any | null;
        avatar?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null;
      };
      media?: Array<{
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export type GetCaregiverCommunitiesByAuthorAsAdminQueryVariables = Exact<{
  caregiverId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetCaregiverCommunitiesByAuthorAsAdminQuery = {
  __typename?: "Query";
  getCaregiverCommunitiesByAuthorAsAdmin: {
    __typename?: "CaregiverCommunityConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "CaregiverCommunity";
      id: string;
      body: string;
      type?: CaregiverCommunityType | null;
      replyCount: number;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      author: {
        __typename?: "Caregiver";
        id: string;
        phone: string;
        gender: Gender;
        name: string;
        username?: string | null;
        dateOfBirth?: any | null;
        point: number;
        certificateNumber?: string | null;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
        avatar?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null;
      };
      media?: Array<{
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export type GetCaregiverCommunityRepliesByCommunityAsAdminQueryVariables = Exact<{
  communityId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetCaregiverCommunityRepliesByCommunityAsAdminQuery = {
  __typename?: "Query";
  getCaregiverCommunityRepliesByCommunityAsAdmin: {
    __typename?: "CaregiverCommunityReplyConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "CaregiverCommunityReply";
      id: string;
      updatedAt: any;
      deletedAt?: any | null;
      createdAt: any;
      body?: string | null;
      author: {
        __typename?: "Caregiver";
        id: string;
        phone: string;
        gender: Gender;
        name: string;
        point: number;
        createdAt: any;
        updatedAt: any;
        avatar?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null;
      };
    } | null> | null;
  };
};

export type DeleteCaregiverCommunityMutationVariables = Exact<{
  communityId: Scalars["ID"];
}>;

export type DeleteCaregiverCommunityMutation = {
  __typename?: "Mutation";
  deleteCaregiverCommunity: {
    __typename?: "CaregiverCommunity";
    id: string;
    body: string;
    type?: CaregiverCommunityType | null;
    replyCount: number;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    author: {
      __typename?: "Caregiver";
      id: string;
      phone: string;
      gender: Gender;
      name: string;
      username?: string | null;
      dateOfBirth?: any | null;
      point: number;
      certificateNumber?: string | null;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
    };
  };
};

export type GetGuardianProfileQueryVariables = Exact<{
  guardianId: Scalars["ID"];
}>;

export type GetGuardianProfileQuery = {
  __typename?: "Query";
  getGuardianProfile?: {
    __typename?: "Guardian";
    id: string;
    phone: string;
    email?: string | null;
    gender: Gender;
    name: string;
    username?: string | null;
    dateOfBirth?: any | null;
    point: number;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
      size: number;
      type: string;
      createdAt: any;
      thumbnailUri?: string | null;
    } | null;
    receivers?: Array<{
      __typename?: "Receiver";
      id: string;
      name: string;
      phone?: string | null;
      gender: Gender;
      address?: string | null;
      dateOfBirth: any;
      caregivers?: Array<{
        __typename?: "CaregiverReceiver";
        caregiver: {
          __typename?: "Caregiver";
          id: string;
          phone: string;
          email?: string | null;
          gender: Gender;
          name: string;
          username?: string | null;
          dateOfBirth?: any | null;
          point: number;
          avatar?: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
            size: number;
            type: string;
            createdAt: any;
            thumbnailUri?: string | null;
          } | null;
        };
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SearchGuardiansAsAdminQueryVariables = Exact<{
  query: Scalars["String"];
  gender?: InputMaybe<Gender>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type SearchGuardiansAsAdminQuery = {
  __typename?: "Query";
  searchGuardiansAsAdmin: {
    __typename?: "GuardianConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: "Guardian";
      id: string;
      phone: string;
      email?: string | null;
      gender: Gender;
      name: string;
      username?: string | null;
      dateOfBirth?: any | null;
      point: number;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      avatar?: {
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null;
    } | null>;
  };
};

export type GetGuardiansAsAdminQueryVariables = Exact<{
  gender?: InputMaybe<Gender>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetGuardiansAsAdminQuery = {
  __typename?: "Query";
  getGuardiansAsAdmin: {
    __typename?: "GuardianConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: "Guardian";
      id: string;
      phone: string;
      email?: string | null;
      gender: Gender;
      name: string;
      username?: string | null;
      dateOfBirth?: any | null;
      point: number;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      avatar?: {
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null;
    } | null>;
  };
};

export type ResignGuardianAsAdminMutationVariables = Exact<{
  guardianId: Scalars["ID"];
}>;

export type ResignGuardianAsAdminMutation = { __typename?: "Mutation"; resignGuardianAsAdmin: boolean };

export type CreateGuardianAsAdminMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  profileInput: ProfileInput;
}>;

export type CreateGuardianAsAdminMutation = {
  __typename?: "Mutation";
  createGuardianAsAdmin: {
    __typename?: "Guardian";
    id: string;
    phone: string;
    gender: Gender;
    name: string;
    point: number;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateCaregiverInfoAsAdminMutationVariables = Exact<{
  caregiverInfoInput: CaregiverInfoInput;
}>;

export type UpdateCaregiverInfoAsAdminMutation = {
  __typename?: "Mutation";
  updateCaregiverInfoAsAdmin: {
    __typename?: "CaregiverInfo";
    id: string;
    body: string;
    createdAt: any;
    media?: Array<{ __typename?: "Media"; id: string; uri: string } | null> | null;
    cities?: Array<{
      __typename?: "City";
      cityCode: string;
      name: string;
      state: { __typename?: "State"; stateCode: string; name: string };
    } | null> | null;
  };
};

export type GetCaregiverInfosQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  cityCodes?: InputMaybe<Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>>;
}>;

export type GetCaregiverInfosQuery = {
  __typename?: "Query";
  getCaregiverInfos: {
    __typename?: "CaregiverInfoConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "CaregiverInfo";
      id: string;
      body: string;
      replyCount: number;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      media?: Array<{ __typename?: "Media"; id: string; uri: string } | null> | null;
      cities?: Array<{
        __typename?: "City";
        cityCode: string;
        name: string;
        state: { __typename?: "State"; stateCode: string; name: string };
      } | null> | null;
    } | null> | null;
  };
};

export type GetCaregiverInfoQueryVariables = Exact<{
  infoId: Scalars["ID"];
}>;

export type GetCaregiverInfoQuery = {
  __typename?: "Query";
  getCaregiverInfo: {
    __typename?: "CaregiverInfo";
    id: string;
    body: string;
    replyCount: number;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    media?: Array<{ __typename?: "Media"; id: string; uri: string } | null> | null;
    cities?: Array<{
      __typename?: "City";
      cityCode: string;
      name: string;
      state: { __typename?: "State"; stateCode: string; name: string };
    } | null> | null;
  };
};

export type DeleteCaregiverInfoAsAdminMutationVariables = Exact<{
  infoId: Scalars["ID"];
}>;

export type DeleteCaregiverInfoAsAdminMutation = {
  __typename?: "Mutation";
  deleteCaregiverInfoAsAdmin: {
    __typename?: "CaregiverInfo";
    id: string;
    body: string;
    replyCount: number;
    createdAt: any;
    updatedAt: any;
  };
};

export type DeleteCaregiverInfoMediaMutationVariables = Exact<{
  infoId: Scalars["ID"];
  mediaId: Scalars["ID"];
}>;

export type DeleteCaregiverInfoMediaMutation = {
  __typename?: "Mutation";
  deleteCaregiverInfoMedia: {
    __typename?: "CaregiverInfo";
    id: string;
    media?: Array<{ __typename?: "Media"; id: string; uri: string } | null> | null;
  };
};

export type GetStatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatesQuery = {
  __typename?: "Query";
  getStates: Array<{ __typename?: "State"; stateCode: string; name: string } | null>;
};

export type GetCitiesByStateQueryVariables = Exact<{
  stateCode: Scalars["ID"];
}>;

export type GetCitiesByStateQuery = {
  __typename?: "Query";
  getCitiesByState: Array<{ __typename?: "City"; cityCode: string; name: string } | null>;
};

export type GetLatestPolicyQueryVariables = Exact<{
  type: PolicyType;
}>;

export type GetLatestPolicyQuery = {
  __typename?: "Query";
  getLatestPolicy: { __typename?: "Policy"; id: string; type: PolicyType; body: string; createdAt: any };
};

export type UpdatePolicyAsAdminMutationVariables = Exact<{
  policyInput: PolicyInput;
}>;

export type UpdatePolicyAsAdminMutation = {
  __typename?: "Mutation";
  updatePolicyAsAdmin: { __typename?: "Policy"; id: string; type: PolicyType; body: string; createdAt: any };
};

export type GetManagementQueryVariables = Exact<{ [key: string]: never }>;

export type GetManagementQuery = {
  __typename?: "Query";
  getManagement: {
    __typename?: "Management";
    id: string;
    companyName?: string | null;
    address?: string | null;
    copyright?: string | null;
    email?: string | null;
    ceoName?: string | null;
    registrationNumber?: string | null;
    escroRegistration?: string | null;
    phone?: string | null;
    kakaoAddress?: string | null;
    minimumTip: number;
    invitationPoint: number;
    shopAddress?: string | null;
  };
};

export type UpdateManagementAsAdminMutationVariables = Exact<{
  managementInput: ManagementInput;
}>;

export type UpdateManagementAsAdminMutation = {
  __typename?: "Mutation";
  updateManagementAsAdmin: {
    __typename?: "Management";
    id: string;
    companyName?: string | null;
    address?: string | null;
    copyright?: string | null;
    email?: string | null;
    ceoName?: string | null;
    registrationNumber?: string | null;
    escroRegistration?: string | null;
    phone?: string | null;
    kakaoAddress?: string | null;
    minimumTip: number;
    invitationPoint: number;
    shopAddress?: string | null;
  };
};

export type GetPoliciesQueryVariables = Exact<{
  type: PolicyType;
}>;

export type GetPoliciesQuery = {
  __typename?: "Query";
  getPolicies: Array<{ __typename?: "Policy"; id: string; type: PolicyType; body: string; createdAt: any } | null>;
};

export type GetPackagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPackagesQuery = {
  __typename?: "Query";
  getPackages: Array<{
    __typename?: "Package";
    id: string;
    point: number;
    initialPrice: number;
    finalPrice: number;
    name?: string | null;
    active: boolean;
  } | null>;
};

export type UpdatePackageAsAdminMutationVariables = Exact<{
  packageInput: PackageInput;
}>;

export type UpdatePackageAsAdminMutation = {
  __typename?: "Mutation";
  updatePackageAsAdmin: {
    __typename?: "Package";
    id: string;
    point: number;
    initialPrice: number;
    finalPrice: number;
    name?: string | null;
    active: boolean;
  };
};

export type GetPackageQueryVariables = Exact<{
  packageId: Scalars["ID"];
}>;

export type GetPackageQuery = {
  __typename?: "Query";
  getPackage: {
    __typename?: "Package";
    id: string;
    point: number;
    initialPrice: number;
    finalPrice: number;
    name?: string | null;
    active: boolean;
  };
};

export type GetUserPointTransactionsAsAdminQueryVariables = Exact<{
  caregiverId?: InputMaybe<Scalars["ID"]>;
  guardianId?: InputMaybe<Scalars["ID"]>;
  range?: InputMaybe<RangeInput>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserPointTransactionsAsAdminQuery = {
  __typename?: "Query";
  getUserPointTransactionsAsAdmin: {
    __typename?: "PointTransactionConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: "PointTransaction";
      id: string;
      type: PointTransactionType;
      amount: number;
      createdAt: any;
      updatedAt: any;
      purchase?: {
        __typename?: "Purchase";
        id: string;
        impUid: string;
        merchantUid: string;
        status: PurchaseStatus;
        initialPrice: number;
        finalPrice: number;
        point?: number | null;
        method: string;
        paidAt?: any | null;
        cancelledAt?: any | null;
        cancelReason?: string | null;
        pgProvider?: string | null;
        createdAt: any;
        updatedAt: any;
        guardian?: {
          __typename?: "Guardian";
          id: string;
          phone: string;
          gender: Gender;
          name: string;
          username?: string | null;
          dateOfBirth?: any | null;
          point: number;
          createdAt: any;
          updatedAt: any;
          deletedAt?: any | null;
          avatar?: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
            size: number;
            type: string;
            createdAt: any;
            thumbnailUri?: string | null;
          } | null;
        } | null;
        caregiver?: {
          __typename?: "Caregiver";
          id: string;
          phone: string;
          gender: Gender;
          name: string;
          username?: string | null;
          dateOfBirth?: any | null;
          point: number;
          certificateNumber?: string | null;
          updatedAt: any;
          createdAt: any;
          deletedAt?: any | null;
          avatar?: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
            size: number;
            type: string;
            createdAt: any;
            thumbnailUri?: string | null;
          } | null;
        } | null;
        pointPackage: {
          __typename?: "Package";
          id: string;
          point: number;
          initialPrice: number;
          finalPrice: number;
          name?: string | null;
          active: boolean;
        };
      } | null;
      serviceRequest?: {
        __typename?: "ServiceRequest";
        id: string;
        price: number;
        serviceAt?: any | null;
        type: ServiceRequestType;
        status: ServiceRequestStatus;
        message?: string | null;
        createdAt: any;
        updatedAt: any;
        service?: {
          __typename?: "Service";
          id: string;
          name: string;
          summary: string;
          price: number;
          active: boolean;
          createdAt: any;
          updatedAt: any;
        } | null;
        guardian: {
          __typename?: "Guardian";
          id: string;
          phone: string;
          gender: Gender;
          name: string;
          username?: string | null;
          dateOfBirth?: any | null;
          point: number;
          createdAt: any;
          updatedAt: any;
          deletedAt?: any | null;
          avatar?: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
            size: number;
            type: string;
            createdAt: any;
            thumbnailUri?: string | null;
          } | null;
        };
        receiver: {
          __typename?: "Receiver";
          id: string;
          name: string;
          phone?: string | null;
          registrationNumber?: string | null;
          dateOfBirth: any;
          gender: Gender;
          createdAt: any;
          updatedAt: any;
          deletedAt?: any | null;
        };
        caregiver: {
          __typename?: "Caregiver";
          id: string;
          phone: string;
          gender: Gender;
          name: string;
          username?: string | null;
          dateOfBirth?: any | null;
          point: number;
          certificateNumber?: string | null;
          createdAt: any;
          updatedAt: any;
          deletedAt?: any | null;
          avatar?: {
            __typename?: "Media";
            id: string;
            name: string;
            uri: string;
            size: number;
            type: string;
            createdAt: any;
            thumbnailUri?: string | null;
          } | null;
        };
      } | null;
    } | null>;
  };
};

export type GetExmapleQuestionsAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetExmapleQuestionsAsAdminQuery = {
  __typename?: "Query";
  getExmapleQuestionsAsAdmin: {
    __typename?: "ExampleQuestionConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges: Array<{
      __typename?: "ExampleQuestion";
      id: string;
      question: string;
      createdAt: any;
      active: boolean;
      updatedAt: any;
      answers?: Array<{
        __typename?: "ExampleQuestionAnswer";
        id: string;
        answer: string;
        active: boolean;
      } | null> | null;
    } | null>;
  };
};

export type UpdateExampleQuestionAsAdminMutationVariables = Exact<{
  exampleQuestionInput: ExmapleQuestionInput;
}>;

export type UpdateExampleQuestionAsAdminMutation = {
  __typename?: "Mutation";
  updateExampleQuestionAsAdmin: { __typename?: "ExampleQuestion"; id: string; question: string };
};

export type UpdateExampleQuestionAnswerAsAdminMutationVariables = Exact<{
  exampleQuestionAnswerInput: ExampleQuestionAnswerInput;
}>;

export type UpdateExampleQuestionAnswerAsAdminMutation = {
  __typename?: "Mutation";
  updateExampleQuestionAnswerAsAdmin: { __typename?: "ExampleQuestionAnswer"; id: string; answer: string };
};

export type GetReceiversAsAdminQueryVariables = Exact<{
  stateId?: InputMaybe<Scalars["ID"]>;
  after?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Gender>;
  cityId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetReceiversAsAdminQuery = {
  __typename?: "Query";
  getReceiversAsAdmin: {
    __typename?: "ReceiverConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "Receiver";
      id: string;
      name: string;
      phone?: string | null;
      registrationNumber?: string | null;
      dateOfBirth: any;
      gender: Gender;
      address?: string | null;
      addressDetail?: string | null;
      lastRecordAt?: any | null;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      registrationMedia?: {
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null;
      state?: { __typename?: "State"; stateCode: string; name: string } | null;
      city?: { __typename?: "City"; cityCode: string; name: string } | null;
    } | null> | null;
  };
};

export type SearchReceiversAsAdminQueryVariables = Exact<{
  query: Scalars["String"];
  gender?: InputMaybe<Gender>;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type SearchReceiversAsAdminQuery = {
  __typename?: "Query";
  searchReceiversAsAdmin: {
    __typename?: "ReceiverConnection";
    pageInfo: { __typename?: "PageInfo"; hasNextPage: boolean; endCursor?: string | null };
    edges?: Array<{
      __typename?: "Receiver";
      id: string;
      name: string;
      phone?: string | null;
      registrationNumber?: string | null;
      dateOfBirth: any;
      gender: Gender;
      address?: string | null;
      addressDetail?: string | null;
      lastRecordAt?: any | null;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      registrationMedia?: {
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null;
      state?: { __typename?: "State"; stateCode: string; name: string } | null;
      city?: { __typename?: "City"; cityCode: string; name: string } | null;
    } | null> | null;
  };
};

export type GetReceiverQueryVariables = Exact<{
  receiverId: Scalars["ID"];
}>;

export type GetReceiverQuery = {
  __typename?: "Query";
  getReceiver: {
    __typename?: "Receiver";
    id: string;
    name: string;
    phone?: string | null;
    registrationNumber?: string | null;
    dateOfBirth: any;
    gender: Gender;
    address?: string | null;
    addressDetail?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    registrationMedia?: {
      __typename?: "Media";
      id: string;
      name: string;
      uri: string;
      size: number;
      type: string;
      createdAt: any;
      thumbnailUri?: string | null;
    } | null;
    guardians?: Array<{
      __typename?: "GuardianReceiver";
      guardian: {
        __typename?: "Guardian";
        id: string;
        phone: string;
        email?: string | null;
        gender: Gender;
        name: string;
        username?: string | null;
        dateOfBirth?: any | null;
        point: number;
        deletedAt?: any | null;
        avatar?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null;
      };
    } | null> | null;
    caregivers?: Array<{
      __typename?: "CaregiverReceiver";
      active: boolean;
      hasUnreadMessage: boolean;
      createdAt: any;
      updatedAt: any;
      caregiver: {
        __typename?: "Caregiver";
        id: string;
        phone: string;
        email?: string | null;
        gender: Gender;
        name: string;
        username?: string | null;
        dateOfBirth?: any | null;
        point: number;
        certificateNumber?: string | null;
        deletedAt?: any | null;
        avatar?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null;
      };
    } | null> | null;
    city?: { __typename?: "City"; cityCode: string; name: string } | null;
    state?: { __typename?: "State"; stateCode: string; name: string } | null;
  };
};

export type UpdateReceiverMutationVariables = Exact<{
  receiverInput: ReceiverInput;
}>;

export type UpdateReceiverMutation = {
  __typename?: "Mutation";
  updateReceiver: {
    __typename?: "Receiver";
    id: string;
    name: string;
    registrationNumber?: string | null;
    phone?: string | null;
    dateOfBirth: any;
    gender: Gender;
    createdAt: any;
  };
};

export type GetReceiverRecordsAsAdminQueryVariables = Exact<{
  receiverId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetReceiverRecordsAsAdminQuery = {
  __typename?: "Query";
  getReceiverRecordsAsAdmin: {
    __typename?: "ReceiverRecordConnection";
    pageInfo: { __typename?: "PageInfo"; endCursor?: string | null; hasNextPage: boolean };
    edges?: Array<{
      __typename?: "ReceiverRecord";
      body: string;
      createdAt: any;
      deletedAt?: any | null;
      updatedAt: any;
      id: string;
      author: {
        __typename?: "Caregiver";
        id: string;
        phone: string;
        name: string;
        gender: Gender;
        username?: string | null;
        avatar?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null;
      };
      media?: Array<{
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export type GetReceiverRecordsQueryVariables = Exact<{
  receiverId: Scalars["ID"];
  range: RangeInput;
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetReceiverRecordsQuery = {
  __typename?: "Query";
  getReceiverRecords: {
    __typename?: "ReceiverRecordConnection";
    pageInfo: { __typename?: "PageInfo"; endCursor?: string | null; hasNextPage: boolean };
    edges?: Array<{
      __typename?: "ReceiverRecord";
      id: string;
      body: string;
      createdAt: any;
      deletedAt?: any | null;
      updatedAt: any;
      author: {
        __typename?: "Caregiver";
        id: string;
        phone: string;
        gender: Gender;
        name: string;
        username?: string | null;
        dateOfBirth?: any | null;
        point: number;
        certificateNumber?: string | null;
        avatar?: {
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null;
      };
      receiver: { __typename?: "Receiver"; id: string; name: string };
      media?: Array<{
        __typename?: "Media";
        id: string;
        name: string;
        uri: string;
        size: number;
        type: string;
        createdAt: any;
        thumbnailUri?: string | null;
      } | null> | null;
    } | null> | null;
  };
};

export type UpdateReportReasonAsAdminMutationVariables = Exact<{
  reportReasonInput: ReportReasonInput;
}>;

export type UpdateReportReasonAsAdminMutation = {
  __typename?: "Mutation";
  updateReportReasonAsAdmin: {
    __typename?: "ReportReason";
    id: string;
    type: ReportReasonType;
    reason?: string | null;
    active: boolean;
  };
};

export type GetReportReasonsQueryVariables = Exact<{
  active?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetReportReasonsQuery = {
  __typename?: "Query";
  getReportReasons?: Array<{
    __typename?: "ReportReason";
    id: string;
    type: ReportReasonType;
    reason?: string | null;
    active: boolean;
  } | null> | null;
};

export type GetReportsAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetReportsAsAdminQuery = {
  __typename?: "Query";
  getReportsAsAdmin: {
    __typename?: "ReportConnection";
    pageInfo: { __typename?: "PageInfo"; endCursor?: string | null; hasNextPage: boolean };
    edges?: Array<{
      __typename?: "Report";
      id: string;
      createdAt: any;
      updatedAt: any;
      customReason?: string | null;
      community?: {
        __typename?: "CaregiverCommunity";
        id: string;
        body: string;
        type?: CaregiverCommunityType | null;
        replyCount: number;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
        author: {
          __typename?: "Caregiver";
          id: string;
          phone: string;
          gender: Gender;
          name: string;
          username?: string | null;
          dateOfBirth?: any | null;
          point: number;
          createdAt: any;
          updatedAt: any;
          deletedAt?: any | null;
        };
        media?: Array<{
          __typename?: "Media";
          id: string;
          name: string;
          uri: string;
          size: number;
          type: string;
          createdAt: any;
          thumbnailUri?: string | null;
        } | null> | null;
      } | null;
      reason?: {
        __typename?: "ReportReason";
        id: string;
        type: ReportReasonType;
        reason?: string | null;
        active: boolean;
      } | null;
      infoReply?: {
        __typename?: "CaregiverInfoReply";
        id: string;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
        body?: string | null;
        info: {
          __typename?: "CaregiverInfo";
          id: string;
          body: string;
          replyCount: number;
          createdAt: any;
          updatedAt: any;
          deletedAt?: any | null;
        };
      } | null;
      caregiver?: {
        __typename?: "Caregiver";
        id: string;
        phone: string;
        gender: Gender;
        name: string;
        username?: string | null;
        point: number;
        dateOfBirth?: any | null;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
      } | null;
      guardian?: {
        __typename?: "Guardian";
        id: string;
        phone: string;
        gender: Gender;
        name: string;
        username?: string | null;
        dateOfBirth?: any | null;
        point: number;
        createdAt: any;
        updatedAt: any;
        deletedAt?: any | null;
      } | null;
    } | null> | null;
  };
};

export type GetServicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetServicesQuery = {
  __typename?: "Query";
  getServices: Array<{
    __typename?: "Service";
    id: string;
    name: string;
    summary: string;
    price: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type GetServiceQueryVariables = Exact<{
  serviceId: Scalars["ID"];
}>;

export type GetServiceQuery = {
  __typename?: "Query";
  getService: {
    __typename?: "Service";
    id: string;
    name: string;
    summary: string;
    price: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateServiceAsAdminMutationVariables = Exact<{
  serviceInput: ServiceInput;
}>;

export type UpdateServiceAsAdminMutation = {
  __typename?: "Mutation";
  updateServiceAsAdmin: {
    __typename?: "Service";
    id: string;
    name: string;
    summary: string;
    price: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type GetUserCountsAsAdminQueryVariables = Exact<{
  range?: InputMaybe<RangeInput>;
}>;

export type GetUserCountsAsAdminQuery = {
  __typename?: "Query";
  getUserCountsAsAdmin: {
    __typename?: "UserCounts";
    caregiverCount: number;
    guardianCount: number;
    receiverCount: number;
    resignedUsersCount: number;
  };
};

export const GetAnnouncementDocument = gql`
  query GetAnnouncement($announcementId: ID!) {
    getAnnouncement(announcementId: $announcementId) {
      id
      title
      summary
      thumbnailMedia {
        id
        uri
      }
      body
      active
      onBanner
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementQuery({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *   },
 * });
 */
export function useGetAnnouncementQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnnouncementQuery, GetAnnouncementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(GetAnnouncementDocument, options);
}
export function useGetAnnouncementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementQuery, GetAnnouncementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(GetAnnouncementDocument, options);
}
export type GetAnnouncementQueryHookResult = ReturnType<typeof useGetAnnouncementQuery>;
export type GetAnnouncementLazyQueryHookResult = ReturnType<typeof useGetAnnouncementLazyQuery>;
export type GetAnnouncementQueryResult = Apollo.QueryResult<GetAnnouncementQuery, GetAnnouncementQueryVariables>;
export const GetAnnouncementsDocument = gql`
  query GetAnnouncements($active: Boolean, $onBanner: Boolean, $first: Int, $after: String) {
    getAnnouncements(active: $active, onBanner: $onBanner, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        title
        summary
        thumbnailMedia {
          id
          uri
        }
        body
        active
        onBanner
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsQuery({
 *   variables: {
 *      active: // value for 'active'
 *      onBanner: // value for 'onBanner'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
}
export function useGetAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
}
export type GetAnnouncementsQueryHookResult = ReturnType<typeof useGetAnnouncementsQuery>;
export type GetAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsLazyQuery>;
export type GetAnnouncementsQueryResult = Apollo.QueryResult<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>;
export const UpdateAnnouncementAsAdminDocument = gql`
  mutation UpdateAnnouncementAsAdmin($announcementInput: AnnouncementInput!) {
    updateAnnouncementAsAdmin(announcementInput: $announcementInput) {
      id
      title
      summary
      thumbnailMedia {
        id
        uri
      }
      body
      active
      onBanner
      createdAt
      updatedAt
    }
  }
`;
export type UpdateAnnouncementAsAdminMutationFn = Apollo.MutationFunction<
  UpdateAnnouncementAsAdminMutation,
  UpdateAnnouncementAsAdminMutationVariables
>;

/**
 * __useUpdateAnnouncementAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementAsAdminMutation, { data, loading, error }] = useUpdateAnnouncementAsAdminMutation({
 *   variables: {
 *      announcementInput: // value for 'announcementInput'
 *   },
 * });
 */
export function useUpdateAnnouncementAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnnouncementAsAdminMutation,
    UpdateAnnouncementAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAnnouncementAsAdminMutation, UpdateAnnouncementAsAdminMutationVariables>(
    UpdateAnnouncementAsAdminDocument,
    options
  );
}
export type UpdateAnnouncementAsAdminMutationHookResult = ReturnType<typeof useUpdateAnnouncementAsAdminMutation>;
export type UpdateAnnouncementAsAdminMutationResult = Apollo.MutationResult<UpdateAnnouncementAsAdminMutation>;
export type UpdateAnnouncementAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnnouncementAsAdminMutation,
  UpdateAnnouncementAsAdminMutationVariables
>;
export const GetCaregiversAsAdminDocument = gql`
  query GetCaregiversAsAdmin($gender: Gender, $first: Int, $after: String) {
    getCaregiversAsAdmin(gender: $gender, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        phone
        gender
        point
        name
        username
        dateOfBirth
        certificateMedia {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        certificateNumber
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetCaregiversAsAdminQuery__
 *
 * To run a query within a React component, call `useGetCaregiversAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiversAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiversAsAdminQuery({
 *   variables: {
 *      gender: // value for 'gender'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCaregiversAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCaregiversAsAdminQuery, GetCaregiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaregiversAsAdminQuery, GetCaregiversAsAdminQueryVariables>(
    GetCaregiversAsAdminDocument,
    options
  );
}
export function useGetCaregiversAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCaregiversAsAdminQuery, GetCaregiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaregiversAsAdminQuery, GetCaregiversAsAdminQueryVariables>(
    GetCaregiversAsAdminDocument,
    options
  );
}
export type GetCaregiversAsAdminQueryHookResult = ReturnType<typeof useGetCaregiversAsAdminQuery>;
export type GetCaregiversAsAdminLazyQueryHookResult = ReturnType<typeof useGetCaregiversAsAdminLazyQuery>;
export type GetCaregiversAsAdminQueryResult = Apollo.QueryResult<
  GetCaregiversAsAdminQuery,
  GetCaregiversAsAdminQueryVariables
>;
export const GetCaregiverProfileDocument = gql`
  query GetCaregiverProfile($caregiverId: ID!) {
    getCaregiverProfile(caregiverId: $caregiverId) {
      id
      phone
      email
      gender
      name
      avatar {
        id
        name
        uri
        size
        type
        createdAt
        thumbnailUri
      }
      username
      dateOfBirth
      point
      certificateMedia {
        id
        name
        uri
        size
        type
        createdAt
        thumbnailUri
      }
      certificateNumber
      receivers {
        id
        name
        phone
        registrationNumber
        dateOfBirth
        gender
        address
        addressDetail
        guardians {
          guardian {
            id
            phone
            name
            email
            gender
            avatar {
              id
              name
              uri
              size
              type
              createdAt
              thumbnailUri
            }
            username
            dateOfBirth
            point
          }
        }
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

/**
 * __useGetCaregiverProfileQuery__
 *
 * To run a query within a React component, call `useGetCaregiverProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverProfileQuery({
 *   variables: {
 *      caregiverId: // value for 'caregiverId'
 *   },
 * });
 */
export function useGetCaregiverProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetCaregiverProfileQuery, GetCaregiverProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaregiverProfileQuery, GetCaregiverProfileQueryVariables>(
    GetCaregiverProfileDocument,
    options
  );
}
export function useGetCaregiverProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCaregiverProfileQuery, GetCaregiverProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaregiverProfileQuery, GetCaregiverProfileQueryVariables>(
    GetCaregiverProfileDocument,
    options
  );
}
export type GetCaregiverProfileQueryHookResult = ReturnType<typeof useGetCaregiverProfileQuery>;
export type GetCaregiverProfileLazyQueryHookResult = ReturnType<typeof useGetCaregiverProfileLazyQuery>;
export type GetCaregiverProfileQueryResult = Apollo.QueryResult<
  GetCaregiverProfileQuery,
  GetCaregiverProfileQueryVariables
>;
export const CreateCaregiverAsAdminDocument = gql`
  mutation CreateCaregiverAsAdmin(
    $phoneNumber: String!
    $profileInput: ProfileInput!
    $cityCodes: [ID]
    $caregiverInput: CaregiverInput
  ) {
    createCaregiverAsAdmin(
      phoneNumber: $phoneNumber
      profileInput: $profileInput
      cityCodes: $cityCodes
      caregiverInput: $caregiverInput
    ) {
      id
      name
    }
  }
`;
export type CreateCaregiverAsAdminMutationFn = Apollo.MutationFunction<
  CreateCaregiverAsAdminMutation,
  CreateCaregiverAsAdminMutationVariables
>;

/**
 * __useCreateCaregiverAsAdminMutation__
 *
 * To run a mutation, you first call `useCreateCaregiverAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaregiverAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaregiverAsAdminMutation, { data, loading, error }] = useCreateCaregiverAsAdminMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      profileInput: // value for 'profileInput'
 *      cityCodes: // value for 'cityCodes'
 *      caregiverInput: // value for 'caregiverInput'
 *   },
 * });
 */
export function useCreateCaregiverAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCaregiverAsAdminMutation, CreateCaregiverAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCaregiverAsAdminMutation, CreateCaregiverAsAdminMutationVariables>(
    CreateCaregiverAsAdminDocument,
    options
  );
}
export type CreateCaregiverAsAdminMutationHookResult = ReturnType<typeof useCreateCaregiverAsAdminMutation>;
export type CreateCaregiverAsAdminMutationResult = Apollo.MutationResult<CreateCaregiverAsAdminMutation>;
export type CreateCaregiverAsAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateCaregiverAsAdminMutation,
  CreateCaregiverAsAdminMutationVariables
>;
export const ResignCaregiverAsAdminDocument = gql`
  mutation ResignCaregiverAsAdmin($caregiverId: ID!) {
    resignCaregiverAsAdmin(caregiverId: $caregiverId)
  }
`;
export type ResignCaregiverAsAdminMutationFn = Apollo.MutationFunction<
  ResignCaregiverAsAdminMutation,
  ResignCaregiverAsAdminMutationVariables
>;

/**
 * __useResignCaregiverAsAdminMutation__
 *
 * To run a mutation, you first call `useResignCaregiverAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResignCaregiverAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resignCaregiverAsAdminMutation, { data, loading, error }] = useResignCaregiverAsAdminMutation({
 *   variables: {
 *      caregiverId: // value for 'caregiverId'
 *   },
 * });
 */
export function useResignCaregiverAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<ResignCaregiverAsAdminMutation, ResignCaregiverAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResignCaregiverAsAdminMutation, ResignCaregiverAsAdminMutationVariables>(
    ResignCaregiverAsAdminDocument,
    options
  );
}
export type ResignCaregiverAsAdminMutationHookResult = ReturnType<typeof useResignCaregiverAsAdminMutation>;
export type ResignCaregiverAsAdminMutationResult = Apollo.MutationResult<ResignCaregiverAsAdminMutation>;
export type ResignCaregiverAsAdminMutationOptions = Apollo.BaseMutationOptions<
  ResignCaregiverAsAdminMutation,
  ResignCaregiverAsAdminMutationVariables
>;
export const GetWorkHoursAsAdminDocument = gql`
  query GetWorkHoursAsAdmin($receiverId: ID, $caregiverId: ID, $range: RangeInput, $first: Int, $after: String) {
    getWorkHoursAsAdmin(
      receiverId: $receiverId
      caregiverId: $caregiverId
      range: $range
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        caregiver {
          id
          phone
          gender
          name
          point
          createdAt
          updatedAt
        }
        receiver {
          id
          name
          dateOfBirth
          gender
          createdAt
          updatedAt
          deletedAt
          registrationNumber
          phone
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetWorkHoursAsAdminQuery__
 *
 * To run a query within a React component, call `useGetWorkHoursAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkHoursAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkHoursAsAdminQuery({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *      caregiverId: // value for 'caregiverId'
 *      range: // value for 'range'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetWorkHoursAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWorkHoursAsAdminQuery, GetWorkHoursAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkHoursAsAdminQuery, GetWorkHoursAsAdminQueryVariables>(
    GetWorkHoursAsAdminDocument,
    options
  );
}
export function useGetWorkHoursAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkHoursAsAdminQuery, GetWorkHoursAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkHoursAsAdminQuery, GetWorkHoursAsAdminQueryVariables>(
    GetWorkHoursAsAdminDocument,
    options
  );
}
export type GetWorkHoursAsAdminQueryHookResult = ReturnType<typeof useGetWorkHoursAsAdminQuery>;
export type GetWorkHoursAsAdminLazyQueryHookResult = ReturnType<typeof useGetWorkHoursAsAdminLazyQuery>;
export type GetWorkHoursAsAdminQueryResult = Apollo.QueryResult<
  GetWorkHoursAsAdminQuery,
  GetWorkHoursAsAdminQueryVariables
>;
export const SearchCaregiversAsAdminDocument = gql`
  query SearchCaregiversAsAdmin($query: String, $gender: Gender, $first: Int, $after: String) {
    searchCaregiversAsAdmin(query: $query, gender: $gender, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        phone
        gender
        point
        name
        username
        dateOfBirth
        certificateMedia {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        certificateNumber
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useSearchCaregiversAsAdminQuery__
 *
 * To run a query within a React component, call `useSearchCaregiversAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCaregiversAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCaregiversAsAdminQuery({
 *   variables: {
 *      query: // value for 'query'
 *      gender: // value for 'gender'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchCaregiversAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchCaregiversAsAdminQuery, SearchCaregiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCaregiversAsAdminQuery, SearchCaregiversAsAdminQueryVariables>(
    SearchCaregiversAsAdminDocument,
    options
  );
}
export function useSearchCaregiversAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchCaregiversAsAdminQuery, SearchCaregiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchCaregiversAsAdminQuery, SearchCaregiversAsAdminQueryVariables>(
    SearchCaregiversAsAdminDocument,
    options
  );
}
export type SearchCaregiversAsAdminQueryHookResult = ReturnType<typeof useSearchCaregiversAsAdminQuery>;
export type SearchCaregiversAsAdminLazyQueryHookResult = ReturnType<typeof useSearchCaregiversAsAdminLazyQuery>;
export type SearchCaregiversAsAdminQueryResult = Apollo.QueryResult<
  SearchCaregiversAsAdminQuery,
  SearchCaregiversAsAdminQueryVariables
>;
export const GetChatsOrderByMessagesCountAsAdminDocument = gql`
  query GetChatsOrderByMessagesCountAsAdmin($first: Int, $after: String) {
    getChatsOrderByMessagesCountAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        chatId
        createdAt
        updatedAt
        messageCount
        hasUnreadMessage
        receiver {
          id
          name
          dateOfBirth
          gender
          createdAt
          updatedAt
        }
        users {
          id
          lastSeenAt
          joinAt
          caregiver {
            id
            phone
            gender
            name
            point
            createdAt
            updatedAt
            deletedAt
          }
          guardian {
            id
            phone
            gender
            name
            point
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

/**
 * __useGetChatsOrderByMessagesCountAsAdminQuery__
 *
 * To run a query within a React component, call `useGetChatsOrderByMessagesCountAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsOrderByMessagesCountAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsOrderByMessagesCountAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetChatsOrderByMessagesCountAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChatsOrderByMessagesCountAsAdminQuery,
    GetChatsOrderByMessagesCountAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatsOrderByMessagesCountAsAdminQuery, GetChatsOrderByMessagesCountAsAdminQueryVariables>(
    GetChatsOrderByMessagesCountAsAdminDocument,
    options
  );
}
export function useGetChatsOrderByMessagesCountAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatsOrderByMessagesCountAsAdminQuery,
    GetChatsOrderByMessagesCountAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChatsOrderByMessagesCountAsAdminQuery,
    GetChatsOrderByMessagesCountAsAdminQueryVariables
  >(GetChatsOrderByMessagesCountAsAdminDocument, options);
}
export type GetChatsOrderByMessagesCountAsAdminQueryHookResult = ReturnType<
  typeof useGetChatsOrderByMessagesCountAsAdminQuery
>;
export type GetChatsOrderByMessagesCountAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetChatsOrderByMessagesCountAsAdminLazyQuery
>;
export type GetChatsOrderByMessagesCountAsAdminQueryResult = Apollo.QueryResult<
  GetChatsOrderByMessagesCountAsAdminQuery,
  GetChatsOrderByMessagesCountAsAdminQueryVariables
>;
export const GetCaregiverCommunitiesByTypeAsAdminDocument = gql`
  query GetCaregiverCommunitiesByTypeAsAdmin(
    $type: CaregiverCommunityType
    $range: RangeInput
    $first: Int
    $after: String
  ) {
    getCaregiverCommunitiesByTypeAsAdmin(type: $type, range: $range, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        author {
          name
          gender
          email
          phone
          id
          point
          updatedAt
          createdAt
          deletedAt
          avatar {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
        }
        body
        type
        media {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        replyCount
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetCaregiverCommunitiesByTypeAsAdminQuery__
 *
 * To run a query within a React component, call `useGetCaregiverCommunitiesByTypeAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverCommunitiesByTypeAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverCommunitiesByTypeAsAdminQuery({
 *   variables: {
 *      type: // value for 'type'
 *      range: // value for 'range'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCaregiverCommunitiesByTypeAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaregiverCommunitiesByTypeAsAdminQuery,
    GetCaregiverCommunitiesByTypeAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaregiverCommunitiesByTypeAsAdminQuery, GetCaregiverCommunitiesByTypeAsAdminQueryVariables>(
    GetCaregiverCommunitiesByTypeAsAdminDocument,
    options
  );
}
export function useGetCaregiverCommunitiesByTypeAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaregiverCommunitiesByTypeAsAdminQuery,
    GetCaregiverCommunitiesByTypeAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaregiverCommunitiesByTypeAsAdminQuery,
    GetCaregiverCommunitiesByTypeAsAdminQueryVariables
  >(GetCaregiverCommunitiesByTypeAsAdminDocument, options);
}
export type GetCaregiverCommunitiesByTypeAsAdminQueryHookResult = ReturnType<
  typeof useGetCaregiverCommunitiesByTypeAsAdminQuery
>;
export type GetCaregiverCommunitiesByTypeAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetCaregiverCommunitiesByTypeAsAdminLazyQuery
>;
export type GetCaregiverCommunitiesByTypeAsAdminQueryResult = Apollo.QueryResult<
  GetCaregiverCommunitiesByTypeAsAdminQuery,
  GetCaregiverCommunitiesByTypeAsAdminQueryVariables
>;
export const GetCaregiverCommunitiesByAuthorAsAdminDocument = gql`
  query GetCaregiverCommunitiesByAuthorAsAdmin($caregiverId: ID!, $first: Int, $after: String) {
    getCaregiverCommunitiesByAuthorAsAdmin(caregiverId: $caregiverId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        author {
          id
          phone
          gender
          name
          avatar {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
          username
          dateOfBirth
          point
          certificateNumber
          createdAt
          updatedAt
          deletedAt
        }
        body
        type
        media {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        replyCount
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetCaregiverCommunitiesByAuthorAsAdminQuery__
 *
 * To run a query within a React component, call `useGetCaregiverCommunitiesByAuthorAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverCommunitiesByAuthorAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverCommunitiesByAuthorAsAdminQuery({
 *   variables: {
 *      caregiverId: // value for 'caregiverId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCaregiverCommunitiesByAuthorAsAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaregiverCommunitiesByAuthorAsAdminQuery,
    GetCaregiverCommunitiesByAuthorAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaregiverCommunitiesByAuthorAsAdminQuery,
    GetCaregiverCommunitiesByAuthorAsAdminQueryVariables
  >(GetCaregiverCommunitiesByAuthorAsAdminDocument, options);
}
export function useGetCaregiverCommunitiesByAuthorAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaregiverCommunitiesByAuthorAsAdminQuery,
    GetCaregiverCommunitiesByAuthorAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaregiverCommunitiesByAuthorAsAdminQuery,
    GetCaregiverCommunitiesByAuthorAsAdminQueryVariables
  >(GetCaregiverCommunitiesByAuthorAsAdminDocument, options);
}
export type GetCaregiverCommunitiesByAuthorAsAdminQueryHookResult = ReturnType<
  typeof useGetCaregiverCommunitiesByAuthorAsAdminQuery
>;
export type GetCaregiverCommunitiesByAuthorAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetCaregiverCommunitiesByAuthorAsAdminLazyQuery
>;
export type GetCaregiverCommunitiesByAuthorAsAdminQueryResult = Apollo.QueryResult<
  GetCaregiverCommunitiesByAuthorAsAdminQuery,
  GetCaregiverCommunitiesByAuthorAsAdminQueryVariables
>;
export const GetCaregiverCommunityRepliesByCommunityAsAdminDocument = gql`
  query GetCaregiverCommunityRepliesByCommunityAsAdmin($communityId: ID!, $first: Int, $after: String) {
    getCaregiverCommunityRepliesByCommunityAsAdmin(communityId: $communityId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        author {
          id
          phone
          gender
          name
          point
          createdAt
          updatedAt
          avatar {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
        }
        updatedAt
        deletedAt
        createdAt
        body
      }
    }
  }
`;

/**
 * __useGetCaregiverCommunityRepliesByCommunityAsAdminQuery__
 *
 * To run a query within a React component, call `useGetCaregiverCommunityRepliesByCommunityAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverCommunityRepliesByCommunityAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverCommunityRepliesByCommunityAsAdminQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCaregiverCommunityRepliesByCommunityAsAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaregiverCommunityRepliesByCommunityAsAdminQuery,
    GetCaregiverCommunityRepliesByCommunityAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaregiverCommunityRepliesByCommunityAsAdminQuery,
    GetCaregiverCommunityRepliesByCommunityAsAdminQueryVariables
  >(GetCaregiverCommunityRepliesByCommunityAsAdminDocument, options);
}
export function useGetCaregiverCommunityRepliesByCommunityAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaregiverCommunityRepliesByCommunityAsAdminQuery,
    GetCaregiverCommunityRepliesByCommunityAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaregiverCommunityRepliesByCommunityAsAdminQuery,
    GetCaregiverCommunityRepliesByCommunityAsAdminQueryVariables
  >(GetCaregiverCommunityRepliesByCommunityAsAdminDocument, options);
}
export type GetCaregiverCommunityRepliesByCommunityAsAdminQueryHookResult = ReturnType<
  typeof useGetCaregiverCommunityRepliesByCommunityAsAdminQuery
>;
export type GetCaregiverCommunityRepliesByCommunityAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetCaregiverCommunityRepliesByCommunityAsAdminLazyQuery
>;
export type GetCaregiverCommunityRepliesByCommunityAsAdminQueryResult = Apollo.QueryResult<
  GetCaregiverCommunityRepliesByCommunityAsAdminQuery,
  GetCaregiverCommunityRepliesByCommunityAsAdminQueryVariables
>;
export const DeleteCaregiverCommunityDocument = gql`
  mutation DeleteCaregiverCommunity($communityId: ID!) {
    deleteCaregiverCommunity(communityId: $communityId) {
      id
      author {
        id
        phone
        gender
        name
        username
        dateOfBirth
        point
        certificateNumber
        createdAt
        updatedAt
        deletedAt
      }
      body
      type
      replyCount
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export type DeleteCaregiverCommunityMutationFn = Apollo.MutationFunction<
  DeleteCaregiverCommunityMutation,
  DeleteCaregiverCommunityMutationVariables
>;

/**
 * __useDeleteCaregiverCommunityMutation__
 *
 * To run a mutation, you first call `useDeleteCaregiverCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaregiverCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaregiverCommunityMutation, { data, loading, error }] = useDeleteCaregiverCommunityMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteCaregiverCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCaregiverCommunityMutation, DeleteCaregiverCommunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCaregiverCommunityMutation, DeleteCaregiverCommunityMutationVariables>(
    DeleteCaregiverCommunityDocument,
    options
  );
}
export type DeleteCaregiverCommunityMutationHookResult = ReturnType<typeof useDeleteCaregiverCommunityMutation>;
export type DeleteCaregiverCommunityMutationResult = Apollo.MutationResult<DeleteCaregiverCommunityMutation>;
export type DeleteCaregiverCommunityMutationOptions = Apollo.BaseMutationOptions<
  DeleteCaregiverCommunityMutation,
  DeleteCaregiverCommunityMutationVariables
>;
export const GetGuardianProfileDocument = gql`
  query GetGuardianProfile($guardianId: ID!) {
    getGuardianProfile(guardianId: $guardianId) {
      id
      phone
      email
      gender
      name
      avatar {
        id
        name
        uri
        size
        type
        createdAt
        thumbnailUri
      }
      username
      dateOfBirth
      point
      createdAt
      updatedAt
      deletedAt
      receivers {
        id
        name
        phone
        gender
        address
        dateOfBirth
        caregivers {
          caregiver {
            id
            phone
            email
            gender
            avatar {
              id
              name
              uri
              size
              type
              createdAt
              thumbnailUri
            }
            name
            username
            dateOfBirth
            point
          }
        }
      }
    }
  }
`;

/**
 * __useGetGuardianProfileQuery__
 *
 * To run a query within a React component, call `useGetGuardianProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuardianProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuardianProfileQuery({
 *   variables: {
 *      guardianId: // value for 'guardianId'
 *   },
 * });
 */
export function useGetGuardianProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetGuardianProfileQuery, GetGuardianProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGuardianProfileQuery, GetGuardianProfileQueryVariables>(
    GetGuardianProfileDocument,
    options
  );
}
export function useGetGuardianProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGuardianProfileQuery, GetGuardianProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGuardianProfileQuery, GetGuardianProfileQueryVariables>(
    GetGuardianProfileDocument,
    options
  );
}
export type GetGuardianProfileQueryHookResult = ReturnType<typeof useGetGuardianProfileQuery>;
export type GetGuardianProfileLazyQueryHookResult = ReturnType<typeof useGetGuardianProfileLazyQuery>;
export type GetGuardianProfileQueryResult = Apollo.QueryResult<
  GetGuardianProfileQuery,
  GetGuardianProfileQueryVariables
>;
export const SearchGuardiansAsAdminDocument = gql`
  query SearchGuardiansAsAdmin($query: String!, $gender: Gender, $first: Int, $after: String) {
    searchGuardiansAsAdmin(query: $query, gender: $gender, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        phone
        email
        gender
        name
        avatar {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        username
        dateOfBirth
        point
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useSearchGuardiansAsAdminQuery__
 *
 * To run a query within a React component, call `useSearchGuardiansAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGuardiansAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGuardiansAsAdminQuery({
 *   variables: {
 *      query: // value for 'query'
 *      gender: // value for 'gender'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchGuardiansAsAdminQuery(
  baseOptions: Apollo.QueryHookOptions<SearchGuardiansAsAdminQuery, SearchGuardiansAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchGuardiansAsAdminQuery, SearchGuardiansAsAdminQueryVariables>(
    SearchGuardiansAsAdminDocument,
    options
  );
}
export function useSearchGuardiansAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchGuardiansAsAdminQuery, SearchGuardiansAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchGuardiansAsAdminQuery, SearchGuardiansAsAdminQueryVariables>(
    SearchGuardiansAsAdminDocument,
    options
  );
}
export type SearchGuardiansAsAdminQueryHookResult = ReturnType<typeof useSearchGuardiansAsAdminQuery>;
export type SearchGuardiansAsAdminLazyQueryHookResult = ReturnType<typeof useSearchGuardiansAsAdminLazyQuery>;
export type SearchGuardiansAsAdminQueryResult = Apollo.QueryResult<
  SearchGuardiansAsAdminQuery,
  SearchGuardiansAsAdminQueryVariables
>;
export const GetGuardiansAsAdminDocument = gql`
  query GetGuardiansAsAdmin($gender: Gender, $first: Int, $after: String) {
    getGuardiansAsAdmin(gender: $gender, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        phone
        email
        gender
        name
        avatar {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        username
        dateOfBirth
        point
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetGuardiansAsAdminQuery__
 *
 * To run a query within a React component, call `useGetGuardiansAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuardiansAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuardiansAsAdminQuery({
 *   variables: {
 *      gender: // value for 'gender'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetGuardiansAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGuardiansAsAdminQuery, GetGuardiansAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGuardiansAsAdminQuery, GetGuardiansAsAdminQueryVariables>(
    GetGuardiansAsAdminDocument,
    options
  );
}
export function useGetGuardiansAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGuardiansAsAdminQuery, GetGuardiansAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGuardiansAsAdminQuery, GetGuardiansAsAdminQueryVariables>(
    GetGuardiansAsAdminDocument,
    options
  );
}
export type GetGuardiansAsAdminQueryHookResult = ReturnType<typeof useGetGuardiansAsAdminQuery>;
export type GetGuardiansAsAdminLazyQueryHookResult = ReturnType<typeof useGetGuardiansAsAdminLazyQuery>;
export type GetGuardiansAsAdminQueryResult = Apollo.QueryResult<
  GetGuardiansAsAdminQuery,
  GetGuardiansAsAdminQueryVariables
>;
export const ResignGuardianAsAdminDocument = gql`
  mutation ResignGuardianAsAdmin($guardianId: ID!) {
    resignGuardianAsAdmin(guardianId: $guardianId)
  }
`;
export type ResignGuardianAsAdminMutationFn = Apollo.MutationFunction<
  ResignGuardianAsAdminMutation,
  ResignGuardianAsAdminMutationVariables
>;

/**
 * __useResignGuardianAsAdminMutation__
 *
 * To run a mutation, you first call `useResignGuardianAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResignGuardianAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resignGuardianAsAdminMutation, { data, loading, error }] = useResignGuardianAsAdminMutation({
 *   variables: {
 *      guardianId: // value for 'guardianId'
 *   },
 * });
 */
export function useResignGuardianAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<ResignGuardianAsAdminMutation, ResignGuardianAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResignGuardianAsAdminMutation, ResignGuardianAsAdminMutationVariables>(
    ResignGuardianAsAdminDocument,
    options
  );
}
export type ResignGuardianAsAdminMutationHookResult = ReturnType<typeof useResignGuardianAsAdminMutation>;
export type ResignGuardianAsAdminMutationResult = Apollo.MutationResult<ResignGuardianAsAdminMutation>;
export type ResignGuardianAsAdminMutationOptions = Apollo.BaseMutationOptions<
  ResignGuardianAsAdminMutation,
  ResignGuardianAsAdminMutationVariables
>;
export const CreateGuardianAsAdminDocument = gql`
  mutation CreateGuardianAsAdmin($phoneNumber: String!, $profileInput: ProfileInput!) {
    createGuardianAsAdmin(phoneNumber: $phoneNumber, profileInput: $profileInput) {
      id
      phone
      gender
      name
      point
      createdAt
      updatedAt
    }
  }
`;
export type CreateGuardianAsAdminMutationFn = Apollo.MutationFunction<
  CreateGuardianAsAdminMutation,
  CreateGuardianAsAdminMutationVariables
>;

/**
 * __useCreateGuardianAsAdminMutation__
 *
 * To run a mutation, you first call `useCreateGuardianAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuardianAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuardianAsAdminMutation, { data, loading, error }] = useCreateGuardianAsAdminMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      profileInput: // value for 'profileInput'
 *   },
 * });
 */
export function useCreateGuardianAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGuardianAsAdminMutation, CreateGuardianAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGuardianAsAdminMutation, CreateGuardianAsAdminMutationVariables>(
    CreateGuardianAsAdminDocument,
    options
  );
}
export type CreateGuardianAsAdminMutationHookResult = ReturnType<typeof useCreateGuardianAsAdminMutation>;
export type CreateGuardianAsAdminMutationResult = Apollo.MutationResult<CreateGuardianAsAdminMutation>;
export type CreateGuardianAsAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateGuardianAsAdminMutation,
  CreateGuardianAsAdminMutationVariables
>;
export const UpdateCaregiverInfoAsAdminDocument = gql`
  mutation UpdateCaregiverInfoAsAdmin($caregiverInfoInput: CaregiverInfoInput!) {
    updateCaregiverInfoAsAdmin(caregiverInfoInput: $caregiverInfoInput) {
      id
      body
      media {
        id
        uri
      }
      createdAt
      cities {
        cityCode
        name
        state {
          stateCode
          name
        }
      }
    }
  }
`;
export type UpdateCaregiverInfoAsAdminMutationFn = Apollo.MutationFunction<
  UpdateCaregiverInfoAsAdminMutation,
  UpdateCaregiverInfoAsAdminMutationVariables
>;

/**
 * __useUpdateCaregiverInfoAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateCaregiverInfoAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaregiverInfoAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaregiverInfoAsAdminMutation, { data, loading, error }] = useUpdateCaregiverInfoAsAdminMutation({
 *   variables: {
 *      caregiverInfoInput: // value for 'caregiverInfoInput'
 *   },
 * });
 */
export function useUpdateCaregiverInfoAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCaregiverInfoAsAdminMutation,
    UpdateCaregiverInfoAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCaregiverInfoAsAdminMutation, UpdateCaregiverInfoAsAdminMutationVariables>(
    UpdateCaregiverInfoAsAdminDocument,
    options
  );
}
export type UpdateCaregiverInfoAsAdminMutationHookResult = ReturnType<typeof useUpdateCaregiverInfoAsAdminMutation>;
export type UpdateCaregiverInfoAsAdminMutationResult = Apollo.MutationResult<UpdateCaregiverInfoAsAdminMutation>;
export type UpdateCaregiverInfoAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateCaregiverInfoAsAdminMutation,
  UpdateCaregiverInfoAsAdminMutationVariables
>;
export const GetCaregiverInfosDocument = gql`
  query GetCaregiverInfos($first: Int, $after: String, $cityCodes: [ID]) {
    getCaregiverInfos(first: $first, after: $after, cityCodes: $cityCodes) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        body
        media {
          id
          uri
        }
        replyCount
        cities {
          cityCode
          name
          state {
            stateCode
            name
          }
        }
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetCaregiverInfosQuery__
 *
 * To run a query within a React component, call `useGetCaregiverInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverInfosQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      cityCodes: // value for 'cityCodes'
 *   },
 * });
 */
export function useGetCaregiverInfosQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCaregiverInfosQuery, GetCaregiverInfosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaregiverInfosQuery, GetCaregiverInfosQueryVariables>(GetCaregiverInfosDocument, options);
}
export function useGetCaregiverInfosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCaregiverInfosQuery, GetCaregiverInfosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaregiverInfosQuery, GetCaregiverInfosQueryVariables>(
    GetCaregiverInfosDocument,
    options
  );
}
export type GetCaregiverInfosQueryHookResult = ReturnType<typeof useGetCaregiverInfosQuery>;
export type GetCaregiverInfosLazyQueryHookResult = ReturnType<typeof useGetCaregiverInfosLazyQuery>;
export type GetCaregiverInfosQueryResult = Apollo.QueryResult<GetCaregiverInfosQuery, GetCaregiverInfosQueryVariables>;
export const GetCaregiverInfoDocument = gql`
  query GetCaregiverInfo($infoId: ID!) {
    getCaregiverInfo(infoId: $infoId) {
      id
      body
      media {
        id
        uri
      }
      replyCount
      cities {
        cityCode
        state {
          stateCode
          name
        }
        name
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

/**
 * __useGetCaregiverInfoQuery__
 *
 * To run a query within a React component, call `useGetCaregiverInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaregiverInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaregiverInfoQuery({
 *   variables: {
 *      infoId: // value for 'infoId'
 *   },
 * });
 */
export function useGetCaregiverInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetCaregiverInfoQuery, GetCaregiverInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCaregiverInfoQuery, GetCaregiverInfoQueryVariables>(GetCaregiverInfoDocument, options);
}
export function useGetCaregiverInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCaregiverInfoQuery, GetCaregiverInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCaregiverInfoQuery, GetCaregiverInfoQueryVariables>(GetCaregiverInfoDocument, options);
}
export type GetCaregiverInfoQueryHookResult = ReturnType<typeof useGetCaregiverInfoQuery>;
export type GetCaregiverInfoLazyQueryHookResult = ReturnType<typeof useGetCaregiverInfoLazyQuery>;
export type GetCaregiverInfoQueryResult = Apollo.QueryResult<GetCaregiverInfoQuery, GetCaregiverInfoQueryVariables>;
export const DeleteCaregiverInfoAsAdminDocument = gql`
  mutation DeleteCaregiverInfoAsAdmin($infoId: ID!) {
    deleteCaregiverInfoAsAdmin(infoId: $infoId) {
      id
      body
      replyCount
      createdAt
      updatedAt
    }
  }
`;
export type DeleteCaregiverInfoAsAdminMutationFn = Apollo.MutationFunction<
  DeleteCaregiverInfoAsAdminMutation,
  DeleteCaregiverInfoAsAdminMutationVariables
>;

/**
 * __useDeleteCaregiverInfoAsAdminMutation__
 *
 * To run a mutation, you first call `useDeleteCaregiverInfoAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaregiverInfoAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaregiverInfoAsAdminMutation, { data, loading, error }] = useDeleteCaregiverInfoAsAdminMutation({
 *   variables: {
 *      infoId: // value for 'infoId'
 *   },
 * });
 */
export function useDeleteCaregiverInfoAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCaregiverInfoAsAdminMutation,
    DeleteCaregiverInfoAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCaregiverInfoAsAdminMutation, DeleteCaregiverInfoAsAdminMutationVariables>(
    DeleteCaregiverInfoAsAdminDocument,
    options
  );
}
export type DeleteCaregiverInfoAsAdminMutationHookResult = ReturnType<typeof useDeleteCaregiverInfoAsAdminMutation>;
export type DeleteCaregiverInfoAsAdminMutationResult = Apollo.MutationResult<DeleteCaregiverInfoAsAdminMutation>;
export type DeleteCaregiverInfoAsAdminMutationOptions = Apollo.BaseMutationOptions<
  DeleteCaregiverInfoAsAdminMutation,
  DeleteCaregiverInfoAsAdminMutationVariables
>;
export const DeleteCaregiverInfoMediaDocument = gql`
  mutation DeleteCaregiverInfoMedia($infoId: ID!, $mediaId: ID!) {
    deleteCaregiverInfoMedia(infoId: $infoId, mediaId: $mediaId) {
      id
      media {
        id
        uri
      }
    }
  }
`;
export type DeleteCaregiverInfoMediaMutationFn = Apollo.MutationFunction<
  DeleteCaregiverInfoMediaMutation,
  DeleteCaregiverInfoMediaMutationVariables
>;

/**
 * __useDeleteCaregiverInfoMediaMutation__
 *
 * To run a mutation, you first call `useDeleteCaregiverInfoMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaregiverInfoMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaregiverInfoMediaMutation, { data, loading, error }] = useDeleteCaregiverInfoMediaMutation({
 *   variables: {
 *      infoId: // value for 'infoId'
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeleteCaregiverInfoMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCaregiverInfoMediaMutation, DeleteCaregiverInfoMediaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCaregiverInfoMediaMutation, DeleteCaregiverInfoMediaMutationVariables>(
    DeleteCaregiverInfoMediaDocument,
    options
  );
}
export type DeleteCaregiverInfoMediaMutationHookResult = ReturnType<typeof useDeleteCaregiverInfoMediaMutation>;
export type DeleteCaregiverInfoMediaMutationResult = Apollo.MutationResult<DeleteCaregiverInfoMediaMutation>;
export type DeleteCaregiverInfoMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteCaregiverInfoMediaMutation,
  DeleteCaregiverInfoMediaMutationVariables
>;
export const GetStatesDocument = gql`
  query GetStates {
    getStates {
      stateCode
      name
    }
  }
`;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
}
export function useGetStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatesQuery, GetStatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
}
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<typeof useGetStatesLazyQuery>;
export type GetStatesQueryResult = Apollo.QueryResult<GetStatesQuery, GetStatesQueryVariables>;
export const GetCitiesByStateDocument = gql`
  query getCitiesByState($stateCode: ID!) {
    getCitiesByState(stateCode: $stateCode) {
      cityCode
      name
    }
  }
`;

/**
 * __useGetCitiesByStateQuery__
 *
 * To run a query within a React component, call `useGetCitiesByStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesByStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesByStateQuery({
 *   variables: {
 *      stateCode: // value for 'stateCode'
 *   },
 * });
 */
export function useGetCitiesByStateQuery(
  baseOptions: Apollo.QueryHookOptions<GetCitiesByStateQuery, GetCitiesByStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCitiesByStateQuery, GetCitiesByStateQueryVariables>(GetCitiesByStateDocument, options);
}
export function useGetCitiesByStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesByStateQuery, GetCitiesByStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCitiesByStateQuery, GetCitiesByStateQueryVariables>(GetCitiesByStateDocument, options);
}
export type GetCitiesByStateQueryHookResult = ReturnType<typeof useGetCitiesByStateQuery>;
export type GetCitiesByStateLazyQueryHookResult = ReturnType<typeof useGetCitiesByStateLazyQuery>;
export type GetCitiesByStateQueryResult = Apollo.QueryResult<GetCitiesByStateQuery, GetCitiesByStateQueryVariables>;
export const GetLatestPolicyDocument = gql`
  query GetLatestPolicy($type: PolicyType!) {
    getLatestPolicy(type: $type) {
      id
      type
      body
      createdAt
    }
  }
`;

/**
 * __useGetLatestPolicyQuery__
 *
 * To run a query within a React component, call `useGetLatestPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPolicyQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLatestPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
}
export function useGetLatestPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
}
export type GetLatestPolicyQueryHookResult = ReturnType<typeof useGetLatestPolicyQuery>;
export type GetLatestPolicyLazyQueryHookResult = ReturnType<typeof useGetLatestPolicyLazyQuery>;
export type GetLatestPolicyQueryResult = Apollo.QueryResult<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>;
export const UpdatePolicyAsAdminDocument = gql`
  mutation UpdatePolicyAsAdmin($policyInput: PolicyInput!) {
    updatePolicyAsAdmin(policyInput: $policyInput) {
      id
      type
      body
      createdAt
    }
  }
`;
export type UpdatePolicyAsAdminMutationFn = Apollo.MutationFunction<
  UpdatePolicyAsAdminMutation,
  UpdatePolicyAsAdminMutationVariables
>;

/**
 * __useUpdatePolicyAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAsAdminMutation, { data, loading, error }] = useUpdatePolicyAsAdminMutation({
 *   variables: {
 *      policyInput: // value for 'policyInput'
 *   },
 * });
 */
export function useUpdatePolicyAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>(
    UpdatePolicyAsAdminDocument,
    options
  );
}
export type UpdatePolicyAsAdminMutationHookResult = ReturnType<typeof useUpdatePolicyAsAdminMutation>;
export type UpdatePolicyAsAdminMutationResult = Apollo.MutationResult<UpdatePolicyAsAdminMutation>;
export type UpdatePolicyAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyAsAdminMutation,
  UpdatePolicyAsAdminMutationVariables
>;
export const GetManagementDocument = gql`
  query GetManagement {
    getManagement {
      id
      companyName
      address
      copyright
      email
      ceoName
      registrationNumber
      escroRegistration
      phone
      kakaoAddress
      minimumTip
      invitationPoint
      shopAddress
    }
  }
`;

/**
 * __useGetManagementQuery__
 *
 * To run a query within a React component, call `useGetManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManagementQuery, GetManagementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
}
export function useGetManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManagementQuery, GetManagementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
}
export type GetManagementQueryHookResult = ReturnType<typeof useGetManagementQuery>;
export type GetManagementLazyQueryHookResult = ReturnType<typeof useGetManagementLazyQuery>;
export type GetManagementQueryResult = Apollo.QueryResult<GetManagementQuery, GetManagementQueryVariables>;
export const UpdateManagementAsAdminDocument = gql`
  mutation UpdateManagementAsAdmin($managementInput: ManagementInput!) {
    updateManagementAsAdmin(managementInput: $managementInput) {
      id
      companyName
      address
      copyright
      email
      ceoName
      registrationNumber
      escroRegistration
      phone
      kakaoAddress
      minimumTip
      invitationPoint
      shopAddress
    }
  }
`;
export type UpdateManagementAsAdminMutationFn = Apollo.MutationFunction<
  UpdateManagementAsAdminMutation,
  UpdateManagementAsAdminMutationVariables
>;

/**
 * __useUpdateManagementAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateManagementAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementAsAdminMutation, { data, loading, error }] = useUpdateManagementAsAdminMutation({
 *   variables: {
 *      managementInput: // value for 'managementInput'
 *   },
 * });
 */
export function useUpdateManagementAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>(
    UpdateManagementAsAdminDocument,
    options
  );
}
export type UpdateManagementAsAdminMutationHookResult = ReturnType<typeof useUpdateManagementAsAdminMutation>;
export type UpdateManagementAsAdminMutationResult = Apollo.MutationResult<UpdateManagementAsAdminMutation>;
export type UpdateManagementAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagementAsAdminMutation,
  UpdateManagementAsAdminMutationVariables
>;
export const GetPoliciesDocument = gql`
  query GetPolicies($type: PolicyType!) {
    getPolicies(type: $type) {
      id
      type
      body
      createdAt
    }
  }
`;

/**
 * __useGetPoliciesQuery__
 *
 * To run a query within a React component, call `useGetPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPoliciesQuery(baseOptions: Apollo.QueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
}
export function useGetPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
}
export type GetPoliciesQueryHookResult = ReturnType<typeof useGetPoliciesQuery>;
export type GetPoliciesLazyQueryHookResult = ReturnType<typeof useGetPoliciesLazyQuery>;
export type GetPoliciesQueryResult = Apollo.QueryResult<GetPoliciesQuery, GetPoliciesQueryVariables>;
export const GetPackagesDocument = gql`
  query GetPackages {
    getPackages {
      id
      point
      initialPrice
      finalPrice
      name
      active
    }
  }
`;

/**
 * __useGetPackagesQuery__
 *
 * To run a query within a React component, call `useGetPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
}
export function useGetPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
}
export type GetPackagesQueryHookResult = ReturnType<typeof useGetPackagesQuery>;
export type GetPackagesLazyQueryHookResult = ReturnType<typeof useGetPackagesLazyQuery>;
export type GetPackagesQueryResult = Apollo.QueryResult<GetPackagesQuery, GetPackagesQueryVariables>;
export const UpdatePackageAsAdminDocument = gql`
  mutation UpdatePackageAsAdmin($packageInput: PackageInput!) {
    updatePackageAsAdmin(packageInput: $packageInput) {
      id
      point
      initialPrice
      finalPrice
      name
      active
    }
  }
`;
export type UpdatePackageAsAdminMutationFn = Apollo.MutationFunction<
  UpdatePackageAsAdminMutation,
  UpdatePackageAsAdminMutationVariables
>;

/**
 * __useUpdatePackageAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePackageAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageAsAdminMutation, { data, loading, error }] = useUpdatePackageAsAdminMutation({
 *   variables: {
 *      packageInput: // value for 'packageInput'
 *   },
 * });
 */
export function useUpdatePackageAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePackageAsAdminMutation, UpdatePackageAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePackageAsAdminMutation, UpdatePackageAsAdminMutationVariables>(
    UpdatePackageAsAdminDocument,
    options
  );
}
export type UpdatePackageAsAdminMutationHookResult = ReturnType<typeof useUpdatePackageAsAdminMutation>;
export type UpdatePackageAsAdminMutationResult = Apollo.MutationResult<UpdatePackageAsAdminMutation>;
export type UpdatePackageAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdatePackageAsAdminMutation,
  UpdatePackageAsAdminMutationVariables
>;
export const GetPackageDocument = gql`
  query GetPackage($packageId: ID!) {
    getPackage(packageId: $packageId) {
      id
      point
      initialPrice
      finalPrice
      name
      active
    }
  }
`;

/**
 * __useGetPackageQuery__
 *
 * To run a query within a React component, call `useGetPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageQuery({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useGetPackageQuery(baseOptions: Apollo.QueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
}
export function useGetPackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPackageQuery, GetPackageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
}
export type GetPackageQueryHookResult = ReturnType<typeof useGetPackageQuery>;
export type GetPackageLazyQueryHookResult = ReturnType<typeof useGetPackageLazyQuery>;
export type GetPackageQueryResult = Apollo.QueryResult<GetPackageQuery, GetPackageQueryVariables>;
export const GetUserPointTransactionsAsAdminDocument = gql`
  query GetUserPointTransactionsAsAdmin(
    $caregiverId: ID
    $guardianId: ID
    $range: RangeInput
    $first: Int
    $after: String
  ) {
    getUserPointTransactionsAsAdmin(
      caregiverId: $caregiverId
      guardianId: $guardianId
      range: $range
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        type
        purchase {
          id
          guardian {
            id
            phone
            gender
            name
            avatar {
              id
              name
              uri
              size
              type
              createdAt
              thumbnailUri
            }
            username
            dateOfBirth
            point
            createdAt
            updatedAt
            deletedAt
          }
          caregiver {
            id
            phone
            gender
            name
            avatar {
              id
              name
              uri
              size
              type
              createdAt
              thumbnailUri
            }
            username
            dateOfBirth
            point
            certificateNumber
            updatedAt
            createdAt
            deletedAt
          }
          pointPackage {
            id
            point
            initialPrice
            finalPrice
            name
            active
          }
          impUid
          merchantUid
          status
          initialPrice
          finalPrice
          point
          method
          paidAt
          cancelledAt
          cancelReason
          pgProvider
          createdAt
          updatedAt
        }
        amount
        serviceRequest {
          id
          service {
            id
            name
            summary
            price
            active
            createdAt
            updatedAt
          }
          guardian {
            id
            phone
            gender
            name
            avatar {
              id
              name
              uri
              size
              type
              createdAt
              thumbnailUri
            }
            username
            dateOfBirth
            point
            createdAt
            updatedAt
            deletedAt
          }
          receiver {
            id
            name
            phone
            registrationNumber
            dateOfBirth
            gender
            createdAt
            updatedAt
            deletedAt
          }
          caregiver {
            id
            phone
            gender
            name
            avatar {
              id
              name
              uri
              size
              type
              createdAt
              thumbnailUri
            }
            username
            dateOfBirth
            point
            certificateNumber
            createdAt
            updatedAt
            deletedAt
          }
          price
          serviceAt
          type
          status
          message
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetUserPointTransactionsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUserPointTransactionsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPointTransactionsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPointTransactionsAsAdminQuery({
 *   variables: {
 *      caregiverId: // value for 'caregiverId'
 *      guardianId: // value for 'guardianId'
 *      range: // value for 'range'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserPointTransactionsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPointTransactionsAsAdminQuery,
    GetUserPointTransactionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPointTransactionsAsAdminQuery, GetUserPointTransactionsAsAdminQueryVariables>(
    GetUserPointTransactionsAsAdminDocument,
    options
  );
}
export function useGetUserPointTransactionsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPointTransactionsAsAdminQuery,
    GetUserPointTransactionsAsAdminQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserPointTransactionsAsAdminQuery, GetUserPointTransactionsAsAdminQueryVariables>(
    GetUserPointTransactionsAsAdminDocument,
    options
  );
}
export type GetUserPointTransactionsAsAdminQueryHookResult = ReturnType<typeof useGetUserPointTransactionsAsAdminQuery>;
export type GetUserPointTransactionsAsAdminLazyQueryHookResult = ReturnType<
  typeof useGetUserPointTransactionsAsAdminLazyQuery
>;
export type GetUserPointTransactionsAsAdminQueryResult = Apollo.QueryResult<
  GetUserPointTransactionsAsAdminQuery,
  GetUserPointTransactionsAsAdminQueryVariables
>;
export const GetExmapleQuestionsAsAdminDocument = gql`
  query GetExmapleQuestionsAsAdmin($first: Int, $after: String) {
    getExmapleQuestionsAsAdmin(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        answers {
          id
          answer
          active
        }
        question
        createdAt
        active
        updatedAt
      }
    }
  }
`;

/**
 * __useGetExmapleQuestionsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetExmapleQuestionsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExmapleQuestionsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExmapleQuestionsAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetExmapleQuestionsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExmapleQuestionsAsAdminQuery, GetExmapleQuestionsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExmapleQuestionsAsAdminQuery, GetExmapleQuestionsAsAdminQueryVariables>(
    GetExmapleQuestionsAsAdminDocument,
    options
  );
}
export function useGetExmapleQuestionsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExmapleQuestionsAsAdminQuery, GetExmapleQuestionsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExmapleQuestionsAsAdminQuery, GetExmapleQuestionsAsAdminQueryVariables>(
    GetExmapleQuestionsAsAdminDocument,
    options
  );
}
export type GetExmapleQuestionsAsAdminQueryHookResult = ReturnType<typeof useGetExmapleQuestionsAsAdminQuery>;
export type GetExmapleQuestionsAsAdminLazyQueryHookResult = ReturnType<typeof useGetExmapleQuestionsAsAdminLazyQuery>;
export type GetExmapleQuestionsAsAdminQueryResult = Apollo.QueryResult<
  GetExmapleQuestionsAsAdminQuery,
  GetExmapleQuestionsAsAdminQueryVariables
>;
export const UpdateExampleQuestionAsAdminDocument = gql`
  mutation UpdateExampleQuestionAsAdmin($exampleQuestionInput: ExmapleQuestionInput!) {
    updateExampleQuestionAsAdmin(exampleQuestionInput: $exampleQuestionInput) {
      id
      question
    }
  }
`;
export type UpdateExampleQuestionAsAdminMutationFn = Apollo.MutationFunction<
  UpdateExampleQuestionAsAdminMutation,
  UpdateExampleQuestionAsAdminMutationVariables
>;

/**
 * __useUpdateExampleQuestionAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateExampleQuestionAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExampleQuestionAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExampleQuestionAsAdminMutation, { data, loading, error }] = useUpdateExampleQuestionAsAdminMutation({
 *   variables: {
 *      exampleQuestionInput: // value for 'exampleQuestionInput'
 *   },
 * });
 */
export function useUpdateExampleQuestionAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExampleQuestionAsAdminMutation,
    UpdateExampleQuestionAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExampleQuestionAsAdminMutation, UpdateExampleQuestionAsAdminMutationVariables>(
    UpdateExampleQuestionAsAdminDocument,
    options
  );
}
export type UpdateExampleQuestionAsAdminMutationHookResult = ReturnType<typeof useUpdateExampleQuestionAsAdminMutation>;
export type UpdateExampleQuestionAsAdminMutationResult = Apollo.MutationResult<UpdateExampleQuestionAsAdminMutation>;
export type UpdateExampleQuestionAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateExampleQuestionAsAdminMutation,
  UpdateExampleQuestionAsAdminMutationVariables
>;
export const UpdateExampleQuestionAnswerAsAdminDocument = gql`
  mutation UpdateExampleQuestionAnswerAsAdmin($exampleQuestionAnswerInput: ExampleQuestionAnswerInput!) {
    updateExampleQuestionAnswerAsAdmin(exampleQuestionAnswerInput: $exampleQuestionAnswerInput) {
      id
      answer
    }
  }
`;
export type UpdateExampleQuestionAnswerAsAdminMutationFn = Apollo.MutationFunction<
  UpdateExampleQuestionAnswerAsAdminMutation,
  UpdateExampleQuestionAnswerAsAdminMutationVariables
>;

/**
 * __useUpdateExampleQuestionAnswerAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateExampleQuestionAnswerAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExampleQuestionAnswerAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExampleQuestionAnswerAsAdminMutation, { data, loading, error }] = useUpdateExampleQuestionAnswerAsAdminMutation({
 *   variables: {
 *      exampleQuestionAnswerInput: // value for 'exampleQuestionAnswerInput'
 *   },
 * });
 */
export function useUpdateExampleQuestionAnswerAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExampleQuestionAnswerAsAdminMutation,
    UpdateExampleQuestionAnswerAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateExampleQuestionAnswerAsAdminMutation,
    UpdateExampleQuestionAnswerAsAdminMutationVariables
  >(UpdateExampleQuestionAnswerAsAdminDocument, options);
}
export type UpdateExampleQuestionAnswerAsAdminMutationHookResult = ReturnType<
  typeof useUpdateExampleQuestionAnswerAsAdminMutation
>;
export type UpdateExampleQuestionAnswerAsAdminMutationResult =
  Apollo.MutationResult<UpdateExampleQuestionAnswerAsAdminMutation>;
export type UpdateExampleQuestionAnswerAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateExampleQuestionAnswerAsAdminMutation,
  UpdateExampleQuestionAnswerAsAdminMutationVariables
>;
export const GetReceiversAsAdminDocument = gql`
  query GetReceiversAsAdmin($stateId: ID, $after: String, $first: Int, $gender: Gender, $cityId: ID) {
    getReceiversAsAdmin(stateId: $stateId, after: $after, first: $first, gender: $gender, cityId: $cityId) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        name
        phone
        registrationMedia {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        registrationNumber
        dateOfBirth
        gender
        address
        addressDetail
        state {
          stateCode
          name
        }
        city {
          cityCode
          name
        }
        lastRecordAt
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetReceiversAsAdminQuery__
 *
 * To run a query within a React component, call `useGetReceiversAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiversAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiversAsAdminQuery({
 *   variables: {
 *      stateId: // value for 'stateId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      gender: // value for 'gender'
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useGetReceiversAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReceiversAsAdminQuery, GetReceiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReceiversAsAdminQuery, GetReceiversAsAdminQueryVariables>(
    GetReceiversAsAdminDocument,
    options
  );
}
export function useGetReceiversAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReceiversAsAdminQuery, GetReceiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReceiversAsAdminQuery, GetReceiversAsAdminQueryVariables>(
    GetReceiversAsAdminDocument,
    options
  );
}
export type GetReceiversAsAdminQueryHookResult = ReturnType<typeof useGetReceiversAsAdminQuery>;
export type GetReceiversAsAdminLazyQueryHookResult = ReturnType<typeof useGetReceiversAsAdminLazyQuery>;
export type GetReceiversAsAdminQueryResult = Apollo.QueryResult<
  GetReceiversAsAdminQuery,
  GetReceiversAsAdminQueryVariables
>;
export const SearchReceiversAsAdminDocument = gql`
  query SearchReceiversAsAdmin($query: String!, $gender: Gender, $first: Int, $after: String) {
    searchReceiversAsAdmin(query: $query, gender: $gender, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        name
        phone
        registrationMedia {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        registrationNumber
        dateOfBirth
        gender
        address
        addressDetail
        state {
          stateCode
          name
        }
        city {
          cityCode
          name
        }
        lastRecordAt
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useSearchReceiversAsAdminQuery__
 *
 * To run a query within a React component, call `useSearchReceiversAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchReceiversAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchReceiversAsAdminQuery({
 *   variables: {
 *      query: // value for 'query'
 *      gender: // value for 'gender'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchReceiversAsAdminQuery(
  baseOptions: Apollo.QueryHookOptions<SearchReceiversAsAdminQuery, SearchReceiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchReceiversAsAdminQuery, SearchReceiversAsAdminQueryVariables>(
    SearchReceiversAsAdminDocument,
    options
  );
}
export function useSearchReceiversAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchReceiversAsAdminQuery, SearchReceiversAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchReceiversAsAdminQuery, SearchReceiversAsAdminQueryVariables>(
    SearchReceiversAsAdminDocument,
    options
  );
}
export type SearchReceiversAsAdminQueryHookResult = ReturnType<typeof useSearchReceiversAsAdminQuery>;
export type SearchReceiversAsAdminLazyQueryHookResult = ReturnType<typeof useSearchReceiversAsAdminLazyQuery>;
export type SearchReceiversAsAdminQueryResult = Apollo.QueryResult<
  SearchReceiversAsAdminQuery,
  SearchReceiversAsAdminQueryVariables
>;
export const GetReceiverDocument = gql`
  query GetReceiver($receiverId: ID!) {
    getReceiver(receiverId: $receiverId) {
      id
      name
      phone
      registrationMedia {
        id
        name
        uri
        size
        type
        createdAt
        thumbnailUri
      }
      registrationNumber
      dateOfBirth
      gender
      address
      addressDetail
      guardians {
        guardian {
          id
          phone
          email
          gender
          name
          username
          avatar {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
          dateOfBirth
          point
          deletedAt
        }
      }
      caregivers {
        caregiver {
          id
          phone
          email
          gender
          name
          avatar {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
          username
          dateOfBirth
          point
          certificateNumber
          deletedAt
        }
        active
        hasUnreadMessage
        createdAt
        updatedAt
      }
      city {
        cityCode
        name
      }
      createdAt
      updatedAt
      deletedAt
      state {
        stateCode
        name
      }
    }
  }
`;

/**
 * __useGetReceiverQuery__
 *
 * To run a query within a React component, call `useGetReceiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiverQuery({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *   },
 * });
 */
export function useGetReceiverQuery(baseOptions: Apollo.QueryHookOptions<GetReceiverQuery, GetReceiverQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReceiverQuery, GetReceiverQueryVariables>(GetReceiverDocument, options);
}
export function useGetReceiverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReceiverQuery, GetReceiverQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReceiverQuery, GetReceiverQueryVariables>(GetReceiverDocument, options);
}
export type GetReceiverQueryHookResult = ReturnType<typeof useGetReceiverQuery>;
export type GetReceiverLazyQueryHookResult = ReturnType<typeof useGetReceiverLazyQuery>;
export type GetReceiverQueryResult = Apollo.QueryResult<GetReceiverQuery, GetReceiverQueryVariables>;
export const UpdateReceiverDocument = gql`
  mutation UpdateReceiver($receiverInput: ReceiverInput!) {
    updateReceiver(receiverInput: $receiverInput) {
      id
      name
      registrationNumber
      phone
      dateOfBirth
      gender
      createdAt
    }
  }
`;
export type UpdateReceiverMutationFn = Apollo.MutationFunction<UpdateReceiverMutation, UpdateReceiverMutationVariables>;

/**
 * __useUpdateReceiverMutation__
 *
 * To run a mutation, you first call `useUpdateReceiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceiverMutation, { data, loading, error }] = useUpdateReceiverMutation({
 *   variables: {
 *      receiverInput: // value for 'receiverInput'
 *   },
 * });
 */
export function useUpdateReceiverMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateReceiverMutation, UpdateReceiverMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReceiverMutation, UpdateReceiverMutationVariables>(UpdateReceiverDocument, options);
}
export type UpdateReceiverMutationHookResult = ReturnType<typeof useUpdateReceiverMutation>;
export type UpdateReceiverMutationResult = Apollo.MutationResult<UpdateReceiverMutation>;
export type UpdateReceiverMutationOptions = Apollo.BaseMutationOptions<
  UpdateReceiverMutation,
  UpdateReceiverMutationVariables
>;
export const GetReceiverRecordsAsAdminDocument = gql`
  query getReceiverRecordsAsAdmin($receiverId: ID!, $first: Int, $after: String) {
    getReceiverRecordsAsAdmin(receiverId: $receiverId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        author {
          id
          phone
          name
          gender
          avatar {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
          username
        }
        body
        media {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        createdAt
        deletedAt
        updatedAt
        id
      }
    }
  }
`;

/**
 * __useGetReceiverRecordsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetReceiverRecordsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiverRecordsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiverRecordsAsAdminQuery({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetReceiverRecordsAsAdminQuery(
  baseOptions: Apollo.QueryHookOptions<GetReceiverRecordsAsAdminQuery, GetReceiverRecordsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReceiverRecordsAsAdminQuery, GetReceiverRecordsAsAdminQueryVariables>(
    GetReceiverRecordsAsAdminDocument,
    options
  );
}
export function useGetReceiverRecordsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReceiverRecordsAsAdminQuery, GetReceiverRecordsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReceiverRecordsAsAdminQuery, GetReceiverRecordsAsAdminQueryVariables>(
    GetReceiverRecordsAsAdminDocument,
    options
  );
}
export type GetReceiverRecordsAsAdminQueryHookResult = ReturnType<typeof useGetReceiverRecordsAsAdminQuery>;
export type GetReceiverRecordsAsAdminLazyQueryHookResult = ReturnType<typeof useGetReceiverRecordsAsAdminLazyQuery>;
export type GetReceiverRecordsAsAdminQueryResult = Apollo.QueryResult<
  GetReceiverRecordsAsAdminQuery,
  GetReceiverRecordsAsAdminQueryVariables
>;
export const GetReceiverRecordsDocument = gql`
  query GetReceiverRecords($receiverId: ID!, $range: RangeInput!, $first: Int, $after: String) {
    getReceiverRecords(receiverId: $receiverId, range: $range, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        id
        author {
          id
          phone
          gender
          name
          avatar {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
          username
          dateOfBirth
          point
          certificateNumber
        }
        body
        receiver {
          id
          name
        }
        media {
          id
          name
          uri
          size
          type
          createdAt
          thumbnailUri
        }
        createdAt
        deletedAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetReceiverRecordsQuery__
 *
 * To run a query within a React component, call `useGetReceiverRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiverRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiverRecordsQuery({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *      range: // value for 'range'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetReceiverRecordsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReceiverRecordsQuery, GetReceiverRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReceiverRecordsQuery, GetReceiverRecordsQueryVariables>(
    GetReceiverRecordsDocument,
    options
  );
}
export function useGetReceiverRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReceiverRecordsQuery, GetReceiverRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReceiverRecordsQuery, GetReceiverRecordsQueryVariables>(
    GetReceiverRecordsDocument,
    options
  );
}
export type GetReceiverRecordsQueryHookResult = ReturnType<typeof useGetReceiverRecordsQuery>;
export type GetReceiverRecordsLazyQueryHookResult = ReturnType<typeof useGetReceiverRecordsLazyQuery>;
export type GetReceiverRecordsQueryResult = Apollo.QueryResult<
  GetReceiverRecordsQuery,
  GetReceiverRecordsQueryVariables
>;
export const UpdateReportReasonAsAdminDocument = gql`
  mutation UpdateReportReasonAsAdmin($reportReasonInput: ReportReasonInput!) {
    updateReportReasonAsAdmin(reportReasonInput: $reportReasonInput) {
      id
      type
      reason
      active
    }
  }
`;
export type UpdateReportReasonAsAdminMutationFn = Apollo.MutationFunction<
  UpdateReportReasonAsAdminMutation,
  UpdateReportReasonAsAdminMutationVariables
>;

/**
 * __useUpdateReportReasonAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateReportReasonAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportReasonAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportReasonAsAdminMutation, { data, loading, error }] = useUpdateReportReasonAsAdminMutation({
 *   variables: {
 *      reportReasonInput: // value for 'reportReasonInput'
 *   },
 * });
 */
export function useUpdateReportReasonAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReportReasonAsAdminMutation,
    UpdateReportReasonAsAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReportReasonAsAdminMutation, UpdateReportReasonAsAdminMutationVariables>(
    UpdateReportReasonAsAdminDocument,
    options
  );
}
export type UpdateReportReasonAsAdminMutationHookResult = ReturnType<typeof useUpdateReportReasonAsAdminMutation>;
export type UpdateReportReasonAsAdminMutationResult = Apollo.MutationResult<UpdateReportReasonAsAdminMutation>;
export type UpdateReportReasonAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateReportReasonAsAdminMutation,
  UpdateReportReasonAsAdminMutationVariables
>;
export const GetReportReasonsDocument = gql`
  query GetReportReasons($active: Boolean) {
    getReportReasons(active: $active) {
      id
      type
      reason
      active
    }
  }
`;

/**
 * __useGetReportReasonsQuery__
 *
 * To run a query within a React component, call `useGetReportReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportReasonsQuery({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetReportReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportReasonsQuery, GetReportReasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportReasonsQuery, GetReportReasonsQueryVariables>(GetReportReasonsDocument, options);
}
export function useGetReportReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportReasonsQuery, GetReportReasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportReasonsQuery, GetReportReasonsQueryVariables>(GetReportReasonsDocument, options);
}
export type GetReportReasonsQueryHookResult = ReturnType<typeof useGetReportReasonsQuery>;
export type GetReportReasonsLazyQueryHookResult = ReturnType<typeof useGetReportReasonsLazyQuery>;
export type GetReportReasonsQueryResult = Apollo.QueryResult<GetReportReasonsQuery, GetReportReasonsQueryVariables>;
export const GetReportsAsAdminDocument = gql`
  query GetReportsAsAdmin($first: Int, $after: String) {
    getReportsAsAdmin(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        id
        community {
          id
          body
          author {
            id
            phone
            gender
            name
            username
            dateOfBirth
            point
            createdAt
            updatedAt
            deletedAt
          }
          type
          replyCount
          createdAt
          updatedAt
          deletedAt
          media {
            id
            name
            uri
            size
            type
            createdAt
            thumbnailUri
          }
        }
        reason {
          id
          type
          reason
          active
        }
        createdAt
        updatedAt
        customReason
        infoReply {
          id
          info {
            id
            body
            replyCount
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
          deletedAt
          body
        }
        caregiver {
          id
          phone
          gender
          name
          username
          point
          dateOfBirth
          createdAt
          updatedAt
          deletedAt
        }
        guardian {
          id
          phone
          gender
          name
          username
          dateOfBirth
          point
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

/**
 * __useGetReportsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetReportsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetReportsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReportsAsAdminQuery, GetReportsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportsAsAdminQuery, GetReportsAsAdminQueryVariables>(GetReportsAsAdminDocument, options);
}
export function useGetReportsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportsAsAdminQuery, GetReportsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportsAsAdminQuery, GetReportsAsAdminQueryVariables>(
    GetReportsAsAdminDocument,
    options
  );
}
export type GetReportsAsAdminQueryHookResult = ReturnType<typeof useGetReportsAsAdminQuery>;
export type GetReportsAsAdminLazyQueryHookResult = ReturnType<typeof useGetReportsAsAdminLazyQuery>;
export type GetReportsAsAdminQueryResult = Apollo.QueryResult<GetReportsAsAdminQuery, GetReportsAsAdminQueryVariables>;
export const GetServicesDocument = gql`
  query GetServices {
    getServices {
      id
      name
      summary
      price
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a React component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetServicesQuery, GetServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
}
export function useGetServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetServicesQuery, GetServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
}
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesLazyQueryHookResult = ReturnType<typeof useGetServicesLazyQuery>;
export type GetServicesQueryResult = Apollo.QueryResult<GetServicesQuery, GetServicesQueryVariables>;
export const GetServiceDocument = gql`
  query GetService($serviceId: ID!) {
    getService(serviceId: $serviceId) {
      id
      name
      summary
      price
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetServiceQuery__
 *
 * To run a query within a React component, call `useGetServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useGetServiceQuery(baseOptions: Apollo.QueryHookOptions<GetServiceQuery, GetServiceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServiceQuery, GetServiceQueryVariables>(GetServiceDocument, options);
}
export function useGetServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetServiceQuery, GetServiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServiceQuery, GetServiceQueryVariables>(GetServiceDocument, options);
}
export type GetServiceQueryHookResult = ReturnType<typeof useGetServiceQuery>;
export type GetServiceLazyQueryHookResult = ReturnType<typeof useGetServiceLazyQuery>;
export type GetServiceQueryResult = Apollo.QueryResult<GetServiceQuery, GetServiceQueryVariables>;
export const UpdateServiceAsAdminDocument = gql`
  mutation UpdateServiceAsAdmin($serviceInput: ServiceInput!) {
    updateServiceAsAdmin(serviceInput: $serviceInput) {
      id
      name
      summary
      price
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateServiceAsAdminMutationFn = Apollo.MutationFunction<
  UpdateServiceAsAdminMutation,
  UpdateServiceAsAdminMutationVariables
>;

/**
 * __useUpdateServiceAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateServiceAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceAsAdminMutation, { data, loading, error }] = useUpdateServiceAsAdminMutation({
 *   variables: {
 *      serviceInput: // value for 'serviceInput'
 *   },
 * });
 */
export function useUpdateServiceAsAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateServiceAsAdminMutation, UpdateServiceAsAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateServiceAsAdminMutation, UpdateServiceAsAdminMutationVariables>(
    UpdateServiceAsAdminDocument,
    options
  );
}
export type UpdateServiceAsAdminMutationHookResult = ReturnType<typeof useUpdateServiceAsAdminMutation>;
export type UpdateServiceAsAdminMutationResult = Apollo.MutationResult<UpdateServiceAsAdminMutation>;
export type UpdateServiceAsAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceAsAdminMutation,
  UpdateServiceAsAdminMutationVariables
>;
export const GetUserCountsAsAdminDocument = gql`
  query GetUserCountsAsAdmin($range: RangeInput) {
    getUserCountsAsAdmin(range: $range) {
      caregiverCount
      guardianCount
      receiverCount
      resignedUsersCount
    }
  }
`;

/**
 * __useGetUserCountsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUserCountsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCountsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCountsAsAdminQuery({
 *   variables: {
 *      range: // value for 'range'
 *   },
 * });
 */
export function useGetUserCountsAsAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserCountsAsAdminQuery, GetUserCountsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserCountsAsAdminQuery, GetUserCountsAsAdminQueryVariables>(
    GetUserCountsAsAdminDocument,
    options
  );
}
export function useGetUserCountsAsAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserCountsAsAdminQuery, GetUserCountsAsAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserCountsAsAdminQuery, GetUserCountsAsAdminQueryVariables>(
    GetUserCountsAsAdminDocument,
    options
  );
}
export type GetUserCountsAsAdminQueryHookResult = ReturnType<typeof useGetUserCountsAsAdminQuery>;
export type GetUserCountsAsAdminLazyQueryHookResult = ReturnType<typeof useGetUserCountsAsAdminLazyQuery>;
export type GetUserCountsAsAdminQueryResult = Apollo.QueryResult<
  GetUserCountsAsAdminQuery,
  GetUserCountsAsAdminQueryVariables
>;
