import React from "react";
import styled from "styled-components";
import useManagementContainer from "./ManagementContainer.hook";
import BasicButton from "../../common/components/BasicButton";
import LabeledInput from "../../common/components/LabeledInput";
import Loader from "../../common/components/Loader";
import PageWrapper from "../../common/components/PageWrapper";

function ManagementContainer() {
  const {
    models: { loading, state, updateLoading },
    operations: { onInputChange, onSubmit },
  } = useManagementContainer();

  if (loading) return <Loader />;

  const {
    address,
    companyName,
    copyright,
    email,
    ceoName,
    registrationNumber,
    escroRegistration,
    phone,
    kakaoAddress,
    minimumTip,
    invitationPoint,
    shopAddress,
  } = state;

  return (
    <PageWrapper isColumnFlex>
      <Wrapper>
        <LeftSide>
          <LabeledInput
            label="회사명"
            placeholder="회사명"
            value={companyName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "companyName")}
          />
          <LabeledInput
            label="대표자명"
            placeholder="대표자명"
            value={ceoName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "ceoName")}
          />
          <LabeledInput
            label="주소"
            placeholder="주소"
            value={address}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "address")}
          />
          <LabeledInput
            label="전화번호"
            placeholder="전화번호"
            value={phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "phone")}
          />
          <LabeledInput
            label="이메일"
            placeholder="이메일"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "email")}
          />
          <LabeledInput
            label="사업자등록번호"
            placeholder="사업자등록번호"
            value={registrationNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "registrationNumber")}
          />
          <LabeledInput
            label="통신판매업 등록번호"
            placeholder="통신판매업 등록번호"
            value={escroRegistration}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "escroRegistration")}
          />
          <LabeledInput
            label="카피라이트"
            placeholder="카피라이트"
            value={copyright}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "copyright")}
          />
          <LabeledInput
            label="카카오 채널 주소"
            placeholder="카카오 채널 주소"
            value={kakaoAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "kakaoAddress")}
          />
          <LabeledInput
            label="쇼핑몰 주소"
            placeholder="쇼핑몰 주소"
            value={shopAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "shopAddress")}
          />
          <LabeledInput
            type="number"
            inputMode="numeric"
            label="친구초대 포인트"
            placeholder="친구초대 포인트"
            value={invitationPoint}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "invitationPoint")}
          />
          <LabeledInput
            type="number"
            inputMode="numeric"
            label="팁 최소금액"
            placeholder="팁 최소금액"
            value={minimumTip}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "minimumTip")}
          />
          <ButtonWrapper>
            <BasicButton text={updateLoading ? "수정 중" : "수정"} disabled={updateLoading} onClick={onSubmit} />
          </ButtonWrapper>
        </LeftSide>
        <RightSide>
          <Logo src="/carelogue_logo.png" />
        </RightSide>
      </Wrapper>
    </PageWrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.color.white,
  borderRadius: 10,
  padding: theme.spacing.regular,
  minHeight: "100%",
  overflow: "auto",
  gap: 10,
}));

const LeftSide = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.small,
}));

const RightSide = styled.div(() => ({
  flex: 1,
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  width: "100%",
  padding: theme.spacing.regular,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Logo = styled.img(() => ({
  width: 300,
}));

export default ManagementContainer;
