import { useState } from "react";
import {
  GetCaregiverCommunitiesByAuthorAsAdminDocument,
  useDeleteCaregiverCommunityMutation,
} from "../../../lib/apollo/graphql/generated";

function usePostItem(postId: string) {
  const [viewFullPost, setViewFullPost] = useState(false);

  const togglePostModal = () => {
    setViewFullPost(prev => !prev);
  };

  const [deletePost, { loading }] = useDeleteCaregiverCommunityMutation({
    onCompleted: () => {
      window.alert("게시물이 삭제되었습니다.");
      togglePostModal();
    },
    refetchQueries: () => [
      {
        query: GetCaregiverCommunitiesByAuthorAsAdminDocument,
      },
    ],
  });

  const onDelete = async () => {
    try {
      await deletePost({
        variables: {
          communityId: postId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    models: {
      viewFullPost,
      loading,
    },
    operations: {
      togglePostModal,
      onDelete,
    },
  };
}

export default usePostItem;
