import styled from "styled-components";
import { CommonUserInfo, UserType } from "global";
import Modal from "../../common/components/Modal";
import TwoButtons from "../../common/components/TwoButtons";
import BasicUserInfoCard from "./BasicUserInfoCard";
import useDeleteUserModal from "./DeleteUserModal.hook";
import Loader from "../../common/components/Loader";

interface DeleteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  userInfo: CommonUserInfo;
  type: UserType;
}

function DeleteUserModal({ isOpen, onClose, userInfo, type }: DeleteUserModalProps) {
  const {
    models: { loading },
    operations: { onUserDelete },
  } = useDeleteUserModal(type, onClose);

  return (
    <Modal open={isOpen} onClose={onClose} title="회원 삭제">
      {loading ? (
        <Loader />
      ) : (
        <>
          <BasicUserInfoCard
            avatar={userInfo["회원사진"]?.uri}
            name={userInfo["이름" as keyof typeof userInfo] as string}
            nickname={userInfo["닉네임" as keyof typeof userInfo] as string}
            id={userInfo["아이디" as keyof typeof userInfo] as string}
          />
          <Main>
            <Warning>
              삭제한 회원 정보는 복구할 수 없습니다. <span>정말 삭제하시겠습니까?</span>
            </Warning>
            <TwoButtons
              text1="네, 삭제하겠습니다."
              text2="아니요, 취소하겠습니다."
              buttonWidth="200px"
              fontWeight="bold"
              onClick1={() => onUserDelete(userInfo["아이디" as keyof typeof userInfo] as string)}
              onClick2={onClose}
            />
          </Main>
        </>
      )}
    </Modal>
  );
}

const Main = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  height: 200,
  justifyContent: "space-around",
  alignItems: "center",
}));

const Warning = styled.p(() => ({
  width: "100%",
  textAlign: "center",
  fontWeight: "bold",

  "&>span": {
    textDecoration: "underline",
  },
}));

export default DeleteUserModal;
