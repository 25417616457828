import useChangePointsModal from "./ChangePointsModal.hook";
import ChangePointsModalMain from "./ChangePointsModalMain";
import ModalModeSelector from "./ModalModeSelector";
import Modal from "../../common/components/Modal";

interface ChangePointsModalProps {
  isOpen: boolean;
  name: string;
  onClose: () => void;
}

function ChangePointsModal({ onClose, isOpen, name }: ChangePointsModalProps) {
  const {
    models: { mode },
    operations: { changeMode },
  } = useChangePointsModal();

  return (
    <Modal open={isOpen} onClose={onClose} title="포인트 변경">
      <ModalModeSelector mode={mode} changeModeFn={changeMode} />
      <ChangePointsModalMain name={name} mode={mode} isOpen={isOpen} />
    </Modal>
  );
}

export default ChangePointsModal;
