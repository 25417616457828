import { useState } from "react";

function useUserListContainer() {
  const [isAddingUser, setIsAddingUser] = useState(false);

  const toggleAddUserModal = () => setIsAddingUser(prev => !prev);

  return {
    models: {
      isAddingUser,
    },
    operations: {
      toggleAddUserModal,
    },
  };
}

export default useUserListContainer;
