import styled from "styled-components";
import TwoButtons from "../../common/components/TwoButtons";
import { UserType } from "global";

type InfoType = "relationship" | "point" | "post" | "record" | "work";

interface ConfigsProps {
  infoType: string;
  onToggle: (newInfoType: InfoType) => void;
  onButton1Click: () => void;
  onButton2Click: () => void;
  type: UserType;
}

interface InfoTypeButtonProps {
  selected: boolean;
}

function Configs({ infoType, onToggle, onButton1Click, onButton2Click, type }: ConfigsProps) {
  return (
    <ControlsWrapper>
      <InfoTypeToggler>
        {type === "caregivers" && (
          <>
            <InfoTypeButton selected={infoType === "relationship"} onClick={() => onToggle("relationship")}>
              관계도 조회
            </InfoTypeButton>
            <InfoTypeButton selected={infoType === "point"} onClick={() => onToggle("point")}>
              포인트 조회
            </InfoTypeButton>
            <InfoTypeButton selected={infoType === "post"} onClick={() => onToggle("post")}>
              게시물 조회
            </InfoTypeButton>
            <InfoTypeButton selected={infoType === "work"} onClick={() => onToggle("work")}>
              출퇴근 조회
            </InfoTypeButton>
          </>
        )}
        {type === "receivers" && (
          <>
            <InfoTypeButton selected={infoType === "relationship"} onClick={() => onToggle("relationship")}>
              관계도 조회
            </InfoTypeButton>
            <InfoTypeButton selected={infoType === "record"} onClick={() => onToggle("record")}>
              요양기록 조회
            </InfoTypeButton>
          </>
        )}
        {type === "guardians" && (
          <>
            <InfoTypeButton selected={infoType === "relationship"} onClick={() => onToggle("relationship")}>
              관계도 조회
            </InfoTypeButton>
            <InfoTypeButton selected={infoType === "point"} onClick={() => onToggle("point")}>
              포인트 조회
            </InfoTypeButton>
          </>
        )}
      </InfoTypeToggler>
      <TwoButtons
        justifyContent="end"
        text1="포인트 변경"
        onClick1={onButton1Click}
        background1="navy"
        hoverBackground1="lightNavy"
        button1Disabled={type === "receivers"}
        button2Disabled={type === "receivers"}
        text2="회원 삭제"
        onClick2={onButton2Click}
        background2="navy"
        hoverBackground2="lightNavy"
      />
    </ControlsWrapper>
  );
}

const ControlsWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const InfoTypeToggler = styled.div(() => ({
  display: "flex",
}));

const InfoTypeButton = styled.button<InfoTypeButtonProps>(({ theme, selected, disabled }) => ({
  borderBottom: selected ? `2px solid ${theme.color.darkGray}` : `2px solid ${theme.color.gray}`,
  color: theme.color.darkGray,
  padding: theme.spacing.small,
  fontWeight: "bold",
  transition: "0.1s ease-in",
  fontSize: 16,
  width: 120,
  cursor: `${disabled ? "auto" : "pointer"}`,

  "&:hover": {
    color: selected || disabled ? theme.color.darkGray : theme.color.navy,
  },
}));

export default Configs;
