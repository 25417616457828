import { useState } from "react";
import {
  GetPackagesDocument,
  Package,
  PackageInput,
  useUpdatePackageAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function usePackageItem(packageData: Package) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [deletePackage, { loading: deleteLoading }] = useUpdatePackageAsAdminMutation({
    onCompleted: data => {
      if (data?.updatePackageAsAdmin) closeDeleteModal();
    },
    refetchQueries: [
      {
        query: GetPackagesDocument,
      },
    ],
  });

  const openDeleteModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDeleting(true);
  };

  const closeDeleteModal = () => {
    setIsDeleting(false);
  };

  const onHover = () => {
    setIsHovered(true);
  };

  const onHoverExit = () => {
    setIsHovered(false);
  };

  const onDelete = async () => {
    const packageInput: PackageInput = {
      id: packageData.id,
      point: packageData.point,
      initialPrice: packageData.initialPrice,
      finalPrice: packageData.finalPrice,
      name: null,
      active: false,
    };

    await deletePackage({
      variables: { packageInput },
    });
  };

  return {
    models: { isDeleting, isHovered, deleteLoading },
    operations: { closeDeleteModal, openDeleteModal, onHover, onHoverExit, onDelete },
  };
}

export default usePackageItem;
