import { useGetChatsOrderByMessagesCountAsAdminQuery } from "../../../lib/apollo/graphql/generated";

function useChatRanking() {
  const { data, loading } = useGetChatsOrderByMessagesCountAsAdminQuery({
    variables: {
      first: 50,
    },
  });

  return {
    models: {
      data: data?.getChatsOrderByMessagesCountAsAdmin.edges,
      loading,
    },
    operations: {},
  };
}

export default useChatRanking;

// [
//   {
//       "__typename": "Chat",
//       "id": "884b252c-4138-4ae7-befc-e4c5b46d5be5",
//       "chatId": "884b252c-4138-4ae7-befc-e4c5b46d5be5",
//       "createdAt": "2023-10-30T12:59:55.854Z",
//       "updatedAt": "2023-11-20T08:46:06.714Z",
//       "messageCount": 115,
//       "hasUnreadMessage": false,
//       "receiver": {
//           "__typename": "Receiver",
//           "id": "49d2d57b-c68f-4a42-883c-d66f72e4a090",
//           "name": "할아버님",
//           "dateOfBirth": "1933-10-15",
//           "gender": "MALE",
//           "createdAt": "2023-10-30T12:59:55.841Z",
//           "updatedAt": "2023-11-16T07:16:38.090Z"
//       },
//       "users": [
//           {
//               "__typename": "ChatUser",
//               "id": "3b2cd872-2295-45c2-8b71-94b5777a4dc8",
//               "lastSeenAt": "2023-11-20T08:46:30.722Z",
//               "joinAt": "2023-10-30T12:59:56.182Z",
//               "caregiver": null,
//               "guardian": null
//           },
//           {
//               "__typename": "ChatUser",
//               "id": "7e01d155-915c-4db5-b489-ba9bd34aa9a4",
//               "lastSeenAt": "2023-11-20T08:46:02.340Z",
//               "joinAt": "2023-10-31T01:15:40.052Z",
//               "caregiver": null,
//               "guardian": null
//           }
//       ]
//   },
//   {
//       "__typename": "Chat",
//       "id": "dc74b806-92ff-45c0-966d-a5773b8e2607",
//       "chatId": "dc74b806-92ff-45c0-966d-a5773b8e2607",
//       "createdAt": "2023-11-05T13:23:29.373Z",
//       "updatedAt": "2023-11-13T05:56:55.421Z",
//       "messageCount": 94,
//       "hasUnreadMessage": false,
//       "receiver": {
//           "__typename": "Receiver",
//           "id": "ab945137-fd85-4630-942f-14de812c5095",
//           "name": "김철수",
//           "dateOfBirth": "1938-10-22",
//           "gender": "MALE",
//           "createdAt": "2023-11-05T13:23:29.364Z",
//           "updatedAt": "2023-11-21T04:53:51.506Z"
//       },
//       "users": [
//           {
//               "__typename": "ChatUser",
//               "id": "82846398-f515-47dd-8fd1-0708270dc8ba",
//               "lastSeenAt": "2023-11-13T05:56:58.176Z",
//               "joinAt": "2023-11-05T13:23:29.642Z",
//               "caregiver": {
//                   "__typename": "Caregiver",
//                   "id": "9VcrA0DxQXXnGQTINKkq88R19ub2",
//                   "phone": "--",
//                   "gender": "MALE",
//                   "name": "김시험",
//                   "point": 1150,
//                   "createdAt": "2023-09-14T15:43:03.540Z",
//                   "updatedAt": "2023-11-11T13:52:41.769Z",
//                   "deletedAt": "2023-11-08T15:04:21.310Z"
//               },
//               "guardian": null
//           }
//       ]
//   },
//   {
//       "__typename": "Chat",
//       "id": "a76fced6-cf7f-4c1b-97b4-d5a302096c67",
//       "chatId": "a76fced6-cf7f-4c1b-97b4-d5a302096c67",
//       "createdAt": "2023-11-12T05:27:37.175Z",
//       "updatedAt": "2023-11-20T08:26:55.275Z",
//       "messageCount": 43,
//       "hasUnreadMessage": false,
//       "receiver": {
//           "__typename": "Receiver",
//           "id": "b900d785-6e1c-40c3-82c9-30a346c34506",
//           "name": "똘",
//           "dateOfBirth": "2014-11-05",
//           "gender": "MALE",
//           "createdAt": "2023-11-12T05:27:37.166Z",
//           "updatedAt": "2023-11-12T07:34:12.045Z"
//       },
//       "users": [
//           {
//               "__typename": "ChatUser",
//               "id": "3a35d467-ec5d-4735-ae1c-ca52cc33c871",
//               "lastSeenAt": "2023-11-20T08:27:11.253Z",
//               "joinAt": "2023-11-12T05:27:37.580Z",
//               "caregiver": null,
//               "guardian": null
//           },
//           {
//               "__typename": "ChatUser",
//               "id": "5baf9ed8-22b3-4a50-8a1e-2eaefded8475",
//               "lastSeenAt": "2023-11-20T08:27:00.013Z",
//               "joinAt": "2023-11-12T05:27:55.333Z",
//               "caregiver": null,
//               "guardian": null
//           }
//       ]
//   },
//   {
//       "__typename": "Chat",
//       "id": "ef0a7c40-987c-4975-ad7f-af45dd8d409f",
//       "chatId": "ef0a7c40-987c-4975-ad7f-af45dd8d409f",
//       "createdAt": "2023-11-08T05:18:41.899Z",
//       "updatedAt": "2023-11-09T06:15:19.789Z",
//       "messageCount": 21,
//       "hasUnreadMessage": false,
//       "receiver": {
//           "__typename": "Receiver",
//           "id": "c109635e-c9e2-425e-a4b8-be68a6f7edc7",
//           "name": "도라에몽",
//           "dateOfBirth": "2023-11-04",
//           "gender": "FEMALE",
//           "createdAt": "2023-11-08T05:18:41.894Z",
//           "updatedAt": "2023-11-10T01:50:22.922Z"
//       },
//       "users": [
//           {
//               "__typename": "ChatUser",
//               "id": "3758ada3-c1d2-4716-b29d-5dadf8d6e228",
//               "lastSeenAt": "2023-11-09T06:14:04.172Z",
//               "joinAt": "2023-11-08T05:18:42.255Z",
//               "caregiver": null,
//               "guardian": null
//           },
//           {
//               "__typename": "ChatUser",
//               "id": "c421bfb6-3887-4ffa-abcb-4df2a26bbfb8",
//               "lastSeenAt": "2023-11-09T06:15:25.720Z",
//               "joinAt": "2023-11-08T06:40:42.009Z",
//               "caregiver": null,
//               "guardian": null
//           }
//       ]
//   },
//   {
//       "__typename": "Chat",
//       "id": "6c449b68-a116-480e-a9b5-c8d8340aaa14",
//       "chatId": "6c449b68-a116-480e-a9b5-c8d8340aaa14",
//       "createdAt": "2023-11-02T06:04:34.233Z",
//       "updatedAt": "2023-11-02T08:20:58.953Z",
//       "messageCount": 16,
//       "hasUnreadMessage": false,
//       "receiver": {
//           "__typename": "Receiver",
//           "id": "46f897fd-af59-4b4a-ba0a-ee8601a02a01",
//           "name": "할머니",
//           "dateOfBirth": "1930-10-30",
//           "gender": "FEMALE",
//           "createdAt": "2023-11-02T06:04:34.221Z",
//           "updatedAt": "2023-11-02T06:06:37.853Z"
//       },
//       "users": []
//   },
//   {
//       "__typename": "Chat",
//       "id": "98c4789f-d063-4bd2-9a90-173463e6a34d",
//       "chatId": "98c4789f-d063-4bd2-9a90-173463e6a34d",
//       "createdAt": "2023-11-08T05:21:17.082Z",
//       "updatedAt": "2023-11-08T08:20:06.969Z",
//       "messageCount": 13,
//       "hasUnreadMessage": false,
//       "receiver": {
//           "__typename": "Receiver",
//           "id": "61107abd-20d0-46aa-abe2-2c1f3ec7d560",
//           "name": "밍키",
//           "dateOfBirth": "2023-11-03",
//           "gender": "FEMALE",
//           "createdAt": "2023-11-08T05:21:17.076Z",
//           "updatedAt": "2023-11-10T01:59:27.317Z"
//       },
//       "users": [
//           {
//               "__typename": "ChatUser",
//               "id": "13fdab7c-cfb5-4e65-90e6-74312866569d",
//               "lastSeenAt": "2023-11-08T09:06:46.243Z",
//               "joinAt": "2023-11-08T06:42:12.807Z",
//               "caregiver": null,
//               "guardian": null
//           },
//           {
//               "__typename": "ChatUser",
//               "id": "d0a1ca7f-4ae2-4ae3-843d-cee1446657fe",
//               "lastSeenAt": "2023-11-08T08:36:18.303Z",
//               "joinAt": "2023-11-08T05:21:17.228Z",
//               "caregiver": null,
//               "guardian": null
//           }
//       ]
//   },
//   {
//       "__typename": "Chat",
//       "id": "7b9a5c8f-3d05-4ec8-8352-b83daa54a2bc",
//       "chatId": "7b9a5c8f-3d05-4ec8-8352-b83daa54a2bc",
//       "createdAt": "2023-11-20T08:22:56.873Z",
//       "updatedAt": "2023-11-20T08:36:41.199Z",
//       "messageCount": 1,
//       "hasUnreadMessage": false,
//       "receiver": {
//           "__typename": "Receiver",
//           "id": "c31507f8-5c61-4c2d-8c14-399533a0e09c",
//           "name": "이귀순",
//           "dateOfBirth": "1933-11-18",
//           "gender": "FEMALE",
//           "createdAt": "2023-11-20T08:22:56.865Z",
//           "updatedAt": "2023-11-20T08:36:21.758Z"
//       },
//       "users": [
//           {
//               "__typename": "ChatUser",
//               "id": "1578d3b8-21fa-43d6-a340-1bf447fcdf19",
//               "lastSeenAt": "2023-11-20T08:36:47.195Z",
//               "joinAt": "2023-11-20T08:22:57.389Z",
//               "caregiver": null,
//               "guardian": null
//           },
//           {
//               "__typename": "ChatUser",
//               "id": "269f602f-d130-4c4b-a412-62ba598d7fd0",
//               "lastSeenAt": "2023-11-21T08:14:15.411Z",
//               "joinAt": "2023-11-20T08:36:22.072Z",
//               "caregiver": null,
//               "guardian": null
//           }
//       ]
//   }
// ]
