import { useState } from "react";
import { CaregiverInfo } from "../../../lib/apollo/graphql/generated";

function useCaregiverInfoItem(info: CaregiverInfo) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const openDeleteModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDeleting(true);
  };

  const closeDeleteModal = () => {
    setIsDeleting(false);
  };

  const showDeleteButton = () => {
    setIsHovered(true);
  };

  const hideDeleteButton = () => {
    setIsHovered(false);
  };

  const { id, createdAt, updatedAt, deletedAt, replyCount, body, cities, media } = info;
  const thumbnailURI = media?.length ? media[0]?.uri : "/images/default_thumbnail_image.png";

  return {
    models: {
      isHovered,
      isDeleting,
      id,
      createdAt,
      updatedAt,
      deletedAt,
      replyCount,
      body,
      cities,
      thumbnailURI,
    },
    operations: {
      openDeleteModal,
      closeDeleteModal,
      showDeleteButton,
      hideDeleteButton,
    },
  };
}

export default useCaregiverInfoItem;
