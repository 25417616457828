import PaginationDataTable from "../../common/components/PaginationDataTable";
import FetchMore from "../../common/components/FetchMore";
import useCaregiverList from "./CaregiverList.hook";
import Loader from "../../common/components/Loader";
import { SearchFilters } from "global";
import NoResultMessage from "../../userDetail/components/NoResultMessage";

interface CaregiverListProps {
  filters: SearchFilters;
  isFilterApplied: boolean;
}

function CaregiverList({ filters, isFilterApplied }: CaregiverListProps) {
  const {
    refs: { fetchMoreRef },
    models: { caregivers, pageInfo, loading, ignores, order, fetchingMore },
    operations: { moveToUserDetailPage },
  } = useCaregiverList(filters, isFilterApplied);

  if (loading) return <Loader />;

  return (
    <>
      {loading ? (
        <Loader />
      ) : !caregivers?.length ? (
        <NoResultMessage message="결과 없음" />
      ) : (
        <PaginationDataTable
          data={caregivers}
          onDataClick={moveToUserDetailPage}
          ignores={ignores}
          max={15}
          order={order}
        />
      )}
      {fetchingMore && <Loader />}
      {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
    </>
  );
}

export default CaregiverList;
