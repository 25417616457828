import { useState } from "react";

function useChangePointsModalMain() {
  const [changeAmount, setChangeAmount] = useState("1");
  const [isConfirming, setIsConfirming] = useState(false);

  const reset = () => {
    setIsConfirming(false);
    setChangeAmount("1");
  };

  const updatePointInputValue = (event: React.ChangeEvent) => {
    const inputChangeAmount = (event.target as HTMLInputElement).value;

    setChangeAmount(inputChangeAmount);
  };

  const moveToConfirmUI = () => {
    if (changeAmount) setIsConfirming(true);
  };

  const cancelConfirm = () => setIsConfirming(false);

  const changePointsInServer = (mode: string) => {
    const pointsToChange = +changeAmount.trim();

    // 💡 TODO: Replace with API Fn
    window.alert(
      mode === "add"
        ? `Will add ${pointsToChange} points on server!`
        : `Will delete ${pointsToChange} points on server!`
    );

    reset();
  };

  return {
    models: { changeAmount, isConfirming },
    operations: { reset, updatePointInputValue, moveToConfirmUI, cancelConfirm, changePointsInServer },
  };
}

export default useChangePointsModalMain;
