import { useState } from "react";
import { useGetPackagesQuery } from "../../../lib/apollo/graphql/generated";
import { ListMode } from "global";

function usePackageContainer() {
  const [mode, setMode] = useState<ListMode>("activesOnly");
  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(null);

  const { data, loading } = useGetPackagesQuery();

  const onModeChange = (event: React.ChangeEvent) => {
    setMode((event.target as HTMLOptionElement).value as ListMode);
  };

  const onPackageSelect = (packageId: string) => {
    setSelectedPackageId(packageId);
  };

  return {
    models: {
      mode,
      loading,
      selectedPackageId,
      data: data?.getPackages,
      actives: data ? [...data.getPackages].filter(packageData => packageData?.active) : [],
    },
    operations: {
      onModeChange,
      onPackageSelect,
    },
  };
}
export default usePackageContainer;
