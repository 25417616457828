import styled from "styled-components";
import { WorkHour } from "response";
import ShortGridItem from "../../common/components/ShortGridItem";
import { formatDate, formatPhoneNumber, getTimeDifferencesInMinutes } from "../../../utilities/format";
import useWorkHistoryItem from "./WorkHistoryItem.hook";

interface WorkHistoryItemProps {
  workHistory: WorkHour;
}

function WorkHistoryItem({ workHistory }: WorkHistoryItemProps) {
  const {
    models: { createdAt, updatedAt, receiverName, receiverId, receiverPhone },
    operations: { moveToReceiverPage },
  } = useWorkHistoryItem(workHistory);

  return (
    <ShortGridItem>
      <Wrapper>
        <div>
          <TimeWrapper>
            <p>
              <span>출근:</span> {formatDate(createdAt, true)}
            </p>
            <p>➡️</p>
            <p>
              <span>퇴근:</span> {formatDate(updatedAt, true)}
            </p>
          </TimeWrapper>
          <Receiver onClick={() => moveToReceiverPage(receiverId)}>
            <span>수급자:</span> {receiverName} ({formatPhoneNumber(receiverPhone as string)})
          </Receiver>
        </div>
        <TotalWorkTime>{getTimeDifferencesInMinutes(createdAt, updatedAt) + "분"}</TotalWorkTime>
      </Wrapper>
    </ShortGridItem>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
}));

const TimeWrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.regular,
  color: theme.color.darkGray,
  fontSize: 14,

  "&>p": {
    margin: 0,

    "&>span": {
      fontWeight: "bold",
    },
  },
}));

const TotalWorkTime = styled.p(({ theme }) => ({
  margin: 0,
  marginLeft: "auto",
  fontWeight: "bold",
  fontSize: 25,
  color: theme.color.darkNavy,
}));

const Receiver = styled.div(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 14,
  cursor: "pointer",
  transition: "0.1s ease-in",
  width: "fit-content",

  "&>span": {
    fontWeight: "bold",
  },

  "&:hover": {
    color: theme.color.navy,
    textDecoration: "underline",
  },
}));

export default WorkHistoryItem;
