import { ModifiedReceiverRecord } from "response";
import useReceiverRecordItem from "./ReceiverRecordItem.hook";
import ItemWithAuthor from "./ItemWithAuthor";
import ModalWithAuthor from "./ModalWithAuthor";
import { getPreview } from "../../../utilities/format";

interface ReceiverRecordItemProps {
  record: ModifiedReceiverRecord;
}

function ReceiverRecordItem({ record }: ReceiverRecordItemProps) {
  const {
    models: { viewFullRecord },
    operations: { moveToCaregiverDetailPage, toggleRecordModal },
  } = useReceiverRecordItem();

  return (
    <>
      <ModalWithAuthor
        isOpen={viewFullRecord}
        onClose={toggleRecordModal}
        title="요양기록 상세보기"
        authorName={record.author.name}
        authorUri={record.author.avatar?.uri}
        createdAt={record.createdAt}
        body={record.body}
        media={record.media}
      />
      <ItemWithAuthor
        onItemClick={toggleRecordModal}
        onAuthorClick={() => moveToCaregiverDetailPage(record.author.id)}
        authorUri={record.author.avatar?.uri}
        authorName={record.author.name}
        authorTitle="요양보호사"
        createdAt={record.createdAt}
        body={getPreview(record.body)}
      />
    </>
  );
}

export default ReceiverRecordItem;
