import { useState } from "react";

type Mode = "add" | "delete";

function useChangePointsModal() {
  const [mode, setMode] = useState<Mode>("add");

  const changeMode = (newMode: Mode) => {
    setMode(newMode);
  };

  return {
    models: { mode },
    operations: { changeMode },
  };
}

export default useChangePointsModal;
