import styled from "styled-components";
import Modal from "../../common/components/Modal";

interface PhotoModalProps {
  onClose: (event: React.MouseEvent) => void;
  open: boolean;
  src: string;
}

function PhotoModal({ onClose, open, src }: PhotoModalProps) {
  return (
    <Modal open={open} onClose={onClose} width="800px" height="800px" title="사진 확인">
      <ImageWrapper>
        <ZoomedImage src={src} />
      </ImageWrapper>
    </Modal>
  );
}

const ImageWrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.regular,
  marginTop: 20,
  display: "flex",
  justifyContent: "center",
}));

const ZoomedImage = styled.img(({ theme }) => ({
  width: 600,
  height: 600,
  objectFit: "cover",
}));

export default PhotoModal;
