import styled from "styled-components";
import { formatDate } from "../../../utilities/format";
import ActiveStatusIcon from "../../common/components/ActiveStatusIcon";

interface ItemWithAuthorProps {
  children?: React.ReactNode;
  onItemClick?: () => void;
  onAuthorClick?: () => void;
  authorUri?: string;
  authorTitle?: string;
  authorName: string;
  createdAt: string;
  body: string;
  isDeleted?: boolean;
}

interface ClickableItemProps {
  $isClickable: boolean;
}

function ItemWithAuthor({
  children,
  onItemClick,
  onAuthorClick,
  authorUri = "/images/default_user_image.png",
  authorName,
  authorTitle = "회원님",
  createdAt,
  body,
  isDeleted = false,
}: ItemWithAuthorProps) {
  return (
    <Wrapper onClick={onItemClick} $isClickable={!!onItemClick}>
      <AuthorImage onClick={onAuthorClick} $isClickable={!!onAuthorClick}>
        <img src={authorUri} alt="Author Profile" />
      </AuthorImage>
      <Info>
        <Author>
          <AuthorName>
            {authorName} {authorTitle}
          </AuthorName>
          <Dot className="bi bi-dot" />
          <CreatedDate>{formatDate(createdAt, true)}</CreatedDate>
          <ActiveStatusIcon isActive={!isDeleted} />
        </Author>
        {children}
        <BodyPreview>
          <p>{body}</p>
        </BodyPreview>
      </Info>
    </Wrapper>
  );
}

const Wrapper = styled.div<ClickableItemProps>(({ theme, $isClickable }) => ({
  padding: theme.spacing.regular,
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 10,
  display: "flex",
  justifyContent: "space-between",
  gap: 20,
  height: 160,
  maxHeight: 160,
  cursor: "pointer",
  transition: "0.1s ease-in",

  ...($isClickable && {
    "&:hover": {
      border: `1px solid ${theme.color.darkGray}`,
    },
  }),
}));

const Info = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flex: 7,

  "&>p": {
    margin: 0,
  },
}));

const Author = styled.div(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  gap: 5,
}));

const AuthorImage = styled.div<ClickableItemProps>(({ $isClickable }) => ({
  flex: 1,
  transition: "0.1s ease-in",

  "&>img": {
    width: 120,
    height: 120,
    borderRadius: 60,
    objectFit: "cover",
    transform: "scale(1)",
    transition: "0.1 ease-in",

    ...($isClickable && {
      "&:hover": {
        transform: "scale(1.05)",
      },
    }),
  },
}));

const AuthorName = styled.p(({ theme }) => ({
  fontSize: 16,
  fontWeight: "bold",
  color: theme.color.darkGray,
  margin: 0,

  "&>span": {
    fontWeight: "normal",
  },
}));

const CreatedDate = styled.p(({ theme }) => ({
  fontSize: 16,
  fontWeight: "light",
  color: theme.color.gray,
  margin: 0,
}));

const BodyPreview = styled.div(({ theme }) => ({
  color: theme.color.darkGray,
  height: "50px",
  width: "100%",
  maxHeight: "50px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  marginTop: 10,
}));

const Dot = styled.i(({ theme }) => ({
  color: theme.color.gray,
}));

export default ItemWithAuthor;
