import styled from "styled-components";
import NoResultMessage from "./NoResultMessage";
import useReplies from "./Replies.hook";
import Reply from "./Reply";
import Loader from "../../common/components/Loader";
import { Fragment } from "react";
import FetchMore from "../../common/components/FetchMore";

interface RepliesProps {
  postId: string;
}

function Replies({ postId }: RepliesProps) {
  const {
    refs: { fetchMoreRef },
    models: { loading, replies, pageInfo, fetchingMore },
  } = useReplies(postId);

  return (
    <Wrapper>
      {loading ? (
        <Loader />
      ) : !replies?.length ? (
        <NoResultMessage message="댓글 없음" fontSize="16px" />
      ) : (
        <>
          <p>{replies?.length}개의 댓글</p>
          {replies?.map((reply, index) => (
            <Fragment key={index}>
              <Reply reply={reply!} />
              {fetchingMore && <Loader />}
              {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
            </Fragment>
          ))}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  height: "100%",
  marginTop: 20,
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  border: `1px solid ${theme.color.gray}`,
  padding: theme.spacing.small,

  "&>p:first-of-type": {
    color: theme.color.darkGray,
    fontSize: 14,
    fontWeight: "bold",
  },
}));

export default Replies;
