import styled from "styled-components";

function NoAnswerMessage() {
  return (
    <NoAnswersMessage>
      <p>아직 답변이 없습니다. 답변을 추가하세요.</p>
    </NoAnswersMessage>
  );
}

const NoAnswersMessage = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  color: theme.color.gray,
  fontSize: 13,
  fontWeight: "bold",
}));

export default NoAnswerMessage;
