import styled from "styled-components";
import LabeledInfo from "./LabeledInfo";
import { CommonUserInfo } from "global";
import { KR_EQUIVALENTS } from "../../../utilities/constants";

interface UserCardProps {
  avatar?: string;
  data: CommonUserInfo;
  labelWidth?: string;
  isLabelEndAlign?: boolean;
  onClick?: () => void;
}

function UserCard({
  avatar = "/images/default_user_image.png",
  data,
  labelWidth = "50px",
  isLabelEndAlign = false,
  onClick,
}: UserCardProps) {
  const keyUserInfo = ["name", "dateOfBirth", "gender"];

  return (
    <Wrapper onClick={onClick}>
      <UserImage
        src={avatar}
        alt="User Image"
        onError={event => {
          event.currentTarget.src = "/images/default_user_image.png";
        }}
      />
      <TextInfoWrapper>
        {keyUserInfo.map((key, index) => {
          const commonProps = { key: index, label: key, labelWidth, isLabelEndAlign };
          const info =
            KR_EQUIVALENTS[data[key as keyof typeof data] as keyof typeof KR_EQUIVALENTS] ??
            data[key as keyof typeof data];

          return <LabeledInfo {...commonProps} info={info} />;
        })}
      </TextInfoWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  borderRadius: 10,
  display: "flex",
  alignItems: "center",
  marginTop: 10,
  cursor: "pointer",
  transition: "transform 0.1s ease-in",

  "&:hover": {
    transform: "scale(1.01)",
  },
}));

const UserImage = styled.img(() => ({
  width: 75,
  height: 75,
  borderRadius: 60,
  objectFit: "cover",
}));

const TextInfoWrapper = styled.div(() => ({
  marginLeft: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

export default UserCard;
