import styled from "styled-components";
import Modal from "../../common/components/Modal";
import LabeledInput from "../../common/components/LabeledInput";
import BasicButton from "../../common/components/BasicButton";
import useAddServiceModal from "./AddServiceModal.hook";
import Loader from "../../common/components/Loader";

interface AddServiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedServiceId: string;
}

function AddServiceModal({ isOpen, onClose, selectedServiceId }: AddServiceModalProps) {
  const {
    models: { loading, updateLoading, state },
    operations: { onUpdate, onStateChange },
  } = useAddServiceModal(selectedServiceId, onClose);

  return (
    <Modal title={`부가서비스 ${selectedServiceId === "new" ? "추가" : "수정"}`} open={isOpen} onClose={onClose}>
      <ContentsWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            <LabeledInput label="서비스명" value={state.name} onChange={event => onStateChange(event, "name")} />
            <LabeledInput label="설명" value={state.summary} onChange={event => onStateChange(event, "summary")} />
            <LabeledInput
              label="가격"
              type="number"
              min="0"
              value={state.price}
              onChange={event => onStateChange(event, "price")}
            />
            <SubmitButtonWrapper>
              <BasicButton
                text={updateLoading ? "" : `${selectedServiceId === "new" ? "추가" : "수정"}`}
                icon={updateLoading && <Loader />}
                onClick={onUpdate}
                height="35px"
              />
            </SubmitButtonWrapper>
          </>
        )}
      </ContentsWrapper>
    </Modal>
  );
}

const ContentsWrapper = styled.div(({ theme }) => ({
  width: "100%",
  height: "85%",
  padding: `${theme.spacing.regular}px 0`,
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

const SubmitButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "auto",
}));

export default AddServiceModal;
