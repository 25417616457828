import { useState } from "react";

function useAnnouncementCard() {
  const [open, setOpen] = useState(false);

  function toggleModal() {
    setOpen(!open);
  }

  return {
    models: {
      open,
    },
    operations: {
      toggleModal,
    },
  };
}

export default useAnnouncementCard;
