import { useState } from "react";

function useUserList() {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({ query: "", gender: "", state: "", city: "" });
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const changeKeyword = (event: React.ChangeEvent) => {
    const inputKeyword = (event.target as HTMLInputElement).value.trim();
    setFilters(prev => ({ ...prev, query: inputKeyword }));
    setIsFilterApplied(false);
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen(prev => !prev);
  };

  const applyFilters = (event: React.FormEvent) => {
    event.preventDefault();

    setIsFilterApplied(true);
  };

  const cancelFilters = (event: React.FormEvent) => {
    event.preventDefault();

    setIsFilterApplied(false);
    setFilters({ query: "", gender: "", state: "", city: "" });
  };

  const changeFilters = (event: React.ChangeEvent | React.FormEvent) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;

    const { name } = target;
    const value = name === "gender" ? target.id : target.value;
    const type = name === "gender" ? "gender" : target.name;

    setFilters(prev => ({ ...prev, [type]: value }));
    setIsFilterApplied(false);
  };

  return {
    models: {
      filters,
      isFilterModalOpen,
      isFilterApplied,
    },
    operations: {
      changeKeyword,
      toggleFilterModal,
      changeFilters,
      applyFilters,
      cancelFilters,
    },
  };
}

export default useUserList;
