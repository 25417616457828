import styled from "styled-components";
import PageWrapper from "../../common/components/PageWrapper";
import useExampleQuestionsContainer from "./ExampleQuestionsContainer.hook";
import Loader from "../../common/components/Loader";
import QuestionBox from "../components/QuestionBox";
import BasicButton from "../../common/components/BasicButton";
import AddNewQuestionModal from "../components/AddNewQuestionModal";

function ExampleQuestionsContainer() {
  const {
    models: { pageInfo, loading, questions, isAddingQuestion },
    operations: { toggleAddQuestionModal },
  } = useExampleQuestionsContainer();

  if (loading) return <Loader />;

  return (
    <>
      <AddNewQuestionModal isOpen={isAddingQuestion} onClose={toggleAddQuestionModal} />
      <PageWrapper>
        <QuestionList>
          <QuestionListHeader>
            <QuestionCount>예시 질문 목록</QuestionCount>
            <BasicButton text="질문 추가" fontSize="12px" height="80%" onClick={toggleAddQuestionModal} />
          </QuestionListHeader>
          {questions.map((question, index) => (
            <QuestionBox key={index} question={question} number={index + 1} />
          ))}
        </QuestionList>
      </PageWrapper>
    </>
  );
}

const QuestionList = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  backgroundColor: "white",
  padding: theme.spacing.regular,
  borderRadius: 10,
  overflow: "auto",
}));

const QuestionCount = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
}));

const QuestionListHeader = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export default ExampleQuestionsContainer;
