import styled from "styled-components";
import useAddInfoModal from "./AddInfoModal.hook";
import AddInfoModalCitySelector from "./AddInfoModalCitySelector";
import Modal from "../../common/components/Modal";
import TwoButtons from "../../common/components/TwoButtons";
import Loader from "../../common/components/Loader";
import Editor from "../../common/components/Editor";

interface AddInfoModalProps {
  isOpen: boolean;
  selectedInfoId: string;
  onClose: () => void;
}

function AddInfoModal({ isOpen, selectedInfoId, onClose }: AddInfoModalProps) {
  const {
    models: { loading, state, data, updateLoading },
    operations: {
      changeCaregiverInfoInput,
      changeMedia,
      onMediaStateRemove,
      onMediaRemove,
      onCityChange,
      onCityAdd,
      onCityRemove,
      onSubmit,
    },
  } = useAddInfoModal(selectedInfoId, onClose);

  const renderImagePreviews = (media: any, isData: boolean) => {
    return media.map((item: any, index: any) => (
      <div key={index} style={{ position: "relative", margin: "5px" }}>
        <DeleteButton
          onClick={() => {
            if (isData) onMediaRemove(item?.id);
            else onMediaStateRemove(index);
          }}
        >
          X
        </DeleteButton>
        <img
          src={isData ? item.uri : URL.createObjectURL(item)}
          alt={`Preview ${index + 1}`}
          style={{ maxWidth: "200px", maxHeight: "200px", margin: "5px" }}
        />
      </div>
    ));
  };

  return (
    <Modal title="정보글 등록" open={isOpen} onClose={onClose} width="50%" height="95%">
      <ContentsWrapper>
        {/* <TextareaWrapper>
          <p>정보글 내용:</p>
          <textarea value={state?.body} onChange={changeCaregiverInfoInput} />
        </TextareaWrapper> */}
        <Editor value={state?.body} onChange={changeCaregiverInfoInput} />
        <ImageWrapper>
          <ImageInputWrapper>
            <HiddenFileInput type="file" multiple accept="image/*" onChange={changeMedia} />
            <ImageInputLabel htmlFor="hiddenFileInput">이미지를 선택하세요.</ImageInputLabel>
          </ImageInputWrapper>
          <ImagePreviewsContainer>
            {!!data?.media && data?.media?.length > 0 && renderImagePreviews(data?.media, true)}
            {!!state?.mediaState && state?.mediaState?.length > 0 && renderImagePreviews(state?.mediaState, false)}
            {!state?.mediaState?.length && !data?.media?.length && <NoImagesMessage>No Images Yet</NoImagesMessage>}
          </ImagePreviewsContainer>
        </ImageWrapper>
        {state?.cities?.map((city, index) => (
          <AddInfoModalCitySelector
            key={city?.cityCode || "city"}
            city={city}
            onCityChange={(selectedCity: { cityCode: string; stateCode: string }) => onCityChange(selectedCity, index)}
            onCityRemove={() => onCityRemove(index)}
          />
        ))}
        <TwoButtons
          text1="도시 추가"
          background1="navy"
          hoverBackground1="lightNavy"
          onClick1={onCityAdd}
          text2={updateLoading ? "" : "정보글 등록"}
          icon2={updateLoading && <Loader />}
          background2="navy"
          hoverBackground2="lightNavy"
          onClick2={onSubmit}
        />
      </ContentsWrapper>
    </Modal>
  );
}

const ContentsWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "80%",
  justifyContent: "space-between",
  padding: theme.spacing.small,
}));

const TextareaWrapper = styled.div(({ theme }) => ({
  width: "100%",
  flex: 1,

  "&>p": {
    margin: 0,
    color: theme.color.darkGray,
    fontWeight: "bold",
  },

  "&>textarea": {
    width: "100%",
    height: "80%",
    minHeight: 150,
  },
}));

const ImageWrapper = styled.div(() => ({
  flex: 1,
  width: "100%",
}));

const ImageInputWrapper = styled.div(({ theme }) => ({
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  borderRadius: "4px",
  border: `1px solid ${theme.color.gray}`,
  width: "100%",
}));

const HiddenFileInput = styled.input(({ theme }) => ({
  opacity: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  zIndex: 1,
  top: 0,
  left: 0,
  overflow: "hidden",
}));

const ImageInputLabel = styled.label(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  width: "100%",
  cursor: "pointer",
  transition: "0.1s ease-in",
  display: "block",
  padding: "8px",
  borderRadius: "4px",

  "&:hover": {
    border: `1px solid ${theme.color.darkNavy}`,
  },
}));

const ImagePreviewsContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: "10px",
  maxHeight: "300px",
  overflowY: "auto",
  border: `1px dashed ${theme.color.gray}`,
  minHeight: 200,
}));

const DeleteButton = styled.button(({ theme }) => ({
  background: "none",
  border: "none",
  color: theme.color.gray,
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  margin: "5px",
  padding: 0,
  transition: "0.1s ease-in",
  position: "absolute",
  right: -3,
  top: -5,

  "&:hover": {
    color: theme.color.error,
  },
}));

const NoImagesMessage = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontStyle: "italic",
  width: "100%",
  height: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: 0,
}));

export default AddInfoModal;
