import Loader from "../../common/components/Loader";
import useWorkHistory from "./WorkHistory.hook";
import { WorkHour } from "response";
import FetchMore from "../../common/components/FetchMore";
import NoResultMessage from "./NoResultMessage";
import WorkHistoryItem from "./WorkHistoryItem";
import InfoListWrapper from "./InfoListWrapper";

function WorkHistory() {
  const {
    refs: { fetchMoreRef },
    models: { data, loading, fetchingMore, refetching, pageInfo, range },
    operations: { changeFrom, changeTo, applyRange, cancelRange },
  } = useWorkHistory();

  return (
    <InfoListWrapper
      onToChange={changeTo}
      onFromChange={changeFrom}
      onApplyRange={applyRange}
      onCancelRange={cancelRange}
      to={range.to}
      from={range.from}
    >
      {loading || refetching ? (
        <Loader />
      ) : (
        <>
          {data?.length ? (
            data?.map(workHistory => {
              console.log(workHistory);
              return <WorkHistoryItem key={workHistory?.id} workHistory={workHistory as WorkHour} />;
            })
          ) : (
            <NoResultMessage />
          )}
          {fetchingMore && <Loader />}
          {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
        </>
      )}
    </InfoListWrapper>
  );
}

export default WorkHistory;
