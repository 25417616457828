import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetCaregiverProfileQuery,
  useGetReceiverQuery,
  useGetGuardianProfileQuery,
} from "../../../lib/apollo/graphql/generated";
import { formatSingleData, extractRelatedUsers } from "../../../utilities/format";
import { UserType, CommonUserInfo } from "global";

type InfoType = "relationship" | "point" | "post" | "record" | "work";

const TYPE_TO_API = {
  caregivers: { fn: useGetCaregiverProfileQuery, variableName: "caregiverId" },
  receivers: { fn: useGetReceiverQuery, variableName: "receiverId" },
  guardians: { fn: useGetGuardianProfileQuery, variableName: "guardianId" },
};

function useUserDetailContainer(type: UserType) {
  const { userId } = useParams();

  const [infoType, setInfoType] = useState<InfoType>("relationship");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAddingPoints, setIsAddingPoints] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [userInfo, setUserInfo] = useState<CommonUserInfo>();
  const [relatedUsers, setRelatedUsers] = useState<{
    [key: string]: [] | CommonUserInfo[];
  }>();

  useEffect(() => {
    if (
      (type !== "receivers" && infoType === "record") ||
      (type !== "caregivers" && (infoType === "post" || infoType === "work"))
    ) {
      setInfoType("relationship");
    }
  }, [infoType, type]);

  const toggleDeletUserModal = () => setIsDeletingUser(prev => !prev);

  const toggleAddPointModal = () => setIsAddingPoints(prev => !prev);

  const changeInfoType = (newInfoType: InfoType) => {
    if (infoType === newInfoType) return;
    setInfoType(newInfoType);
  };

  const { loading } = TYPE_TO_API[type as keyof typeof TYPE_TO_API].fn({
    variables: { [TYPE_TO_API[type as keyof typeof TYPE_TO_API].variableName]: userId! } as any,
    onCompleted: data => {
      const firstNest = `get${type.charAt(0).toUpperCase() + type.slice(1).slice(0, -1)}${
        type !== "receivers" ? "Profile" : ""
      }`;

      const extracted = data?.[firstNest as keyof typeof data];

      const user: CommonUserInfo = formatSingleData(extracted);
      const relatedUsersInfo = extractRelatedUsers(user);

      setIsDeleted(!!user["삭제일"]);
      setUserInfo(user);
      setRelatedUsers(relatedUsersInfo);
    },
  });

  return {
    models: {
      isAddingPoints,
      isDeletingUser,
      infoType,
      loading,
      isDeleted,
      userInfo,
      relatedUsers,
    },
    operations: {
      toggleAddPointModal,
      toggleDeletUserModal,
      changeInfoType,
    },
  };
}

export default useUserDetailContainer;
