import styled from "styled-components";

interface LoaderProps {
  backgroundColor?: string;
}

interface WrapperProps {
  $backgroundColor: string;
}

function Loader({ backgroundColor = "inherit " }: LoaderProps) {
  return (
    <Wrapper $backgroundColor={backgroundColor}>
      <div className="spinner-grow spinner-grow-sm text-primary" role="status"></div>
      <div className="spinner-grow spinner-grow-sm text-primary" role="status"></div>
      <div className="spinner-grow spinner-grow-sm text-primary" role="status"></div>
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ $backgroundColor }) => ({
  backgroundColor: $backgroundColor,
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
}));

export default Loader;
