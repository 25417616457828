import styled from "styled-components";

interface DeletedUserMessageProps {
  deletedAt: string;
}

function DeletedUserMessage({ deletedAt }: DeletedUserMessageProps) {
  return (
    <Wrapper>
      <p>탈퇴한 회원입니다.</p>
      <span>탈퇴 날짜: {deletedAt}</span>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  width: "100%",
  height: "100%",
  color: theme.color.gray,
  fontSize: 20,
  fontWeight: "bold",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "&>span": {
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "italic",
  },
}));

export default DeletedUserMessage;
