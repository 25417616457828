import { KR_EQUIVALENTS, WEEKDAYS_KR } from "./constants";
import { GeneralDataObject, CommonUserInfo, Location } from "global";

/**
 * @param phoneNumber raw phone number string fetched from the server (+82...)
 * @returns phone number string in the format of (010-xxxx-xxxx)
 */
export const formatPhoneNumber = (phoneNumber: string | number) => {
  const removed82 = phoneNumber.toString().replace("+82", "0");
  const addedDash = removed82.slice(0, 3) + "-" + removed82.slice(3, 7) + "-" + removed82.slice(7);

  return addedDash;
};

/**
 * @param dateString date string
 * @param hasTime (optional) boolean
 * @returns date string in 'yyyy-mm-dd' format. If hasTime is true, returns 'yyyy.mm.dd Weekday hh:mm'.
 */
export const formatDate = (dateString: string | number, hasTime = false) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const weekday = date.getDay();
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");

  return hasTime ? `${year}.${month}.${day} ${WEEKDAYS_KR[weekday]} ${hour}:${minute}` : `${year}-${month}-${day}`;
};

/**
 *
 * @param rawData server fetched data that is wrapped in an additional layer of object
 * @returns the extracted object that doesn't have the extra object layer
 */
export const extractData = <TData, TExtracted>(rawData: TData) => {
  const extractedData = rawData[Object.keys(rawData as Object)[0] as keyof typeof rawData] as TExtracted;

  return extractedData;
};

/**
 * formats one single data object and returns a more conveniently formatted data object
 * @param extractedData  one data object that is already extracted from its extra layer
 * @returns formatted data with Korean keys and formatted dates, phone numbers, and etc.
 */
export const formatSingleData = (extractedData: any): any => {
  const newData: any = {};
  const keys = Object.keys(extractedData as object);

  type NewDataKey = keyof typeof newData;
  type NewDataValue = (typeof newData)[NewDataKey];

  for (const key of keys) {
    if (key === "addressDetail" || key === "state") continue;

    const krKey = KR_EQUIVALENTS[key as keyof typeof KR_EQUIVALENTS];
    const value = extractedData[key as keyof typeof extractedData];

    // Case 1: if phone number needs to be formatted into "010-0000-0000" format
    if (key === "phone") {
      const phoneNumber = extractedData["deletedAt"] ? null : formatPhoneNumber(value as string);
      newData[krKey as NewDataKey] = phoneNumber as NewDataValue;
      continue;
    }

    // Case 2: if the date needs to be formatted to "yyyy-mm-dd" format
    if (key === "createdAt" || key === "updatedAt") {
      const date = formatDate(value as string);
      newData[krKey as keyof typeof newData] = date as NewDataValue;
      continue;
    }

    // Case 3: if looping through address, and addressDetail exists, combine the two together into address
    if (key === "address" && extractedData["addressDetail" as keyof typeof extractedData]) {
      newData[krKey as NewDataKey] = ((value as string) +
        " " +
        extractedData["addressDetail" as keyof typeof extractedData]) as string as NewDataValue;
      continue;
    }

    // Case 4: if looping througb city value, combine it with state and make a location (지역) property
    if (key === "city") {
      const state = (extractedData["state" as keyof typeof extractedData] as Location).name;
      const city = (value as Location).name;
      newData["지역" as NewDataKey] = `${state} ${city}` as NewDataValue;
      continue;
    }

    // Case 5: if looping through point value, make it a string with "P" at the end
    if (key === "point") {
      const pointsInString = (value as String) + " P";
      newData[krKey as NewDataKey] = pointsInString as NewDataValue;
      continue;
    }

    // Case 6: if checking guardian profile and need to retrieve caregivers
    if (key === "receivers" && value.length) {
      newData["수급자"] = value;
      if (value[0].caregivers) newData["보호사"] = value[0].caregivers;
      if (value[0].guardians) newData["보호자"] = value[0].guardians;
      continue;
    }

    // Case 7: if the key is answers, display answers count and leave answers array inside
    if (key === "answers") {
      const allAnswers = extractedData[key as keyof typeof extractedData];
      const activeAnswers = [];

      for (const answer of allAnswers) {
        if (answer.active) activeAnswers.push(answer);
      }

      newData[krKey as NewDataKey] = activeAnswers.length;
      newData["__answers" as NewDataKey] = activeAnswers;
      continue;
    }

    // Case 8: if the key is deletedAt, check if the date actually exists and then format it if needed
    if (key === "deletedAt") {
      const deletedDate = value ? formatDate(value as string) : null;
      newData[krKey as NewDataKey] = deletedDate;
      continue;
    }

    // Case 9: if looping through multiple cities value, just skip it
    if (key === "cities") continue;

    // All Else: Simply assign without any additional steps { value, show }
    if (krKey) {
      newData[krKey as NewDataKey] = value as NewDataValue;
    } else {
      const capitalizedEngKey = key.charAt(0).toUpperCase() + key.slice(1);
      newData[capitalizedEngKey as NewDataKey] = value as NewDataValue;
    }
  }

  return newData;
};

/**
 * formats all object data within an array of data objects (repeatedly invokes 'formatSingleData' on its own *)
 * @param extractedData array of data objects that are already extracted from their extra layer
 * @returns an array of formatted data with Korean keys and formatted dates, phone numbers, and etc.
 */
export const formatMultipleData = <T>(extractedData: GeneralDataObject[]): T[] => {
  const formattedData: T[] = [];

  extractedData.forEach(data => {
    const formattedSingleData = formatSingleData(data);
    formattedData.push(formattedSingleData);
  });

  return formattedData;
};

export const extractRelatedUsers = (formattedUserData: CommonUserInfo): { [key: string]: CommonUserInfo[] | [] } => {
  const relevantUsers = {
    caregivers: formattedUserData["보호사"] || [],
    receivers: formattedUserData["수급자"] || [],
    guardians: formattedUserData["보호자"] || [],
  };

  if (relevantUsers.caregivers.length && "caregiver" in relevantUsers.caregivers[0]) {
    const caregivers: CommonUserInfo[] = [];
    relevantUsers.caregivers.forEach(({ caregiver }) => caregivers.push(caregiver as CommonUserInfo));
    relevantUsers.caregivers = caregivers;
  }

  if (relevantUsers.guardians.length && "guardian" in relevantUsers.guardians[0]) {
    const guardians: CommonUserInfo[] = [];
    relevantUsers.guardians.forEach(({ guardian }) => guardians.push(guardian as CommonUserInfo));
    relevantUsers.guardians = guardians;
  }

  if (relevantUsers.receivers.length && "receivers" in relevantUsers.receivers[0]) {
    const receivers: CommonUserInfo[] = [];
    relevantUsers.receivers.forEach(({ receiver }) => receivers.push(receiver as CommonUserInfo));
    relevantUsers.receivers = receivers;
  }

  return relevantUsers;
};

export const getPreview = (body: string) => {
  return body.length > 100 ? body.slice(0, 100) + "..." : body;
};

export const getTimeDifferencesInMinutes = (start: string, end: string) => {
  const startDate = Date.parse(start);
  const endDate = Date.parse(end);

  return Math.ceil((endDate - startDate) / (1000 * 60));
};

export const getTwelveMonths = () => {
  const current = new Date();

  let thisMonth: { from: string; to: string } | null = null;
  const twelveMonths: Array<{ from: string; to: string }> = [];

  for (let i = 11; i >= 0; i -= 1) {
    const startDate = new Date(current);
    const endDate = new Date(current);

    startDate.setMonth(current.getMonth() - i);
    startDate.setDate(1);

    endDate.setMonth(current.getMonth() - (i - 1));
    endDate.setDate(0);

    const from = startDate.toISOString().split("T")[0];
    const to = endDate.toISOString().split("T")[0];

    twelveMonths.push({ from: startDate.toISOString().split("T")[0], to: endDate.toISOString().split("T")[0] });
    if (i === 0) thisMonth = { from, to };
  }

  return { twelveMonths, thisMonth };
};
