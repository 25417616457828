import {
  GetCaregiverInfosDocument,
  useDeleteCaregiverInfoAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import { FETCH_SIZE } from "../../../utilities/constants";

function useDeleteInfoModal(infoId: string, onClose: () => void) {
  const [deleteInfo, { loading }] = useDeleteCaregiverInfoAsAdminMutation({
    variables: {
      infoId,
    },
    onCompleted: data => {
      onClose();
    },
    refetchQueries: () => [
      {
        query: GetCaregiverInfosDocument,
        variables: {
          first: FETCH_SIZE,
        },
      },
    ],
  });

  const onDeleteInfo = async () => {
    await deleteInfo();
  };

  return {
    models: { loading },
    operations: { onDeleteInfo },
  };
}

export default useDeleteInfoModal;
