import styled from "styled-components";
import ShortGridItem from "../../common/components/ShortGridItem";
import { PointTransaction } from "../../../lib/apollo/graphql/generated";
import { CommonUserInfo, UserType } from "global";
import ColoredLabel from "../../common/components/ColoredLabel";
import useTransactionItem from "./TransactionItem.hook";

interface TransactionItemProps {
  transaction: PointTransaction;
  userInfo: CommonUserInfo;
  type: UserType;
}

function TransactionItem({ transaction, userInfo, type }: TransactionItemProps) {
  const {
    models: {
      avatarUri,
      statusLabelText,
      counterpartyName,
      statusLabelBgColor,
      operator,
      message,
      transactionDate,
      transactionAmount,
      transactionTypeLabelText,
    },
  } = useTransactionItem(transaction, userInfo, type);

  return (
    <ShortGridItem>
      <Wrapper>
        <CounterpartyImage src={avatarUri} />
        <Info>
          <MessageInfo>
            <ColoredLabel text={statusLabelText} backgroundColor={statusLabelBgColor} />
            <Counterparty>{counterpartyName}</Counterparty>
            <Date>{transactionDate}</Date>
          </MessageInfo>
          <Message>{message?.length ? <p>{message}</p> : <span>메세지 없음</span>}</Message>
        </Info>
        <TransactionInfo>
          <TransactionAmount>
            <span>{operator}</span>
            {transactionAmount}
          </TransactionAmount>
          <ColoredLabel text={transactionTypeLabelText} backgroundColor="gray" />
        </TransactionInfo>
      </Wrapper>
    </ShortGridItem>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.regular,
  height: "100px",
}));

const Info = styled.div(({ theme }) => ({
  minWidth: "60%",
  width: "fit-content",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: theme.spacing.small,
}));

const Message = styled.div(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 18,
  fontWeight: "bold",
  margin: 0,
  height: "100%",
  width: "95%",
  overflow: "auto",

  "&>p": {
    margin: 0,
  },

  "&>span": {
    color: theme.color.gray,
  },
}));

const MessageInfo = styled.div(() => ({
  display: "flex",
  gap: 10,
  heigth: "fit-content",
  "&>p": {
    margin: 0,
  },
}));

const TransactionInfo = styled.div(() => ({
  marginLeft: "auto",
  display: "flex",
  width: "fit-content",
  flexDirection: "column",
  alignItems: "end",
}));

const TransactionAmount = styled.p(({ theme }) => ({
  color: theme.color.darkNavy,
  margin: 0,
  fontWeight: "bold",
  fontSize: 25,
  textAlign: "end",
}));

const CounterpartyImage = styled.img(() => ({
  width: 100,
  minWidth: 100,
  height: 100,
  borderRadius: "100%",
  objectFit: "cover",
}));

const Counterparty = styled.p(() => ({
  margin: 0,
}));

const Date = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontSize: 14,
}));

export default TransactionItem;
