import { useEffect } from "react";
import styled from "styled-components";
import ConfirmPointChange from "./ConfirmPointChange";
import ChangePointAmountInputArea from "./ChangePointAmountInputArea";
import useChangePointsModalMain from "./ChangePointsModalMain.hook";

interface ChangePointsModalMainProps {
  name: string;
  mode: string;
  isOpen: boolean;
}

function ChangePointsModalMain({ name, mode, isOpen }: ChangePointsModalMainProps) {
  const {
    models: { isConfirming, changeAmount },
    operations: { reset, updatePointInputValue, moveToConfirmUI, cancelConfirm, changePointsInServer },
  } = useChangePointsModalMain();

  useEffect(() => reset(), [isOpen]);

  return (
    <Wrapper>
      {isConfirming ? (
        <ConfirmPointChange
          name={name}
          mode={mode}
          changeAmount={changeAmount}
          cancelFn={cancelConfirm}
          postConfirmFn={changePointsInServer}
        />
      ) : (
        <ChangePointAmountInputArea
          name={name}
          mode={mode}
          changeAmount={changeAmount}
          changeFn={updatePointInputValue}
          preConfirmFn={moveToConfirmUI}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  marginTop: 20,
  height: 350,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
}));

export default ChangePointsModalMain;
