import { UserType } from "global";
import { useState } from "react";

type UserTypes = UserType | "select";

function useAddUserModal() {
  const [userType, setUserType] = useState<UserTypes>("select");

  const changeUserType = (event: React.ChangeEvent) => {
    setUserType((event.target as HTMLSelectElement).value as UserTypes);
  };

  return {
    models: { userType },
    operations: { changeUserType },
  };
}

export default useAddUserModal;
