import { useState } from "react";
import { ExtractedData } from "response";
import {
  useGetExmapleQuestionsAsAdminQuery,
  GetExmapleQuestionsAsAdminQuery,
} from "../../../lib/apollo/graphql/generated";
import { FormattedExampleQuestion } from "response";
import { extractData, formatMultipleData } from "../../../utilities/format";

function useExampleQuestionsContainer() {
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);

  const { data, loading } = useGetExmapleQuestionsAsAdminQuery({
    variables: {
      first: 15,
    },
  });

  let pageInfo = {};
  let questions: FormattedExampleQuestion[] = [];

  if (!loading) {
    const extractedData = extractData<GetExmapleQuestionsAsAdminQuery | undefined, ExtractedData>(data);
    pageInfo = extractedData.pageInfo;
    questions = formatMultipleData<FormattedExampleQuestion>(extractedData.edges);
  }

  const toggleAddQuestionModal = () => {
    setIsAddingQuestion(prev => !prev);
  };

  return {
    models: { pageInfo, loading, questions, isAddingQuestion },
    operations: { toggleAddQuestionModal },
  };
}

export default useExampleQuestionsContainer;
