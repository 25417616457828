import { useEffect, useState } from "react";
import { useGetUserCountsAsAdminLazyQuery, useGetUserCountsAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { getTwelveMonths } from "../../../utilities/format";

const USER_TYPE_TO_LABEL = {
  caregivers: "신규 요양보호사 수",
  guardians: "신규 보호자 수",
  receivers: "신규 수급자 수",
  resigned: "탈퇴 회원 수",
};

interface ChartDataset {
  labels: string[];
  datasets: Array<{
    label: string;
    data: Array<undefined | number>;
    borderWidth?: number;
    borderColor?: string;
  }>;
}

function useUserStats() {
  const { thisMonth, twelveMonths } = getTwelveMonths();
  const [userTrend, setUserTrend] = useState<ChartDataset>();
  const [userTrendLoading, setUserTrendLoading] = useState(true);

  const { data: currentMonthUserTrend, loading: currentMonthLoading } = useGetUserCountsAsAdminQuery({
    variables: {
      range: thisMonth,
    },
  });

  const [getUserCountsByMonth] = useGetUserCountsAsAdminLazyQuery();

  useEffect(() => {
    (async () => {
      try {
        const allUserCounts = await Promise.all(
          twelveMonths.map(async month => {
            try {
              const { data } = await getUserCountsByMonth({
                variables: {
                  range: month,
                },
              });

              const caregivers = data?.getUserCountsAsAdmin.caregiverCount;
              const guardians = data?.getUserCountsAsAdmin.guardianCount;
              const receivers = data?.getUserCountsAsAdmin.receiverCount;
              const resigned = data?.getUserCountsAsAdmin.resignedUsersCount;

              return {
                [month.from.split("-")[0] + "." + month.from.split("-")[1]]: {
                  caregivers,
                  guardians,
                  receivers,
                  resigned,
                },
              };
            } catch (error) {
              console.log(`[Error at ${month}]`, error);
              return -1;
            }
          })
        );

        const labels = allUserCounts.map(monthlyData => Object.keys(monthlyData!)[0]);

        const datasets = ["caregivers", "receivers", "guardians", "resigned"].map(userType => {
          const label = USER_TYPE_TO_LABEL[userType as keyof typeof USER_TYPE_TO_LABEL];
          const data = allUserCounts.map(userCounts => {
            const date = Object.keys(userCounts)[0];
            const counts = userCounts[date as keyof typeof userCounts];

            return counts[userType as keyof typeof counts];
          });

          return {
            label,
            data,
          };
        });

        setUserTrend({
          labels,
          datasets,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setUserTrendLoading(false);
      }
    })();
  }, []);

  return {
    models: {
      currentMonth: currentMonthUserTrend?.getUserCountsAsAdmin,
      isLoading: !!(currentMonthLoading || userTrendLoading),
      userTrend,
    },
    operations: {},
  };
}

export default useUserStats;
