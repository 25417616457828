import { useEffect, useState } from "react";
import {
  useGetStatesQuery,
  useGetCitiesByStateLazyQuery,
  GetStatesQuery,
  GetCitiesByStateQuery,
} from "../../../lib/apollo/graphql/generated";
import { extractData } from "../../../utilities/format";

function useSearchFilterModal(currentState: string) {
  const { data: stateData, loading: stateLoading } = useGetStatesQuery();
  const [cities, setCities] = useState<Array<{ __typename?: "City"; name: string } | null>>([]);
  const [getCitiesByState, { data: cityData, loading: cityLoading }] = useGetCitiesByStateLazyQuery();

  let states: Array<{ __typename?: "State"; stateCode: string; name: string } | null> = [];

  if (!stateLoading) {
    states = extractData<
      GetStatesQuery | undefined,
      Array<{ __typename?: "State"; stateCode: string; name: string } | null>
    >(stateData);
  }

  useEffect(() => {
    if (currentState) {
      getCitiesByState({
        variables: {
          stateCode: currentState,
        },
      });
    } else {
      setCities([]);
    }
  }, [currentState]);

  useEffect(() => {
    if (!cityLoading && cityData) {
      setCities(
        extractData<GetCitiesByStateQuery | undefined, Array<{ __typename?: "City"; name: string } | null>>(cityData)
      );
    }
  }, [cityData, cityLoading]);

  return {
    models: { cities, states },
    operations: {},
  };
}

export default useSearchFilterModal;
