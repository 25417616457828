import { useState } from "react";
import { useGetReportReasonsQuery } from "../../../lib/apollo/graphql/generated";

function useReportContainer() {
  const [isAdding, setIsAdding] = useState(false);

  const { data, loading } = useGetReportReasonsQuery({
    variables: {
      active: true,
    },
  });

  if (!loading) console.log(data?.getReportReasons);

  const openAddModal = () => {
    setIsAdding(true);
  };

  const closeAddModal = () => {
    setIsAdding(false);
  };

  return {
    models: { isAdding, loading, data: data?.getReportReasons },
    operations: { openAddModal, closeAddModal },
  };
}

export default useReportContainer;
