import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetAnnouncementsQuery } from "../../../lib/apollo/graphql/generated";

function useAnnouncementContainer() {
  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  const [fetchingMore, setFetchingMore] = useState(false);
  const [open, setOpen] = useState(false);

  const { loading, data, fetchMore, refetch } = useGetAnnouncementsQuery({
    variables: {
      onBanner: null,
      active: null,
      first: 9,
    },
  });

  function onViewChange(inView: boolean) {
    const pageInfo = data?.getAnnouncements?.pageInfo;
    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          onBanner: null,
          active: null,
          first: 9,
          after: pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  function onRefetchClick() {
    refetch();
  }

  function onRegisterClick() {
    setOpen(true);
  }

  function onClose() {
    setOpen(!open);
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAnnouncements?.edges,
      open,
    },
    operations: {
      onRefetchClick,
      onRegisterClick,
      onClose,
    },
  };
}

export default useAnnouncementContainer;
