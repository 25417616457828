import styled from "styled-components";

const LABEL_COLORS = {
  green: "#44cf44de",
  red: "#f64545c5",
  blue: "#788bacf9",
  gray: "#d5d5d5",
};

const TEXT_COLORS = {
  green: "#d2ffd2",
  red: "#ffd6d6",
  blue: "#c1d8ff",
  gray: "#777777",
};

type LabelColors = "green" | "red" | "gray" | "blue";

interface ColoredLabelProps {
  text?: string;
  backgroundColor?: LabelColors;
  icon?: React.ReactNode;
}

interface WrapperProps {
  $backgroundColor: LabelColors;
}

function ColoredLabel({ text, backgroundColor = "blue", icon }: ColoredLabelProps) {
  return (
    <Wrapper>
      <Label $backgroundColor={backgroundColor}>
        {icon && icon}
        {text && <p>{text}</p>}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
}));

const Label = styled.div<WrapperProps>(({ theme, $backgroundColor }) => ({
  backgroundColor: LABEL_COLORS[$backgroundColor],
  borderRadius: 5,
  padding: `0 ${theme.spacing.small}px`,
  color: TEXT_COLORS[$backgroundColor],
  fontWeight: "bold",
  fontSize: 12,
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: 5,

  "&>p": {
    margin: 0,
  },
}));

export default ColoredLabel;
