import { useEffect, useState } from "react";
import {
  GetPackagesDocument,
  PackageInput,
  useGetPackageLazyQuery,
  useUpdatePackageAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

interface State {
  id: string | null;
  point: number;
  initialPrice: number;
  finalPrice: number;
}

function useUpdatePackageModal(selectedPackageId: string, onClose: () => void) {
  const [state, setState] = useState<State>({
    id: null,
    point: 0,
    initialPrice: 0,
    finalPrice: 0,
  });

  const initializeState = () => {
    setState({
      id: null,
      point: 0,
      initialPrice: 0,
      finalPrice: 0,
    });
  };

  const [updatePackage, { loading: updateLoading }] = useUpdatePackageAsAdminMutation({
    onCompleted: data => {
      if (data?.updatePackageAsAdmin) onClose();
    },
    refetchQueries:
      selectedPackageId === "new"
        ? [
            {
              query: GetPackagesDocument,
            },
          ]
        : undefined,
  });

  const [lazyGetPackage, { data: fetchData, loading: fetchLoading }] = useGetPackageLazyQuery({
    onCompleted: data => {
      if (data?.getPackage) {
        const { id, point, initialPrice, finalPrice } = data?.getPackage;

        setState(prev => ({
          ...prev,
          id,
          point,
          initialPrice,
          finalPrice,
        }));
      }
    },
  });

  useEffect(() => {
    if (selectedPackageId && selectedPackageId !== "new") {
      lazyGetPackage({
        variables: {
          packageId: selectedPackageId,
        },
      });
    } else if (selectedPackageId === "new") {
      initializeState();
    }
  }, [selectedPackageId, lazyGetPackage]);

  const onStateChange = (event: React.ChangeEvent, key: keyof State) => {
    const newValue = +(event.target as HTMLInputElement).value;
    setState(prev => ({ ...prev, [key]: newValue }));
  };

  const onUpdate = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!state.point) return window.alert("포인트를 입력하세요.");
    if (!state.initialPrice) return window.alert("가격을 입력하세요.");

    const packageInput: PackageInput = {
      id: selectedPackageId === "new" ? null : selectedPackageId,
      point: state.point,
      initialPrice: state.initialPrice,
      finalPrice: state.finalPrice ? state.finalPrice : state.initialPrice,
      active: true,
    };

    await updatePackage({
      variables: {
        packageInput,
      },
    });
  };

  return {
    models: {
      state,
      updateLoading,
      fetchData: fetchData?.getPackage,
      fetchLoading,
    },
    operations: {
      onStateChange,
      onUpdate,
    },
  };
}

export default useUpdatePackageModal;
