import { useState } from "react";

function usePolicyTableItem() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(prev => !prev);
  };

  return {
    models: { isOpen },
    operations: { toggleIsOpen },
  };
}

export default usePolicyTableItem;
