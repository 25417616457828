import { useState } from "react";
import { useGetServicesQuery } from "../../../lib/apollo/graphql/generated";

type Mode = "all" | "activesOnly";

function useServiceContainer() {
  const [mode, setMode] = useState<Mode>("activesOnly");
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);

  const { data, loading } = useGetServicesQuery();

  const onInfoSelect = (id: string | null) => {
    setSelectedServiceId(id);
  };

  const onModeChange = (event: React.ChangeEvent) => {
    setMode((event.target as HTMLOptionElement).value as Mode);
  };

  return {
    models: {
      data: data?.getServices,
      loading,
      selectedServiceId,
      actives: data ? [...data.getServices].filter(service => service?.active) : [],
      mode,
    },
    operations: {
      onInfoSelect,
      onModeChange,
    },
  };
}

export default useServiceContainer;
