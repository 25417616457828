import styled from "styled-components";
import UserTypeToggler from "../components/UserTypeToggler";
import { UserType } from "global";
import BasicButton from "../../common/components/BasicButton";

interface TopConfigsSectionProps {
  type: UserType;
  onModalButtonClick: (event: React.MouseEvent) => void;
}

function TopConfigsSection({ onModalButtonClick, type }: TopConfigsSectionProps) {
  return (
    <Wrapper>
      <UserTypeToggler type={type} />
      <BasicButton text="회원 추가" onClick={onModalButtonClick} height="90%" width="80px" fontSize="12px" />
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: theme.color.white,
  padding: `0 ${theme.spacing.regular}px`,
  justifyContent: "space-between",
}));

export default TopConfigsSection;
