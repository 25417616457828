import styled from "styled-components";
import Modal from "../../common/components/Modal";
import BasicButton from "../../common/components/BasicButton";
import { FormattedExampleQuestion } from "response";
import useDeleteQuestionModal from "./DeleteQuestionModal.hook";

interface DeleteQuestionModalProps {
  isOpen: boolean;
  onClose: () => void;
  question: FormattedExampleQuestion;
  onServerChange: () => void;
}

function DeleteQuestionModal({ isOpen, onClose, question, onServerChange }: DeleteQuestionModalProps) {
  const {
    operations: { deleteQuestion },
  } = useDeleteQuestionModal(question["아이디"], question["질문"], onServerChange, onClose);

  return (
    <Modal title="질문 삭제" onClose={onClose} open={isOpen}>
      <ContentsWrapper>
        <ConfirmMessage>해당 질문을 삭제하시겠습니까?</ConfirmMessage>
        <QuestionPreview>
          <Question>{question["질문"]}</Question>
          <AnswerCount>총 답변 수: {question.__answers.length}</AnswerCount>
        </QuestionPreview>
      </ContentsWrapper>
      <SubmitButtonWrapper>
        <BasicButton text="질문 삭제" backgroundColor="red" hoverBackgroundColor="lightRed" onClick={deleteQuestion} />
      </SubmitButtonWrapper>
    </Modal>
  );
}

const ContentsWrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing.regular}px 0`,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  height: "80%",
}));

const ConfirmMessage = styled.p(({ theme }) => ({
  fontSize: 18,
  color: theme.color.darkGray,
}));

const QuestionPreview = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.lightGray}`,
  borderRadius: 10,
  padding: theme.spacing.regular,
}));

const Question = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
}));

const AnswerCount = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontSize: 13,
  fontWeight: "bold",
  margin: 0,
}));

const SubmitButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  bottom: 40,
  left: 0,
}));

export default DeleteQuestionModal;
