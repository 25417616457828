import styled from "styled-components";
import TwoButtons from "../../common/components/TwoButtons";

interface ConfirmPointChangeProps {
  name: string;
  changeAmount: string;
  mode: string;
  cancelFn: () => void;
  postConfirmFn: (mode: string) => void;
}

function ConfirmPointChange({ name, changeAmount, mode, cancelFn, postConfirmFn }: ConfirmPointChangeProps) {
  return (
    <>
      <ConfirmMessage>
        {name}님{mode === "add" ? `에게 ${changeAmount} 포인트 추가` : `의 포인트를 ${changeAmount} 포인트 차감`}
        하시겠습니까?
      </ConfirmMessage>
      <TwoButtons
        text1={`포인트 ${mode === "add" ? "추가" : "삭제"}`}
        text2="취소"
        buttonWidth="120px"
        fontWeight="bold"
        onClick1={() => postConfirmFn(mode)}
        onClick2={() => cancelFn()}
      />
    </>
  );
}

const ConfirmMessage = styled.p(({ theme }) => ({
  padding: theme.spacing.small,
  fontWeight: "bold",
  color: theme.color.darkGray,
  fontSize: 18,
  disply: "flex",
  justifyContent: "center",
}));

export default ConfirmPointChange;
