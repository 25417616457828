import styled from "styled-components";

interface DataTableHeaderProps {
  keys: string[];
}

function DataTableHeader({ keys }: DataTableHeaderProps) {
  return (
    <thead>
      <UserListHeadRow>
        {keys.map((key, index) => {
          return <th key={`${key}-${index}`}>{key}</th>;
        })}
      </UserListHeadRow>
    </thead>
  );
}

const UserListHeadRow = styled.tr(({ theme }) => ({
  borderBottom: `2px solid ${theme.color.gray}`,

  "&>th": {
    borderRight: `1px solid ${theme.color.gray}`,
    textAlign: "center",
    padding: theme.spacing.small,
    color: theme.color.darkGray,

    "&:last-of-type": {
      borderRight: "none",
    },
  },
}));

export default DataTableHeader;
