import styled from "styled-components";

interface WhiteContentBoxProps {
  children: React.ReactNode;
}

function WhiteContentBox({ children }: WhiteContentBoxProps) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.regular,
  backgroundColor: theme.color.white,
  height: "100%",
  width: "100%",
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

export default WhiteContentBox;
