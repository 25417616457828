import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useFirebaseContext } from "../../../modules/common/providers/FirebaseProvider";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "../utils/router";

function useTopbar() {
  const location = useLocation();
  const user = useFirebaseContext();
  const [pathname, setPathname] = useState("");

  function getPathName() {
    const name = [...location.pathname.split("/")][1].toUpperCase() || "MAIN";

    setPathname(PRIVATE_ROUTES[name as keyof typeof PRIVATE_ROUTES].name!);
  }

  useEffect(() => {
    getPathName();
  }, [location?.pathname]);

  return {
    models: {
      pathname,
    },
  };
}

export default useTopbar;
