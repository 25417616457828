import styled from "styled-components";

type ClickHandler = (event: React.MouseEvent) => void | (() => void);

interface DeleteIconProps {
  onClick: ClickHandler;
  isDisplayed?: boolean;
}

interface WrapperProps {
  $isDisplayed: boolean;
}

function DeleteIcon({ onClick, isDisplayed = true }: DeleteIconProps) {
  return <Wrapper className="bi bi-trash" $isDisplayed={isDisplayed} onClick={onClick} />;
}

const Wrapper = styled.i<WrapperProps>(({ theme, $isDisplayed }) => ({
  color: theme.color.gray,
  transition: "0.1s ease-in",
  cursor: "pointer",
  fontSize: 18,
  position: "absolute",
  bottom: 10,
  right: 10,
  opacity: $isDisplayed ? 1 : 0,

  "&:hover": {
    color: theme.color.error,
  },
}));

export default DeleteIcon;
