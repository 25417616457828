import styled from "styled-components";
import ListModeSelector from "./ListModeSelector";
import BasicButton from "./BasicButton";

interface PageTopSectionProps {
  title: string;
  onModeChange?: (event: React.ChangeEvent) => void;
  onClick?: (event: React.MouseEvent) => void | (() => void);
  buttonText?: string;
}

function PageTopSection({ title, onModeChange, onClick, buttonText = "추가" }: PageTopSectionProps) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {onModeChange && <ListModeSelector onChange={onModeChange} />}
      {onClick && <BasicButton text={buttonText} onClick={onClick} />}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing.small}px 0`,
  display: "flex",
  alignItems: "center",
  gap: 10,
}));

const Title = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 20,
  margin: 0,
  marginRight: "auto",
}));

export default PageTopSection;
