import { ListMode } from "global";
import styled from "styled-components";

interface ListModeSelectorProps {
  onChange: (event: React.ChangeEvent) => void;
  options?: Array<{ text: string; value: ListMode }>;
}

function ListModeSelector({
  onChange,
  options = [
    { text: "활성 데이터만", value: "activesOnly" },
    { text: "전체 보기", value: "all" },
  ],
}: ListModeSelectorProps) {
  return (
    <ModeSelector onChange={onChange}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.text}
        </option>
      ))}
    </ModeSelector>
  );
}

const ModeSelector = styled.select(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 5,
  height: "100%",
  color: theme.color.darkGray,
  "&:focus": {
    outline: "none",
  },
}));

export default ListModeSelector;
