import { useState } from "react";
import { useUpdateExampleQuestionAsAdminMutation } from "../../../lib/apollo/graphql/generated";

function useAddNewQuestionModal(onClose: () => void) {
  const [newQuestion, setNewQuestion] = useState("");
  const [active, setActive] = useState(true);

  const [addNewQuestion, { loading }] = useUpdateExampleQuestionAsAdminMutation();

  const updateNewQuestionValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewQuestion(event.target.value);
  };

  const submitNewQuestion = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!newQuestion) return;

    try {
      await addNewQuestion({
        variables: {
          exampleQuestionInput: {
            active,
            question: newQuestion,
          },
        },
      });

      if (!loading) {
        window.alert("새로운 질문이 추가 되었습니다");
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeQuestionActiveStatus = (event: React.FormEvent | React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    setActive(target.id === "active");
  };

  return {
    models: { newQuestion, active },
    operations: { updateNewQuestionValue, submitNewQuestion, changeQuestionActiveStatus },
  };
}

export default useAddNewQuestionModal;
