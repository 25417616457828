import styled from "styled-components";
import RangeSetter from "./RangeSetter";
import TwoButtons from "../../common/components/TwoButtons";

interface InfoListWrapperProps {
  children: React.ReactNode;
  to?: string;
  from?: string;
  onFromChange?: (event: React.ChangeEvent) => void;
  onToChange?: (event: React.ChangeEvent) => void;
  onApplyRange?: () => void;
  onCancelRange?: () => void;
  buttonText?: string;
}

function InfoListWrapper({
  children,
  onFromChange,
  onToChange,
  onApplyRange,
  onCancelRange,
  buttonText = "적용",
  to,
  from,
}: InfoListWrapperProps) {
  return (
    <Wrapper>
      {onFromChange && onToChange && (
        <Top>
          <RangeSetter onFromChange={onFromChange!} onToChange={onToChange} to={to} from={from} />
          <TwoButtons
            text1={buttonText}
            text2="취소"
            fontSize="14px"
            onClick1={onApplyRange}
            onClick2={onCancelRange}
            hoverBackground2="lightRed"
            hoverBackground1="lightGreen"
            wrapperWidth="fit-content"
          />
        </Top>
      )}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.regular,
  overflow: "auto",
  height: "100%",
  borderRadius: 5,
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const Top = styled.div(() => ({
  display: "flex",
}));

export default InfoListWrapper;
