import styled from "styled-components";
import Modal from "./Modal";
import Loader from "./Loader";
import TwoButtons from "./TwoButtons";

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  deleteLoading: boolean;
  title?: string;
}

function ConfirmDeleteModal({
  isOpen,
  onClose,
  onDelete,
  deleteLoading,
  title = "데이터 삭제",
}: ConfirmDeleteModalProps) {
  return (
    <Modal title={title} open={isOpen} onClose={onClose}>
      <MessagesWrapper>
        <WarningMessage>삭제된 정보는 복구할 수 없습니다.</WarningMessage>
        <WarningMessage>정말 삭제하시겠습니까?</WarningMessage>
      </MessagesWrapper>
      <TwoButtons
        text2={deleteLoading ? "" : "삭제하겠습니다."}
        icon2={deleteLoading && <Loader />}
        text1="아니요."
        background1="green"
        background2="red"
        hoverBackground1="lightGreen"
        hoverBackground2="lightRed"
        buttonWidth="30%"
        fontWeight="bold"
        onClick2={onDelete}
        onClick1={onClose}
      />
    </Modal>
  );
}

const MessagesWrapper = styled.div(() => ({
  height: "70%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const WarningMessage = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 18,
  margin: 0,
  textAlign: "center",

  "&:last-of-type": {
    fontWeight: "bold",
  },
}));

export default ConfirmDeleteModal;
