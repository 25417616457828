import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Used to map each pathname to its appropriate tab name
 * Make sure each route/name corresponds with those defined in "./utils/router.tsx" file
 */
const PATHNAME_TO_TABNAME = {
  "/": "Dashboard",
  "/users/caregivers": "보호사 회원 관리",
  "/detail/caregivers": "보호사 회원 관리",
  "/users/guardians": "보호자 회원 관리",
  "/detail/guardians": "보호자 회원 관리",
  "/users/receivers": "수급자 회원 관리",
  "/detail/receivers": "수급자 회원 관리",
  "/information/manage": "기업정보 관리",
  "/information/terms": "이용약관 관리",
  "/information/privacy": "개인정보처리방침 관리",
  "/information/questions": "예시질문 관리",
  "/announcement/list": "공지사항 관리",
  "/announcement/info": "정보글 관리",
  "/product/service": "부가서비스 관리",
  "/product/package": "포인트상품 관리",
  "/information/report": "신고사유 관리",
};

/**
 * When location.pathname changes, automatically updates the selected tab to the appropriate one
 * Returns selectedTab state, which is then used in Sidebar to differentiate the UI of the selected menu tab
 */
function useSidebar() {
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const { pathname } = useLocation();

  useEffect(() => {
    if (PATHNAME_TO_TABNAME[pathname as keyof typeof PATHNAME_TO_TABNAME]) {
      setSelectedTab(PATHNAME_TO_TABNAME[pathname as keyof typeof PATHNAME_TO_TABNAME]);
      return;
    }

    const keys = Object.keys(PATHNAME_TO_TABNAME);

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];

      if (key === "/" || !pathname.includes(key)) continue;

      setSelectedTab(PATHNAME_TO_TABNAME[keys[i] as keyof typeof PATHNAME_TO_TABNAME]);
      break;
    }
  }, [pathname]);

  return {
    models: { selectedTab },
  };
}

export default useSidebar;
