import styled from "styled-components";

interface BasicUserInfoCardProps {
  avatar?: string;
  name: string;
  nickname: string;
  id: string;
}

function BasicUserInfoCard({ avatar = "/images/default_user_image.png", name, nickname, id }: BasicUserInfoCardProps) {
  return (
    <Wrapper>
      <UserImage src={avatar} alt="User Image" />
      <TextInfoWrapper>
        <TextInfo>
          <p>이름: </p>
          <p>{name}</p>
        </TextInfo>
        <TextInfo>
          <p>아이디: </p>
          <p>{id}</p>
        </TextInfo>
        <TextInfo>
          <p>닉네임: </p>
          <p>{nickname}</p>
        </TextInfo>
      </TextInfoWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  padding: theme.spacing.small,
  borderRadius: 10,
  display: "flex",
  alignItems: "center",
  margin: "30px 0",
}));

const UserImage = styled.img(() => ({
  width: 120,
  height: 120,
  borderRadius: 60,
  objectFit: "cover",
}));

const TextInfoWrapper = styled.div(() => ({
  marginLeft: 20,
  display: "flex",
  flexDirection: "column",
  gap: 10,
}));

const TextInfo = styled.div(({ theme }) => ({
  display: "flex",

  "&>p": {
    margin: 0,
  },

  "&>p:first-of-type": {
    width: 50,
    marginRight: 10,
    textAlign: "right",
    color: theme.color.darkGray,
  },
}));

export default BasicUserInfoCard;
