import { useState } from "react";
import {
  GetReportReasonsDocument,
  ReportReason,
  ReportReasonInput,
  ReportReasonType,
  useUpdateReportReasonAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useAddReportReasonModal(onClose: () => void, reason?: ReportReasonInput) {
  const [state, setState] = useState<ReportReasonInput>(
    reason ?? {
      id: null,
      type: ReportReasonType.CaregiverCommunity,
      reason: "",
      active: true,
    }
  );

  const [updateReportReason, { loading }] = useUpdateReportReasonAsAdminMutation({
    onCompleted: data => {
      if (data?.updateReportReasonAsAdmin) onClose();
    },
    refetchQueries: [
      {
        query: GetReportReasonsDocument,
        variables: {
          active: true,
        },
      },
    ],
  });

  const onStateChange = (key: keyof ReportReason, event: React.FormEvent | React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let value: string | boolean | ReportReasonType | null = null;

    if (key === "type") {
      value = target.id;
    } else if (key === "active") {
      value = !!(target.id === "active");
    } else if (key === "reason") {
      value = target.value;
    }

    setState(prev => ({ ...prev, [key]: value }));
  };

  const onSubmit = async () => {
    console.log(state);

    await updateReportReason({
      variables: {
        reportReasonInput: state,
      },
    });
  };

  return {
    models: { state, loading },
    operations: { onStateChange, updateReportReason, onSubmit },
  };
}

export default useAddReportReasonModal;
