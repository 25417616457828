import { useNavigate } from "react-router-dom";
import { WorkHour } from "response";

function useWorkHistoryItem(workHistory: WorkHour) {
  const navigate = useNavigate();
  const {
    updatedAt,
    createdAt,
    receiver: { name: receiverName, id: receiverId, phone: receiverPhone },
  } = workHistory;

  const moveToReceiverPage = (receiverId: string) => {
    navigate(`/detail/receivers/${receiverId}`);
  };

  return {
    models: {
      createdAt,
      updatedAt,
      receiverName,
      receiverId,
      receiverPhone,
    },
    operations: {
      moveToReceiverPage,
    },
  };
}

export default useWorkHistoryItem;
