import styled from "styled-components";
import usePolicyContainer from "./PolicyContainer.hook";
import { POLICY_TYPE_TEXT } from "../../../utilities/constants";
import BasicButton from "../../common/components/BasicButton";
import Editor from "../../common/components/Editor";
import Loader from "../../common/components/Loader";
import PageWrapper from "../../common/components/PageWrapper";
import PolicyHistoryModal from "../components/PolicyHistoryModal";
import { PolicyType } from "../../../lib/apollo/graphql/generated";

function PolicyContainer() {
  const {
    models: { loading, body, type, title, isViewingPolicyHistory },
    operations: { onBodyChange, onSubmit, togglePolicyHistoryModal },
  } = usePolicyContainer();

  if (loading) return <Loader />;

  return (
    <>
      <PolicyHistoryModal
        type={type as PolicyType}
        isOpen={isViewingPolicyHistory}
        onClose={togglePolicyHistoryModal}
      />
      <PageWrapper isColumnFlex>
        <Wrapper>
          <Header>
            <Title>{title} 관리</Title>
            <BasicButton text="전체보기" height="70%" onClick={togglePolicyHistoryModal} />
          </Header>
          <Editor height="80%" value={body} onChange={onBodyChange} />
          <ButtonWrapper>
            <BasicButton
              width="200px"
              fontWeight="bold"
              text={POLICY_TYPE_TEXT[type as keyof typeof POLICY_TYPE_TEXT] + " 등록"}
              onClick={onSubmit}
            />
          </ButtonWrapper>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.color.white,
  padding: theme.spacing.regular,
  borderRadius: 10,
  height: "100%",
  position: "relative",
}));

const ButtonWrapper = styled.div(() => ({
  position: "absolute",
  bottom: "5%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const Header = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing.small,
}));

const Title = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 18,
  fontWeight: "bold",
  margin: 0,
}));

export default PolicyContainer;
