import { useState } from "react";
import { Gender, ReceiverInput, useUpdateReceiverMutation } from "../../../lib/apollo/graphql/generated";

function useReceiverRegisterForm() {
  const [formValues, setFormValues] = useState<ReceiverInput>({
    phone: "",
    gender: Gender.Male,
    name: "",
    dateOfBirth: "",
    address: null,
    addressDetail: "",
    registrationMediaInput: {
      file: null,
      thumbnailFile: null,
    },
    registrationNumber: "",
  });

  const [createReceiver, { loading }] = useUpdateReceiverMutation();

  // Updates formValue state onChange of inputs used in the register form
  const changeFormValue = (event: React.ChangeEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;

    if (key === "gender") {
      return setFormValues(prev => ({ ...prev, gender: value === "Male" ? Gender.Male : Gender.Female }));
    }

    setFormValues(prev => ({ ...prev, [key]: value }));
  };

  // Changes image files
  const changeImageFileValues = (event: React.ChangeEvent, key: string) => {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    setFormValues(prev => ({ ...prev, [key]: { file, thumbnailFile: file } }));
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!formValues.name || !formValues.dateOfBirth || !formValues.gender)
      return window.alert("필수 정보를 모두 입력하세요.");

    try {
      const { data } = await createReceiver({
        variables: {
          receiverInput: formValues,
        },
      });

      if (!loading) console.log(data?.updateReceiver?.id, ":", data?.updateReceiver?.name);

      window.alert("새로운 회원이 추가 되었습니다!");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    operations: { changeFormValue, changeImageFileValues, onSubmit },
  };
}

export default useReceiverRegisterForm;
