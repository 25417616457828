import { useNavigate } from "react-router-dom";
import { UserType } from "global";

function useUserToggler() {
  const navigate = useNavigate();

  const toggleUserType = (type: UserType) => {
    navigate(`/users/${type}`);
  };

  return {
    models: {},
    operations: { toggleUserType },
  };
}

export default useUserToggler;
