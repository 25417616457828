import styled from "styled-components";
import { KR_EQUIVALENTS } from "../../../utilities/constants";
import { UserType, CommonUserInfo } from "global";
import UserCard from "../../common/components/UserCard";
import { useNavigate } from "react-router-dom";
import NoResultMessage from "./NoResultMessage";

interface RelationshipInfoProps {
  type: UserType;
  relatedUsers: { [key: string]: CommonUserInfo[] | [] };
}

function RelationshipInfo({ type, relatedUsers }: RelationshipInfoProps) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      {Object.keys(relatedUsers).map((userType, index) => {
        if (userType === "deleted" || userType === type) return null;

        const typedUsers = relatedUsers[userType as keyof typeof relatedUsers];

        return (
          <TypeSpecificInfo key={`${userType}-${index}`}>
            <SectionTitle>
              <p>{KR_EQUIVALENTS[userType as keyof typeof KR_EQUIVALENTS]}</p>
              <p>
                <UserCountIcon className="bi bi-person-fill" />
                <span>{typedUsers.length}</span>
              </p>
            </SectionTitle>
            {typedUsers.length ? (
              typedUsers.map((user, index) => (
                <UserCard
                  key={index}
                  data={user}
                  avatar={user?.avatar?.uri}
                  labelWidth="40px"
                  onClick={() => navigate(`/detail/${userType}/${user.id}`)}
                />
              ))
            ) : (
              <NoResultMessage />
            )}
          </TypeSpecificInfo>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.small,
  height: "100%",
  marginTop: 10,
  display: "flex",
  gap: 10,
  overflow: "auto",
}));

const TypeSpecificInfo = styled.div(({ theme }) => ({
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  width: "100%",
  padding: theme.spacing.regular,
  overflow: "auto",
}));

const SectionTitle = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontSize: 18,
  fontWeight: "bold",
  color: theme.color.darkGray,
  margin: 0,
  borderBottom: `1px solid ${theme.color.gray}`,

  "&>p": {
    margin: 0,
  },
}));

const UserCountIcon = styled.i(({ theme }) => ({
  color: theme.color.darkGray,
  marginRight: 5,
  fontSize: 18,
}));

export default RelationshipInfo;
