import styled from "styled-components";

interface ModalModeSelectorProps {
  mode: string;
  changeModeFn: (newMode: "add" | "delete") => void;
}

interface ModeSelectorProps {
  selected: boolean;
}

function ModalModeSelector({ mode, changeModeFn }: ModalModeSelectorProps) {
  return (
    <Wrapper>
      <ModeSelector selected={mode === "add"} onClick={() => changeModeFn("add")}>
        포인트 추가
      </ModeSelector>
      <ModeSelector selected={mode === "delete"} onClick={() => changeModeFn("delete")}>
        포인트 차감
      </ModeSelector>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 10,
  display: "flex",
  justifyContent: "space-around",
  margin: "15px 0",
}));

const ModeSelector = styled.div<ModeSelectorProps>(({ theme, selected }) => ({
  width: "100%",
  height: "100%",
  textAlign: "center",
  padding: theme.spacing.small,
  cursor: "pointer",
  transition: "0.1s ease-in",
  color: theme.color.darkGray,

  "&:first-of-type": {
    borderRadius: "10px 0 0 10px",
    backgroundColor: selected ? "#3ab93adf" : "inherit",
    color: selected ? "white" : "inherit",
    fontWeight: selected ? "bold" : "inherit",
  },

  "&:last-of-type": {
    borderLeft: `1px solid ${theme.color.gray}`,
    borderRadius: "0 10px 10px 0",
    backgroundColor: selected ? "#ff4d4dda" : "inherit",
    color: selected ? "white" : "inherit",
    fontWeight: selected ? "bold" : "inherit",
  },
}));

export default ModalModeSelector;
