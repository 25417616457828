import styled from "styled-components";
import { CaregiverCommunity, Media } from "../../../lib/apollo/graphql/generated";
import ItemWithAuthor from "./ItemWithAuthor";
import usePostItem from "./PostItem.hook";
import ModalWithAuthor from "./ModalWithAuthor";
import { getPreview } from "../../../utilities/format";
import Replies from "./Replies";
import ColoredLabel from "../../common/components/ColoredLabel";

const POST_TYPE = {
  GENERAL: "일상주제 게시물",
  SECRET: "대나무숲 게시물",
};

interface PostItemProps {
  post: CaregiverCommunity;
}

function PostItem({ post }: PostItemProps) {
  const {
    models: { viewFullPost, loading },
    operations: { togglePostModal, onDelete },
  } = usePostItem(post.id);

  return (
    <>
      <ModalWithAuthor
        isOpen={viewFullPost}
        onClose={togglePostModal}
        title="게시물 상세보기"
        authorName={post.author.name}
        authorUri={post.author.avatar?.uri}
        createdAt={post.createdAt}
        body={post.body}
        media={post.media as Media[]}
        width="80%"
        height="95%"
        deleteFn={onDelete}
        deleteLoading={loading}
        deletedAt={post.deletedAt}
      >
        <Replies postId={post.id} />
      </ModalWithAuthor>
      <ItemWithAuthor
        onItemClick={togglePostModal}
        authorName={post.author.name}
        authorUri={post.author.avatar?.uri}
        authorTitle="요양보호사"
        createdAt={post.createdAt}
        body={getPreview(post.body)}
        isDeleted={!!post.deletedAt}
      >
        <AdditionalInformation>
          <ColoredLabel
            text={POST_TYPE[post.type as keyof typeof POST_TYPE]}
            backgroundColor={post.type === "GENERAL" ? "blue" : "gray"}
          />
          <p>{`답글 ${post.replyCount}개`}</p>
        </AdditionalInformation>
      </ItemWithAuthor>
    </>
  );
}

const AdditionalInformation = styled.div(() => ({
  display: "flex",
  gap: 5,
  fontSize: 12,

  "&>p": {
    margin: 0,
  },
}));

export default PostItem;
