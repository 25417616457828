import { PointTransaction } from "../../../lib/apollo/graphql/generated";
import { CommonUserInfo, UserType } from "global";
import { formatDate } from "../../../utilities/format";

const STATUS = {
  SERVICE_DONE: "서비스 완료",
  TIP_ACCEPTED: "팁 수락",
  TIP_CANCELLED: "팁 취소",
  SERVICE_REQUESTED: "서비스 요청",
  SERVICE_ACCEPTED: "서비스 수락",
  SERVICE_REJECTED: "서비스 거절",
  SERVICE_CANCELLED: "서비스 취소",
  SERVICE_REMINDED: "서비스 알림",
  TIP_REQUESTED: "팁 요청",
  TIP_REJECTED: "팁 거절",
};

const POSITIVES = ["SERVICE_DONE", "TIP_ACCEPTED", "SERVICE_ACCEPTED"];
const NEGATIVES = ["TIP_CANCELLED", "SERVICE_REJECTED", "SERVICE_CANCELLED", "TIP_REJECTED"];

type LabelColors = "gray" | "green" | "red" | "blue";

function useTransactionItem(transaction: PointTransaction, userInfo: CommonUserInfo, type: UserType) {
  const { serviceRequest, purchase, type: transactionType, amount } = transaction;

  const counterpartyType = type === "guardians" ? "caregiver" : "guardian";
  const operator = transactionType === "ADDITION" ? "+" : "-";
  const message = transaction.serviceRequest?.message;
  const transactionAmount = Number(amount).toLocaleString() + "P";
  const statusLabelText = purchase ? "충전" : STATUS[serviceRequest?.status as keyof typeof STATUS];
  const counterpartyName = serviceRequest?.[counterpartyType]?.name;
  const userUri = userInfo.회원사진?.uri ?? "/images/default_user_image.png";
  const counterpartyUri = serviceRequest?.[counterpartyType]?.avatar?.uri ?? "/images/default_user_image.png";
  const transactionTypeLabelText = purchase ? "충전" : serviceRequest?.service?.name ?? "팁";

  const transactionDate = purchase?.createdAt
    ? formatDate(purchase.createdAt, true)
    : formatDate(serviceRequest?.createdAt, true);

  const statusLabelBgColor: LabelColors = POSITIVES.includes(serviceRequest?.status!)
    ? "green"
    : NEGATIVES.includes(serviceRequest?.status!)
    ? "red"
    : "gray";

  return {
    models: {
      avatarUri: purchase ? userUri : counterpartyUri,
      statusLabelText,
      statusLabelBgColor,
      operator,
      message,
      transactionAmount,
      transactionDate,
      counterpartyName,
      transactionTypeLabelText,
    },
  };
}

export default useTransactionItem;
