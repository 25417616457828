import { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PRIVATE_ROUTES } from "../utils/router";
import useSidebar from "./Sidebar.hook";

interface NavProps {
  selected: boolean;
}

function Sidebar() {
  const {
    models: { selectedTab },
  } = useSidebar();

  return (
    <Wrapper>
      <LogoContainer key="logo-container">
        <Link to="/">
          <Logo />
        </Link>
      </LogoContainer>
      {Object.keys(PRIVATE_ROUTES).map((key, index) => {
        const route = PRIVATE_ROUTES[key as keyof typeof PRIVATE_ROUTES];

        if (!route.show) return null;

        return (
          <Fragment key={`${key}-${index}`}>
            <MenuWrapper>
              <MenuSectionTitle>{route.name}</MenuSectionTitle>
              {Object.values(route.children!).map(child => (
                <Link key={child.name} to={child.path!}>
                  <Nav selected={selectedTab === child.name} key={child.name}>
                    {child.name}
                  </Nav>
                </Link>
              ))}
            </MenuWrapper>
            <Divider />
          </Fragment>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.aside(({ theme }) => ({
  minWidth: 200,
  width: "10%",
  height: "100vh",
  backgroundColor: theme.color.black,
  color: theme.color.white,
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const LogoContainer = styled.div(({ theme }) => ({
  padding: `${theme.spacing.regular}px ${theme.spacing.regular}px 0`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing.small,
}));

const Logo = styled.img.attrs(() => ({
  src: "/carelogue_logo.png",
}))(() => ({
  width: "90%",
  objectFit: "contain",
}));

const Divider = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.darkGray}`,
  width: "90%",
}));

const MenuWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: theme.spacing.small,
}));

const MenuSectionTitle = styled.p(({ theme }) => ({
  padding: `${theme.spacing.small}px`,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
  color: "white",
  width: "100%",
}));

const Nav = styled.button<NavProps>(({ theme, selected }) => ({
  ...theme.font.body1,
  padding: `3px ${theme.spacing.regular}px`,
  width: "100%",
  textAlign: "left",
  transition: "0.05s ease-in",
  border: "none",
  backgroundColor: selected ? theme.color.navy : theme.color.black,
  color: selected ? "white" : theme.color.gray,
  fontSize: 15,

  "&:hover": {
    backgroundColor: theme.color.navy,
    cursor: "pointer",
  },
}));

export default Sidebar;
