import styled from "styled-components";
import LabeledInfo from "../../common/components/LabeledInfo";
import { CommonUserInfo } from "global";
import { KR_EQUIVALENTS } from "../../../utilities/constants";

interface ProfileProps {
  userInfo: CommonUserInfo;
  isDeleted: boolean;
}

function Profile({ userInfo, isDeleted }: ProfileProps) {
  return (
    <Wrapper>
      <UserImage
        src={userInfo["회원사진"]?.uri ?? "/images/default_user_image.png"}
        alt="User Image"
        onError={event => {
          event.currentTarget.src = "/images/default_user_image.png";
        }}
      />
      <Name>
        {userInfo["이름"]}
        {isDeleted && <span> (탈퇴)</span>}
      </Name>
      <Id>{userInfo["아이디"]}</Id>
      <Info>
        {Object.keys(userInfo as object).map((key, index) => {
          if (["회원 종류", "아이디", "자격증 사진", "회원사진", "등록증 사진"].includes(key)) return null;

          const info = userInfo![key as keyof typeof userInfo];
          const KrInfo = KR_EQUIVALENTS[info as keyof typeof KR_EQUIVALENTS];
          const commonProps = { key: index, label: key, labelWidth: "80px" };

          if (["보호자", "보호사", "수급자"].includes(key)) {
            const count = userInfo[key as keyof typeof userInfo]?.length.toString();
            return <LabeledInfo {...commonProps} info={count ? `${count} 명` : "0 명"} />;
          }

          if (typeof info !== "string") {
            return <LabeledInfo {...commonProps} info={"-"} />;
          }

          return <LabeledInfo {...commonProps} info={KrInfo ?? info} />;
        })}
      </Info>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: "white",
  padding: `${theme.spacing.regular}px ${theme.spacing.small}px`,
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
}));

const Info = styled.div(({ theme }) => ({
  marginTop: 10,
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",

  "&>div": {
    borderBottom: `1px solid ${theme.color.gray}`,
    padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  },
}));

const UserImage = styled.img(({ theme }) => ({
  width: 150,
  height: 150,
  minWidth: 150,
  minHeight: 150,
  borderRadius: 75,
  objectFit: "cover",
}));

const Name = styled.p(({ theme }) => ({
  fontSize: 22,
  color: theme.color.darkGray,
  fontWeight: "bold",
  margin: "20px 0 0 0",

  "&>span": {
    color: theme.color.gray,
  },
}));

const Id = styled.p(({ theme }) => ({
  fontSize: 10,
  color: theme.color.gray,
}));

export default Profile;
