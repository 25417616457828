import { useState } from "react";
import { PolicyType, useGetPoliciesQuery } from "../../../lib/apollo/graphql/generated";
import { ExtractedPolicies } from "response";

function usePolicyHistoryModal(type: PolicyType) {
  const [policies, setPolicies] = useState<ExtractedPolicies>([]);

  const { loading } = useGetPoliciesQuery({
    variables: {
      type,
    },
    onCompleted: data => {
      const extracted = data?.getPolicies;
      setPolicies(extracted);
    },
  });

  return {
    models: { loading, policies },
  };
}

export default usePolicyHistoryModal;
