import styled from "styled-components";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

function RouteLayout() {
  return (
    <Wrapper>
      <Sidebar />
      <RightSideWrapper>
        <Topbar />
        <Outlet />
      </RightSideWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  position: "relative",
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "row",
}));

const RightSideWrapper = styled.div(({ theme }) => ({
  flex: 7,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

export default RouteLayout;
