import styled from "styled-components";
import { Package } from "../../../lib/apollo/graphql/generated";
import ActiveStatusIcon from "../../common/components/ActiveStatusIcon";
import ShortGridItem from "../../common/components/ShortGridItem";
import DeleteIcon from "../../common/components/DeleteIcon";
import usePackageItem from "./PackageItem.hook";
import ConfirmDeleteModal from "../../common/components/ConfirmDeleteModal";

interface PackageItemProps {
  packageData: Package;
  onClick: (id: string) => void;
}

interface PricesProps {
  $isOnSale: boolean;
}

function PackageItem({ packageData, onClick }: PackageItemProps) {
  const {
    models: { isDeleting, isHovered, deleteLoading },
    operations: { closeDeleteModal, openDeleteModal, onHover, onHoverExit, onDelete },
  } = usePackageItem(packageData);

  return (
    <>
      <ConfirmDeleteModal
        title="포인트상품 삭제"
        isOpen={isDeleting}
        onClose={closeDeleteModal}
        onDelete={onDelete}
        deleteLoading={deleteLoading}
      />
      <ShortGridItem onClick={() => onClick(packageData.id)} onMouseOver={onHover} onMouseOut={onHoverExit}>
        <Top>
          <Points>{packageData.point} 케어</Points>
          <ActiveStatusIcon isActive={packageData.active} />
        </Top>
        <Prices $isOnSale={packageData.finalPrice !== packageData.initialPrice}>
          <span>
            가격: <span>{packageData.initialPrice}원</span>
          </span>
          <span>{packageData.finalPrice}원</span>
        </Prices>
        <DeleteIcon onClick={openDeleteModal} isDisplayed={isHovered} />
      </ShortGridItem>
    </>
  );
}

const Top = styled.div(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
  display: "flex",
  alignItems: "center",
  gap: 10,
}));

const Points = styled.p(({ theme }) => ({
  margin: 0,
  fontWeight: "bold",
  fontSize: 20,
  color: theme.color.navy,
}));

const Prices = styled.div<PricesProps>(({ theme, $isOnSale }) => ({
  display: "flex",
  gap: 5,

  "&>span:first-of-type": {
    color: theme.color.darkGray,

    "&>span": {
      color: $isOnSale ? theme.color.gray : theme.color.darkGray,
      textDecoration: $isOnSale ? "line-through" : "none",
      fontStyle: $isOnSale ? "italic" : "normal",
      fontWeight: $isOnSale ? "normal" : "bold",
    },
  },

  "&>span:last-of-type": {
    opacity: $isOnSale ? 1 : 0,
    color: theme.color.darkGray,
    fontWeight: "bold",
  },
}));

export default PackageItem;
