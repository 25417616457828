import styled from "styled-components";
import Modal from "../../common/components/Modal";
import BasicButton from "../../common/components/BasicButton";
import useAddNewAnswerModal from "./AddNewAnswerModal.hook";
import NoAnswerMessage from "./NoAnswerMessage";
import { Answer } from "global";

interface AddNewAnswerModalProps {
  isOpen: boolean;
  onClose: () => void;
  questionId: string;
  currentAnswers?: Answer[];
  onServerChange: () => void;
}

function AddNewAnswerModal({
  isOpen,
  onClose,
  questionId,
  currentAnswers = [{ id: 0, answer: "", active: true, original: false }],
  onServerChange,
}: AddNewAnswerModalProps) {
  const {
    models: { answers },
    operations: { increaseNumberOfAnswers, deleteAnswer, changeAnswer, addNewAnswer },
  } = useAddNewAnswerModal(questionId, currentAnswers, onServerChange);

  return (
    <Modal title="새로운 답변 추가" open={isOpen} onClose={onClose} height="800px">
      <Wrapper>
        {!answers.length ? (
          <NoAnswerMessage />
        ) : (
          answers.map((answer, index) =>
            answer.active ? (
              <NewAnswerBox key={index}>
                <InputNumber>답변 {index + 1}.</InputNumber>
                <InputIConWrapper>
                  <Input
                    key={index}
                    value={answer.answer}
                    onChange={event => changeAnswer(event, answer.id)}
                    disabled={answer.original}
                  />
                  <DeleteAnswerButton className="bi bi-trash3" onClick={() => deleteAnswer(answer.id, answer.answer)} />
                </InputIConWrapper>
              </NewAnswerBox>
            ) : (
              <></>
            )
          )
        )}
        <AddAnswerButton className="bi bi-plus-circle-fill" onClick={increaseNumberOfAnswers} />
      </Wrapper>
      <SubmitButtonWrapper>
        <BasicButton text="답변 추가" onClick={addNewAnswer} />
      </SubmitButtonWrapper>
    </Modal>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  overflowY: "scroll",
  height: "70%",
  display: "flex",
  flexDirection: "column",
  gap: 3,
  alignItems: "center",
  padding: `${theme.spacing.small}px 0`,
}));

const NewAnswerBox = styled.div(() => ({
  width: "100%",
  marginBottom: 20,
}));

const InputIConWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
}));

const InputNumber = styled.p(({ theme }) => ({
  margin: 0,
  marginRight: "auto",
  color: theme.color.darkGray,
  fontSize: 15,
  fontWeight: "bold",
}));

const DeleteAnswerButton = styled.i(({ theme }) => ({
  fontSize: 16,
  cursor: "pointer",
  transition: "0.1s ease-in",
  color: theme.color.darkGray,
  display: "flex",
  alignItems: "center",

  "&:hover": {
    color: theme.color.error,
  },
}));

const Input = styled.input(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 5,
  padding: theme.spacing.small,
  width: "90%",
  marginRight: 10,
  marginBottom: 5,
}));

const AddAnswerButton = styled.i(({ theme }) => ({
  fontSize: 20,
  cursor: "pointer",
  transition: "0.1s ease-in",
  color: theme.color.green2,

  "&:hover": {
    color: theme.color.green3,
  },
}));

const SubmitButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  bottom: 20,
  left: 0,
}));

export default AddNewAnswerModal;
