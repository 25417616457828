import styled from "styled-components";

interface ShortGridItemProps {
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  children?: React.ReactNode;
}

interface WrapperProps {
  $isClickable: boolean;
}

function ShortGridItem({ onClick, onMouseOver, onMouseOut, children }: ShortGridItemProps) {
  return (
    <Wrapper onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} $isClickable={!!onClick}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, $isClickable }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 5,
  width: "100%",
  padding: theme.spacing.regular,
  position: "relative",

  ...($isClickable && {
    cursor: "pointer",
    transition: "0.1s ease-in",

    "&:hover": {
      border: `1px solid ${theme.color.darkGray}`,
    },
  }),
}));

export default ShortGridItem;
