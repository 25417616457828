import { UserType } from "global";
import useUserListContainer from "./UserListContainer.hook";
import UserList from "../components/UserList";
import AddUserModal from "../components/AddUserModal";
import PageWrapper from "../../common/components/PageWrapper";
import TopConfigsSection from "../components/TopConfigsSection";

interface UserListContainerProps {
  type: UserType;
}

function UserListContainer({ type }: UserListContainerProps) {
  const {
    models: { isAddingUser },
    operations: { toggleAddUserModal },
  } = useUserListContainer();

  return (
    <>
      <AddUserModal onClose={toggleAddUserModal} isOpen={isAddingUser} />
      <PageWrapper hasPadding={false} isColumnFlex={true}>
        <TopConfigsSection onModalButtonClick={toggleAddUserModal} type={type} />
        <UserList type={type} />
      </PageWrapper>
    </>
  );
}

export default UserListContainer;
