import PaginationDataTable from "../../common/components/PaginationDataTable";
import FetchMore from "../../common/components/FetchMore";
import Loader from "../../common/components/Loader";
import useReceiverList from "./ReceiverList.hook";
import { SearchFilters } from "global";
import NoResultMessage from "../../userDetail/components/NoResultMessage";

interface ReceiverListProps {
  filters: SearchFilters;
  isFilterApplied: boolean;
}

function ReceiverList({ filters, isFilterApplied }: ReceiverListProps) {
  const {
    refs: { fetchMoreRef },
    models: { receivers, pageInfo, loading, ignores, order, fetchingMore },
    operations: { moveToUserDetailPage },
  } = useReceiverList(filters, isFilterApplied);

  return (
    <>
      {loading ? (
        <Loader />
      ) : !receivers?.length ? (
        <NoResultMessage message="결과 없음" />
      ) : (
        <PaginationDataTable
          data={receivers}
          onDataClick={moveToUserDetailPage}
          ignores={ignores}
          max={15}
          order={order}
        />
      )}
      {fetchingMore && <Loader />}
      {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
    </>
  );
}

export default ReceiverList;
