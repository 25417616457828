import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { SearchFilters } from "global";
import { FETCH_SIZE } from "../../../utilities/constants";
import {
  Gender,
  useGetCaregiversAsAdminQuery,
  useSearchCaregiversAsAdminLazyQuery,
} from "../../../lib/apollo/graphql/generated";

const ignores = ["구분", "아이디", "회원사진", "이메일"];
const order = ["이름", "성별", "닉네임", "생일", "포인트", "생성일", "업데이트"];

function useCaregiverList(filters: SearchFilters, isFilterApplied: boolean) {
  const navigate = useNavigate();

  const [fetchingMore, setFetchingMore] = useState(false);

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  function onViewChange(inView: boolean) {
    const refetchFn = isFilterApplied ? searchFetchMore : fetchMore;
    const pageInfo = isFilterApplied
      ? searchData?.searchCaregiversAsAdmin.pageInfo
      : data?.getCaregiversAsAdmin.pageInfo;

    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      refetchFn({
        variables: { first: FETCH_SIZE, after: pageInfo?.endCursor },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  const moveToUserDetailPage = (userId: string | number) => {
    navigate(`/detail/caregivers/${userId}`);
  };

  const { data, loading, fetchMore } = useGetCaregiversAsAdminQuery({
    variables: {
      first: FETCH_SIZE,
    },
  });

  const [searchCaregivers, { data: searchData, loading: searchLoading, fetchMore: searchFetchMore }] =
    useSearchCaregiversAsAdminLazyQuery();

  useEffect(() => {
    (async () => {
      try {
        await searchCaregivers({
          variables: {
            query: filters.query,
            gender: !filters.gender ? null : filters.gender === "male" ? Gender.Male : Gender.Female,
            first: FETCH_SIZE,
          },
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [isFilterApplied]);

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      caregivers: isFilterApplied ? searchData?.searchCaregiversAsAdmin.edges : data?.getCaregiversAsAdmin.edges,
      pageInfo: isFilterApplied ? searchData?.searchCaregiversAsAdmin.pageInfo : data?.getCaregiversAsAdmin.pageInfo,
      loading: isFilterApplied ? searchLoading : loading,
      ignores,
      order,
      fetchingMore,
    },
    operations: {
      moveToUserDetailPage,
    },
  };
}

export default useCaregiverList;
