import styled from "styled-components";
import Modal from "../../common/components/Modal";
import TwoButtons from "../../common/components/TwoButtons";

interface DeletePostModalProps {
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
}

function DeletePostModal({ open, onClose }: DeletePostModalProps) {
  return (
    <Modal title="게시물 삭제" open={open} onClose={onClose} width="400px" height="400px">
      <Warning>
        <p>한번 삭제된 게시물 정보는 복구할 수 없습니다.</p>
        <p>정말 삭제하시겠습니까?</p>
      </Warning>
      <TwoButtons text1="삭제" text2="취소" />
    </Modal>
  );
}

const Warning = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: 250,
  gap: 10,

  "&>p": {
    fontWeight: "bold",
    fontSize: 18,
    margin: 0,
    color: theme.color.darkGray,
  },
}));

export default DeletePostModal;
