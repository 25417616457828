import React from "react";
import { RouterProvider } from "react-router-dom";
import { useFirebaseContext } from "../../../modules/common/providers/FirebaseProvider";
import { privateRouter, publicRouter } from "../utils/router";

function RouteContainer() {
  const user = useFirebaseContext();

  return <RouterProvider router={!!user ? privateRouter : publicRouter} />;
}

export default RouteContainer;
