import styled from "styled-components";

interface ActiveStatusIconProps {
  isActive: boolean;
}

interface WrapperProps {
  $isActive: boolean;
}

function ActiveStatusIcon({ isActive }: ActiveStatusIconProps) {
  return <Wrapper $isActive={isActive} />;
}

const Wrapper = styled.div<WrapperProps>(({ theme, $isActive }) => ({
  backgroundColor: $isActive ? theme.color.green1 : theme.color.error,
  height: 12,
  width: 12,
  borderRadius: "100%",
  opacity: 0.8,
}));

export default ActiveStatusIcon;
