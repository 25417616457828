import { useState } from "react";
import {
  CaregiverInput,
  Gender,
  ProfileInput,
  useCreateCaregiverAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useCaregiverRegisterForm() {
  const [formValues, setFormValues] = useState<CaregiverInput & ProfileInput>({
    phone: "",
    gender: Gender.Male,
    name: "",
    username: "",
    dateOfBirth: "",
    avatarInput: {
      file: "",
      thumbnailFile: "",
    },
    certificateMediaInput: {
      file: "",
      thumbnailFile: "",
    },
    certificateNumber: "",
  });

  const [createNewCaregiver, { loading }] = useCreateCaregiverAsAdminMutation();

  // Updates formValue state onChange of inputs used in the register form
  const changeFormValue = (event: React.ChangeEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;

    if (key === "gender") {
      return setFormValues(prev => ({ ...prev, gender: value === "Male" ? Gender.Male : Gender.Female }));
    }

    setFormValues(prev => ({ ...prev, [key]: value }));
  };
  // Changes image files
  const changeImageFileValues = (event: React.ChangeEvent, key: string) => {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    setFormValues(prev => ({ ...prev, [key]: { file, thumbnailFile: file } }));
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!formValues.gender || !formValues.phone || !formValues.name || !formValues.username || !formValues.dateOfBirth)
      return window.alert("필수 정보를 모두 입력하세요.");

    const phoneNumberWithoutDashes = formValues.phone?.replace(/-/g, "");
    if (phoneNumberWithoutDashes.length !== 11) return window.alert("유효한 전화번호를 입력하세요.");

    const phoneNumberWith82 = phoneNumberWithoutDashes.replace(/0/, "+82");

    const profileInput = {
      phone: phoneNumberWithoutDashes,
      name: formValues.name,
      username: formValues.username,
      avatarInput: formValues.avatarInput,
      dateOfBirth: formValues.dateOfBirth,
      gender: formValues.gender,
    };

    try {
      const { data } = await createNewCaregiver({
        variables: {
          phoneNumber: phoneNumberWith82,
          profileInput,
          caregiverInput: {
            certificateMediaInput: formValues.certificateMediaInput,
            certificateNumber: formValues.certificateNumber,
          },
        },
      });

      if (!loading) console.log(data?.createCaregiverAsAdmin?.id, ":", data?.createCaregiverAsAdmin?.name);
      window.alert("새로운 회원이 추가 되었습니다!");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    operations: {
      onSubmit,
      changeFormValue,
      changeImageFileValues,
    },
  };
}

export default useCaregiverRegisterForm;
