import styled from "styled-components";
import Modal from "../../common/components/Modal";
import useUpdatePackageModal from "./UpdatePackageModal.hook";
import Loader from "../../common/components/Loader";
import LabeledInput from "../../common/components/LabeledInput";
import BasicButton from "../../common/components/BasicButton";

interface UpdatePackageModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPackageId: string;
}

function UpdatePackageModal({ isOpen, onClose, selectedPackageId }: UpdatePackageModalProps) {
  const {
    models: { state, updateLoading, fetchLoading },
    operations: { onStateChange, onUpdate },
  } = useUpdatePackageModal(selectedPackageId, onClose);

  return (
    <Modal title={`포인트상품 ${selectedPackageId === "new" ? "추가" : "수정"}`} open={isOpen} onClose={onClose}>
      <ContentsWrapper>
        {fetchLoading ? (
          <Loader />
        ) : (
          <>
            <LabeledInput
              label="포인트"
              type="number"
              min="0"
              value={state.point}
              onChange={event => onStateChange(event, "point")}
            />
            <LabeledInput
              label="가격"
              type="number"
              min="0"
              value={state.initialPrice}
              onChange={event => onStateChange(event, "initialPrice")}
            />
            <LabeledInput
              label="할인가"
              type="number"
              min="0"
              value={state.finalPrice}
              onChange={event => onStateChange(event, "finalPrice")}
            />
            <SubmitButtonWrapper>
              <BasicButton
                text={updateLoading ? "" : `${selectedPackageId === "new" ? "추가" : "수정"}`}
                icon={updateLoading && <Loader />}
                onClick={onUpdate}
                height="35px"
              />
            </SubmitButtonWrapper>
          </>
        )}
      </ContentsWrapper>
    </Modal>
  );
}

const ContentsWrapper = styled.div(({ theme }) => ({
  width: "100%",
  height: "85%",
  padding: `${theme.spacing.regular}px 0`,
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

const SubmitButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "auto",
}));

export default UpdatePackageModal;
