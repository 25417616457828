import useDeleteServiceModal from "./DeleteServiceModal.hook";
import { Service } from "../../../lib/apollo/graphql/generated";
import ConfirmDeleteModal from "../../common/components/ConfirmDeleteModal";

interface DeleteServiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  service: Service;
}

function DeleteServiceModal({ isOpen, onClose, service }: DeleteServiceModalProps) {
  const {
    models: { loading },
    operations: { onDelete },
  } = useDeleteServiceModal(service, onClose);

  return (
    <ConfirmDeleteModal
      title="부가서비스 삭제"
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      deleteLoading={loading}
    />
  );
}

export default DeleteServiceModal;
