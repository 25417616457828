import styled from "styled-components";
import DeleteUserModal from "../components/DeleteUserModal";
import useUserDetailContainer from "./UserDetailContainer.hook";
import ChangePointsModal from "../components/ChangePointsModal";
import Configs from "../components/Configs";
import RelationshipInfo from "../components/RelationshipInfo";
import DeletedUserMessage from "../components/DeletedUserMessage";
import PostInfo from "../components/PostInfo";
import PointInfo from "../components/PointInfo";
import Loader from "../../common/components/Loader";
import PageWrapper from "../../common/components/PageWrapper";
import { UserType } from "global";
import Profile from "../components/Profile";
import ReceiverRecord from "../components/ReceiverRecord";
import WorkHistory from "../components/WorkHistory";

interface UserDetailContainerProps {
  type: UserType;
}

function UserDetailContainer({ type }: UserDetailContainerProps) {
  const {
    models: { isAddingPoints, isDeletingUser, infoType, loading, isDeleted, userInfo, relatedUsers },
    operations: { toggleAddPointModal, toggleDeletUserModal, changeInfoType },
  } = useUserDetailContainer(type);

  if (loading || !userInfo) return <Loader />;

  return (
    <>
      {type !== "receivers" && (
        <>
          <DeleteUserModal isOpen={isDeletingUser} onClose={toggleDeletUserModal} userInfo={userInfo!} type={type} />
          <ChangePointsModal isOpen={isAddingPoints} name={userInfo!["이름"] as string} onClose={toggleAddPointModal} />
        </>
      )}
      <PageWrapper>
        <Profile userInfo={userInfo!} isDeleted={isDeleted} />
        <ToggleableInfoWrapper>
          {!isDeleted ? (
            <>
              <Configs
                infoType={infoType}
                onToggle={changeInfoType}
                onButton1Click={toggleAddPointModal}
                onButton2Click={toggleDeletUserModal}
                type={type}
              />
              {infoType === "relationship" && <RelationshipInfo type={type} relatedUsers={relatedUsers!} />}
              {infoType === "point" && <PointInfo type={type} userInfo={userInfo} />}
              {infoType === "post" && <PostInfo />}
              {infoType === "record" && <ReceiverRecord />}
              {infoType === "work" && <WorkHistory />}
            </>
          ) : (
            <DeletedUserMessage deletedAt={userInfo["삭제일"]} />
          )}
        </ToggleableInfoWrapper>
      </PageWrapper>
    </>
  );
}

const ToggleableInfoWrapper = styled.div(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing.regular,
  borderRadius: 10,
  flex: 4,
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

export default UserDetailContainer;
