import styled from "styled-components";

export interface ModalProps {
  children?: React.ReactNode;
  open: boolean;
  title?: string;
  height?: string;
  width?: string;
  onClose: (event: React.MouseEvent) => void;
}

interface BlackBackgroundProps {
  open: boolean;
}

interface WrapperProps {
  height: string;
  width: string;
}

function Modal({
  open,
  children,
  title,
  height = "600px",
  width = "600px",
  onClose,
}: ModalProps) {
  return (
    <BlackBackground open={open}>
      <Wrapper height={height} width={width}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton className="bi bi-x" onClick={onClose} />
        </ModalHeader>
        {children}
      </Wrapper>
    </BlackBackground>
  );
}

const BlackBackground = styled.div<BlackBackgroundProps>(({ open, theme }) => ({
  display: open ? "flex" : "none",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  backgroundColor: "#000000bf",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
}));

const Wrapper = styled.div<WrapperProps>(({ height, width, theme }) => ({
  backgroundColor: "white",
  borderRadius: 10,
  padding: theme.spacing.regular,
  width: width,
  height: height,
  position: "relative",
  overflowY: "auto",
}));

const ModalHeader = styled.div(() => ({
  display: "flex",
  position: "relative",
}));

const ModalTitle = styled.p(({ theme }) => ({
  fontSize: 30,
  fontWeight: "bold",
  color: theme.color.darkGray,
  margin: "20px 0 10px 0",
}));

const CloseButton = styled.i(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 25,
  cursor: "pointer",
  transition: "0.1s ease-in",
  position: "absolute",
  right: 0,
  top: 0,
  "&:hover": {
    color: theme.color.error,
  },
}));

export default Modal;
