import { UserType } from "global";
import {
  useResignCaregiverAsAdminMutation,
  useResignGuardianAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

const TYPE_TO_API = {
  caregivers: { fn: useResignCaregiverAsAdminMutation, variableName: "caregiverId" },
  guardians: { fn: useResignGuardianAsAdminMutation, variableName: "guardianId" },
};

function useDeleteUserModal(type: UserType, onClose: () => void) {
  const [deleteUser, { loading }] = TYPE_TO_API[type as keyof typeof TYPE_TO_API].fn();

  const onUserDelete = async (userId: string) => {
    try {
      await deleteUser({
        variables: { [TYPE_TO_API[type as keyof typeof TYPE_TO_API].variableName]: userId! } as any,
        onCompleted: () => {
          window.alert("회원 정보가 삭제되었습니다.");
          onClose();
          window.location.reload();
        },
      });
    } catch (error) {
      window.alert(error);
    }
  };

  return {
    models: { loading },
    operations: { onUserDelete },
  };
}

export default useDeleteUserModal;
