import styled from "styled-components";
import DataTableBodyRow from "./DataTableBodyRow";
import DataTableHeader from "./DataTableHeader";
import usePaginationDataTable from "./PaginationDataTable.hook";
import { Edges } from "response";

interface PaginationDataTableProps {
  data: Edges;
  onDataClick: (id: string | number) => void;
  ignores?: string[];
  max?: number;
  widths?: string[];
  order?: string[];
}

/**
 * @param data the data that will be displayed on the table
 * @param onDataClick the function that will be invoked every time a single row of data is clicked on
 * @param ignores (optional) an array of strings that explicitly list all the keys that will be not be rendered onto the table
 * @param max (optional) the number of data one full set of paginated batch will contain (used to fill up remaining space with empty rows in case auto height adjustments is unwanted)
 * @param order (optional) an array of strings that explicitly list all the keys that need to be rendered in order; non-included keys will be added on by datasets behind explicility listed keys
 * @param widths (optional) an array of strings that contain the widths of each <col>; mapped through to fill out <colgroup></colgroup> if needed
 */
function PaginationDataTable({
  data,
  ignores = [],
  order = [],
  widths,
  onDataClick,
  max = 12,
}: PaginationDataTableProps) {
  const {
    models: { placeholderArray, dataLength, keysToRenderInOrder, translatedData },
  } = usePaginationDataTable({ data, order, ignores, max });

  return (
    <Wrapper>
      {widths && (
        <colgroup>
          {widths.map((width, index) => (
            <col key={`${width}-${index}`} width={width} />
          ))}
        </colgroup>
      )}
      <DataTableHeader keys={keysToRenderInOrder} />
      <tbody>
        {placeholderArray.map((_, index) => (
          <DataTableBodyRow
            key={index}
            keys={keysToRenderInOrder}
            row={translatedData[index] ? translatedData[index] : { id: "" }}
            onDataClick={translatedData[index] ? onDataClick : undefined}
            index={index}
            dataLength={dataLength}
            pastMax={index + 1 > dataLength}
          />
        ))}
      </tbody>
    </Wrapper>
  );
}

const Wrapper = styled.table(({ theme }) => ({
  boxShadow: `0 0 0 1px ${theme.color.gray}`,
  borderRadius: 10,
  backgroundColor: "white",
  height: "100%",
  maxHeight: "100%",
  overflow: "auto",
  width: "100%",
  padding: 0,
  borderCollapse: "collapse",
  tableLayout: "fixed",
}));

export default PaginationDataTable;
