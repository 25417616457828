import { useState } from "react";
import { useGetCaregiverCommunityRepliesByCommunityAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { FETCH_SIZE } from "../../../utilities/constants";
import { useInView } from "react-intersection-observer";

function useReplies(postId: string) {
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useGetCaregiverCommunityRepliesByCommunityAsAdminQuery({
    variables: {
      communityId: postId,
      first: FETCH_SIZE,
    },
  });

  const onViewChange = (inView: boolean) => {
    const pageInfo = data?.getCaregiverCommunityRepliesByCommunityAsAdmin.pageInfo;

    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: { first: FETCH_SIZE, after: pageInfo?.endCursor },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      fetchingMore,
      loading,
      replies: data?.getCaregiverCommunityRepliesByCommunityAsAdmin.edges,
      pageInfo: data?.getCaregiverCommunityRepliesByCommunityAsAdmin.pageInfo,
    },
    operations: {},
  };
}

export default useReplies;
