import imageCompression from "browser-image-compression";
import { useEffect, useState } from "react";
import { COMPRESSION_OPTION, FETCH_SIZE, MEDIA_LIMIT } from "../../../utilities/constants";
import {
  CaregiverInfoInput,
  GetCaregiverInfosDocument,
  useDeleteCaregiverInfoMediaMutation,
  useGetCaregiverInfoLazyQuery,
  useUpdateCaregiverInfoAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

interface State {
  id: string | null;
  body: string;
  mediaState: File[] | null;
  cities: {
    cityCode: string;
    stateCode: string;
  }[];
}

function useAddInfoModal(selectedInfoId: string, onClose: () => void) {
  const [state, setState] = useState<State>({
    id: null,
    body: "",
    mediaState: null,
    cities: [{ stateCode: "", cityCode: "" }],
  });

  const [getCaregiverInfo, { data, loading }] = useGetCaregiverInfoLazyQuery({
    onCompleted: data => {
      if (data?.getCaregiverInfo) {
        const { id, body, cities } = data?.getCaregiverInfo;

        setState(prev => ({
          ...prev,
          id,
          body,
          cities:
            cities && cities?.length > 0
              ? cities?.map(city => ({
                  cityCode: city?.cityCode!,
                  stateCode: city?.state?.stateCode!,
                }))
              : prev.cities,
        }));
      }
    },
  });

  const [updateCaregiverInfoAsAdmin, { loading: updateLoading }] = useUpdateCaregiverInfoAsAdminMutation({
    onCompleted: data => {
      if (data?.updateCaregiverInfoAsAdmin) {
        onClose();
      }
    },
    refetchQueries:
      selectedInfoId === "new"
        ? [
            {
              query: GetCaregiverInfosDocument,
              variables: {
                first: FETCH_SIZE,
              },
            },
          ]
        : undefined,
  });

  const [deleteCaregiverInfoMedia] = useDeleteCaregiverInfoMediaMutation();

  useEffect(() => {
    if (selectedInfoId !== "new") {
      getCaregiverInfo({
        variables: {
          infoId: selectedInfoId,
        },
      });
    }
  }, [selectedInfoId]);

  const changeCaregiverInfoInput = (inputValue: string) => {
    setState(prev => ({ ...prev, body: inputValue }));
  };

  const changeMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event?.target;

    if (files) {
      const limit = MEDIA_LIMIT - (!!data?.getCaregiverInfo?.media ? data?.getCaregiverInfo?.media?.length : 0);

      const editedMediaState = state?.mediaState ? [...state?.mediaState, ...files]?.slice(0, limit) : [...files];

      setState(prev => ({
        ...prev,
        mediaState: editedMediaState,
      }));
    }
  };

  const onMediaStateRemove = (index: number) => {
    if (!state?.mediaState) return;

    let editedMediaState = [...state?.mediaState];

    editedMediaState = [...editedMediaState?.slice(0, index), ...editedMediaState?.slice(index + 1)];

    setState(prev => ({
      ...prev,
      mediaState: editedMediaState,
    }));
  };

  const onMediaRemove = (mediaId: string) => {
    deleteCaregiverInfoMedia({
      variables: {
        mediaId,
        infoId: selectedInfoId,
      },
    });
  };

  const onCityChange = (
    city: {
      cityCode: string;
      stateCode: string;
    },
    index: number
  ) => {
    let editedCities = [...state?.cities];

    editedCities[index] = city;

    setState(prev => ({
      ...prev,
      cities: editedCities,
    }));
  };

  const onCityAdd = () => {
    setState(prev => ({
      ...prev,
      cities: [...prev?.cities, { stateCode: "", cityCode: "" }],
    }));
  };

  const onCityRemove = (index: number) => {
    let editedCities = [...state?.cities];

    editedCities = [...editedCities?.slice(0, index), ...editedCities?.slice(index + 1)];

    setState(prev => ({
      ...prev,
      cities: editedCities,
    }));
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!state.body) return window.alert("내용을 입력하세요.");

    const { body, cities, mediaState } = state;

    const cityIds: string[] = [...cities]?.filter(city => !!city?.cityCode)?.map(city => city?.cityCode);

    const caregiverInfoInput: CaregiverInfoInput = {
      id: selectedInfoId === "new" ? null : selectedInfoId,
      body,
      cityIds,
    };

    if (mediaState) {
      const mediaInputs = [];

      for await (let item of mediaState) {
        const compressedFile = await imageCompression(item, COMPRESSION_OPTION);
        mediaInputs.push({ file: compressedFile });
      }

      caregiverInfoInput.mediaInputs = mediaInputs;
    }

    await updateCaregiverInfoAsAdmin({
      variables: {
        caregiverInfoInput,
      },
    });
  };

  return {
    models: {
      loading,
      updateLoading,
      state,
      data: data?.getCaregiverInfo,
    },
    operations: {
      changeCaregiverInfoInput,
      changeMedia,
      onMediaStateRemove,
      onMediaRemove,
      onCityChange,
      onCityAdd,
      onCityRemove,
      onSubmit,
    },
  };
}

export default useAddInfoModal;
