import styled from "styled-components";
import PageWrapper from "../../common/components/PageWrapper";
import WhiteContentBox from "../../common/components/WhiteContentBox";
import BasicButton from "../../common/components/BasicButton";
import useReportContainer from "./ReportContainer.hook";
import AddReportReasonModal from "../components/AddReportReasonModal";
import ItemsGridWrapper from "../../common/components/ItemsGridWrapper";
import Loader from "../../common/components/Loader";
import ReportReasonItem from "../components/ReportReaasonItem";

function ReportContainer() {
  const {
    models: { isAdding, loading, data },
    operations: { openAddModal, closeAddModal },
  } = useReportContainer();

  return (
    <>
      <AddReportReasonModal isOpen={isAdding} onClose={closeAddModal} />
      <PageWrapper>
        <WhiteContentBox>
          <Wrapper>
            <Header>
              <Title>신고사유 관리</Title>
              <BasicButton text="사유 추가" height="70%" onClick={openAddModal} />
            </Header>
            {loading ? (
              <Loader />
            ) : (
              <ItemsGridWrapper>
                {data
                  ?.filter(item => item?.active)
                  .map((reportReason, index) => (
                    <ReportReasonItem key={index} reportReason={reportReason!} />
                  ))}
              </ItemsGridWrapper>
            )}
          </Wrapper>
        </WhiteContentBox>
      </PageWrapper>
    </>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.color.white,
  padding: theme.spacing.regular,
  borderRadius: 10,
  height: "100%",
  position: "relative",
}));

const Header = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing.small,
}));

const Title = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 18,
  fontWeight: "bold",
  margin: 0,
}));

export default ReportContainer;
