import styled from "styled-components";

function NullTableData() {
  return <NoDataMessage>N/A</NoDataMessage>;
}

const NoDataMessage = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontSize: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: 0,
}));

export default NullTableData;
