import styled from "styled-components";

interface FieldsetInputProps {
  type: "radio" | "text";
  id: string;
  name: string;
  text: string;
  checked?: boolean;
}

function FieldsetInput({ id, type, name, text, checked }: FieldsetInputProps) {
  return (
    <Wrapper>
      <input id={id} type={type} name={name} defaultChecked={checked} />
      <label htmlFor={id}>{text}</label>
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  gap: 3,
  justifyContent: "center",
  alignItems: "center",
}));

export default FieldsetInput;
