import { useEffect, useState } from "react";
import {
  useUpdateServiceAsAdminMutation,
  useGetServiceLazyQuery,
  ServiceInput,
  GetServicesDocument,
} from "../../../lib/apollo/graphql/generated";

interface State {
  id: string | null;
  summary: string;
  name: string;
  price: number;
}

function useAddServiceModal(selectedServiceId: string, onClose: () => void) {
  const [state, setState] = useState<State>({
    id: null,
    summary: "",
    name: "",
    price: 0,
  });

  const [updateService, { loading: updateLoading }] = useUpdateServiceAsAdminMutation({
    onCompleted: data => {
      if (data?.updateServiceAsAdmin) onClose();
    },
    refetchQueries:
      selectedServiceId === "new"
        ? [
            {
              query: GetServicesDocument,
            },
          ]
        : undefined,
  });

  const [lazyGetService, { data, loading }] = useGetServiceLazyQuery({
    onCompleted: data => {
      if (data?.getService) {
        const { id, summary, name, price } = data?.getService;

        setState(prev => ({
          ...prev,
          id,
          summary,
          name,
          price,
        }));
      }
    },
  });

  const initializeState = () => {
    setState({
      id: null,
      summary: "",
      name: "",
      price: 0,
    });
  };

  useEffect(() => {
    if (selectedServiceId && selectedServiceId !== "new") {
      lazyGetService({
        variables: {
          serviceId: selectedServiceId,
        },
      });
    } else if (selectedServiceId === "new") {
      initializeState();
    }
  }, [selectedServiceId, lazyGetService]);

  const onStateChange = (event: React.ChangeEvent, key: keyof State) => {
    const newValue =
      key === "price" ? +(event.target as HTMLInputElement).value : (event.target as HTMLInputElement).value;
    setState(prev => ({ ...prev, [key]: newValue }));
  };

  const onUpdate = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!state.name) return window.alert("부가서비스명을 입력하세요.");
    if (!state.summary) return window.alert("부가서비스 설명을 입력하세요.");
    if (!state.price) return window.alert("부가서비스 가격을 입력하세요.");

    const serviceInput: ServiceInput = {
      id: selectedServiceId === "new" ? null : selectedServiceId,
      summary: state.summary,
      name: state.name,
      price: state.price as number,
      active: true,
    };

    await updateService({
      variables: {
        serviceInput,
      },
    });
  };

  return {
    models: {
      data: data?.getService,
      loading,
      updateLoading,
      state,
    },
    operations: {
      onUpdate,
      onStateChange,
    },
  };
}

export default useAddServiceModal;
