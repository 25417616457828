import styled from "styled-components";
import { ExampleQuestionAnswer } from "../../../lib/apollo/graphql/generated";
import NoAnswerMessage from "./NoAnswerMessage";

interface AnswerListProps {
  isOpen: boolean;
  answers: ExampleQuestionAnswer[];
}

interface WrapperProps {
  $isOpen: boolean;
}

function AnswerList({ isOpen, answers }: AnswerListProps) {
  return (
    <Wrapper $isOpen={isOpen}>
      {!answers.length ? (
        <NoAnswerMessage />
      ) : (
        answers.map((answer, index: number) =>
          answer.active ? (
            <p key={index}>
              <i className="bi bi-dot" />
              {answer.answer}
            </p>
          ) : (
            <></>
          )
        )
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, $isOpen }) => ({
  maxHeight: $isOpen ? "500px" : 0,
  opacity: $isOpen ? 1 : 0,
  padding: theme.spacing.small,
  display: "flex",
  flexDirection: "column",
  gap: 3,
  transition: "0.1s ease-in-out",
  overflow: "hidden",

  "&>p": {
    margin: 0,
  },
}));

export default AnswerList;
