import styled from "styled-components";
import useAnnouncementCard from "./AnnouncementCard.hook";
import UpdateAnnouncementModal from "./UpdateAnnouncementModal";
import { formatDate } from "../../../utilities/format";

interface AnnouncementCardProps {
  announcement: {
    __typename?: "Announcement";
    id: string;
    title: string;
    summary?: string | null;
    body: string;
    active: boolean;
    onBanner: boolean;
    createdAt: any;
    updatedAt: any;
    thumbnailMedia?: { __typename?: "Media"; id: string; uri: string } | null;
  };
}

function AnnouncementCard({ announcement }: AnnouncementCardProps) {
  const {
    models: { open },
    operations: { toggleModal },
  } = useAnnouncementCard();

  const { id, title, summary, thumbnailMedia, active, onBanner, updatedAt } = announcement;

  return (
    <>
      <UpdateAnnouncementModal selectedAnnouncementId={id} open={open} onClose={toggleModal} />
      <Wrapper onClick={toggleModal}>
        <Thumbnail src={thumbnailMedia?.uri ?? "/images/default_thumbnail_image.png"} />
        <TextWrapper>
          <Date>
            수정: {formatDate(updatedAt, true)}{" "}
            <span>
              {" "}
              배너여부 : {onBanner ? "Y" : "N"} 공개여부 : {active ? "Y" : "N"}
            </span>
          </Date>
          <MainText>
            <Title>{title}</Title>
            <Summary>{summary}</Summary>
          </MainText>
        </TextWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  boxShadow: `0 0 0 1px ${theme.color.gray}`,
  borderRadius: 5,
  height: 350,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing.small,
  position: "relative",
  cursor: "pointer",
  transition: "0.1s ease-in",

  "&:hover": {
    boxShadow: `0 0 0 1px ${theme.color.darkGray}`,
  },
}));

const TextWrapper = styled.div(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 3,
}));

const MainText = styled.div(({ theme }) => ({
  height: "100%",
  padding: `${theme.spacing.regular}px 0`,
}));

const Title = styled.div(({ theme }) => ({
  ...theme.font.body1,
  fontWeight: "bold",
  marginBottom: theme.spacing.normal,
}));

const Summary = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  margin: 0,
  fontSize: 15,
  marginBottom: 10,
}));

const Thumbnail = styled.img(() => ({
  width: "100%",
  height: "50%",
  objectFit: "cover",
}));

const Date = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontSize: 12,
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
}));

export default AnnouncementCard;
