import { useState } from "react";
import { useNavigate } from "react-router-dom";

function useReceiverRecordItem() {
  const [viewFullRecord, setViewFullRecord] = useState(false);
  const navigate = useNavigate();

  const moveToCaregiverDetailPage = (caregiverId: string) => {
    navigate(`/detail/caregivers/${caregiverId}`);
  };

  const toggleRecordModal = () => {
    setViewFullRecord(prev => !prev);
  };

  return {
    models: { viewFullRecord },
    operations: { moveToCaregiverDetailPage, toggleRecordModal },
  };
}

export default useReceiverRecordItem;
