import styled from "styled-components";
import BasicButton from "../../common/components/BasicButton";
import useReceiverRegisterForm from "./ReceiverRegisterForm.hook";
import RegisterFormField from "./RegisterFormFields";

function ReceiverRegisterForm() {
  const {
    operations: { changeFormValue, changeImageFileValues, onSubmit },
  } = useReceiverRegisterForm();

  return (
    <Wrapper onSubmit={onSubmit}>
      <RegisterFormField key="전화번호" label="전화번호" onChange={event => changeFormValue(event, "phone")} />
      <RegisterFormField key="이름" label="이름" onChange={event => changeFormValue(event, "name")} />
      <RegisterFormField key="주소" label="주소" onChange={event => changeFormValue(event, "address")} />
      <RegisterFormField key="상세주소" label="상세주소" onChange={event => changeFormValue(event, "addressDetail")} />
      <RegisterFormField
        key="생년월일"
        label="생년월일"
        type="date"
        size="small"
        onChange={event => changeFormValue(event, "dateOfBirth")}
      />
      <RegisterFormField
        key="성별"
        label="성별"
        type="select"
        size="small"
        options={[
          { value: "MALE", text: "남성" },
          { value: "FEMALE", text: "여성" },
        ]}
        onChange={event => changeFormValue(event, "gender")}
      />
      <RegisterFormField
        key="수급증명서이미지"
        label="수급증명서"
        type="file"
        onChange={event => changeImageFileValues(event, "registrationMediaInput")}
      />
      <RegisterFormField
        key="증명번호"
        label="증명번호"
        onChange={event => changeFormValue(event, "registrationNumber")}
      />
      <SubmitButtonWrapper>
        <BasicButton text="수급자 추가" type="submit" />
      </SubmitButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.form(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  padding: `${theme.spacing.regular}px 0`,
  width: 550,
}));

const SubmitButtonWrapper = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 30,
}));

export default ReceiverRegisterForm;
