import styled from "styled-components";

interface DividerProps {
  marginTop?: string;
  marginBottom?: string;
}

interface LineProps {
  $marginTop: string;
  $marginBottom?: string;
}

function Divider({ marginTop = "0px", marginBottom = "0px" }: DividerProps) {
  return <Line $marginTop={marginTop} $marginBottom={marginBottom} />;
}

const Line = styled.div<LineProps>(({ theme, $marginTop, $marginBottom }) => ({
  border: `1px solid ${theme.color.lightGray}`,
  height: 1,
  width: "100%",
  marginTop: $marginTop,
  marginBottom: $marginBottom,
}));

export default Divider;
