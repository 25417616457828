import styled from "styled-components";
import useCommunityDetailContainer from "./CommunityDetailContainer.hook";
import PageWrapper from "../../common/components/PageWrapper";
import PhotoModal from "../components/PhotoModal";
import DeletePostModal from "../components/DeletePostModal";
import LabeledInfo from "../../common/components/LabeledInfo";
import TwoButtons from "../../common/components/TwoButtons";

function CommunityDetailContainer() {
  const {
    models: { isZoomingPhoto, zoomedImageSrc, isDeletingPost },
    operations: { togglePhotoModal, toggleDeletePostModal },
  } = useCommunityDetailContainer();

  return (
    <>
      <PhotoModal open={isZoomingPhoto} onClose={togglePhotoModal} src={zoomedImageSrc} />
      <DeletePostModal open={isDeletingPost} onClose={toggleDeletePostModal} />
      <PageWrapper isColumnFlex={true}>
        <BasicInfoWrapper>
          <LabeledInfo label="작성자" info="손규성" />
          <LabeledInfo label="유형" info="요양보호사 커뮤니티 게시물" />
          <LabeledInfo label="게시일" info="2023.10.13 금" />
          <LabeledInfo label="좋아요 수" info="2,503개" />
          <LabeledInfo label="댓글 수" info="333개" />
        </BasicInfoWrapper>
        <MainInformationWrapper>
          <ContentWrapper>
            <TextContents>
              안녕하세요! 저는 ChatGPT입니다. ChatGPT는 인공 지능 기반 대화 모델로, 사람들과 대화하고 질문에 답변하는 데
              사용됩니다. 저는 텍스트 기반 대화에 능하며, 모든 종류의 주제에 대해 정보를 제공하고 질문에 답을 할 수
              있습니다. 예를 들어, 여러분이 무엇을 궁금해하거나 도움이 필요한 경우, 저에게 질문을 던질 수 있습니다.
              또한, 여러분이 프로젝트나 작업에 도움을 필요로 할 때, 코드 예제, 조언, 창의적인 아이디어, 또는 글쓰기
              지원을 제공할 수 있습니다. ChatGPT는 머신 러닝과 자연어 처리 기술을 기반으로 하며, 다양한 분야에서 사용될
              수 있습니다. 예를 들어, ChatGPT는 개발자, 학생, 비즈니스 전문가, 또는 궁극적으로 누구에게나 도움을 제공할
              수안녕하세요! 저는 ChatGPT입니다. ChatGPT는 인공 지능 기반 대화 모델로, 사람들과 대화하고 질문에 답변하는
              데 사용됩니다. 저는 텍스트 기반 대화에 능하며, 모든 종류의 주제에 대해 정보를 제공하고 질문에 답을 할 수
              있습니다. 예를 들어, 여러분이 무엇을 궁금해하거나 도움이 필요한 경우, 저에게 질문을 던질 수 있습니다.
              또한, 여러분이 프로젝트나 작업에 도움을 필요로 할 때, 코드 예제, 조언, 창의적인 아이디어, 또는 글쓰기
              지원을 제공할 수 있습니다. ChatGPT는 머신안녕하세요! 저는 ChatGPT입니다. ChatGPT는 인공 지능 기반 대화
              모델로, 사람들과 대화하고 질문에 답변하는 데 사용됩니다. 저는 텍스트 기반 대화에 능하며, 모든 종류의
              주제에 대해 정보를 제공하고 질문에 답을 할 수 있습니다. 예를 들어, 여러분이 무엇을 궁금해하거나 도움이
              필요한 경우, 저에게 질문을 던질 수 있습니다. 또한, 여러분이 프로젝트나 작업에 도움을 필요로 할 때, 코드
              예제, 조언, 창의적인 아이디어, 또는 글쓰기 지원을 제공할 수 있습니다. ChatGPT는 머신안녕하세요! 저는
              ChatGPT입니다. ChatGPT는 인공 지능 기반 대화 모델로, 사람들과 대화하고 질문에 답변하는 데 사용됩니다. 저는
              텍스트 기반 대화에 능하며, 모든 종류의 주제에 대해 정보를 제공하고 질문에 답을 할 수 있습니다. 예를 들어,
              여러분이 무엇을 궁금해하거나 도움이 필요한 경우, 저에게 질문을 던질 수 있습니다. 또한, 여러분이 프로젝트나
              작업에 도움을 필요로 할 때, 코드 예제, 조언, 창의적인 아이디어, 또는 글쓰기 지원을 제공할 수 있습니다.
              ChatGPT는 머신안녕하세요! 저는 ChatGPT입니다. ChatGPT는 인공 지능 기반 대화 모델로, 사람들과 대화하고
              질문에 답변하는 데 사용됩니다. 저는 텍스트 기반 대화에 능하며, 모든 종류의 주제에 대해 정보를 제공하고
              질문에 답을 할 수 있습니다. 예를 들어, 여러분이 무엇을 궁금해하거나 도움이 필요한 경우, 저에게 질문을 던질
              수 있습니다. 또한, 여러분이 프로젝트나 작업에 도움을 필요로 할 때, 코드 예제, 조언, 창의적인 아이디어,
              또는 글쓰기 지원을 제공할 수 있습니다. ChatGPT는 머신안녕하세요! 저는 ChatGPT입니다. ChatGPT는 인공 지능
              기반 대화 모델로, 사람들과 대화하고 질문에 답변하는 데 사용됩니다. 저는 텍스트 기반 대화에 능하며, 모든
              종류의 주제에 대해 정보를 제공하고 질문에 답을 할 수 있습니다. 예를 들어, 여러분이 무엇을 궁금해하거나
              도움이 필요한 경우, 저에게 질문을 던질 수 있습니다. 또한, 여러분이 프로젝트나 작업에 도움을 필요로 할 때,
              코드 예제, 조언, 창의적인 아이디어, 또는 글쓰기 지원을 제공할 수 있습니다. ChatGPT는 머신안녕하세요! 저는
              ChatGPT입니다. ChatGPT는 인공 지능 기반 대화 모델로, 사람들과 대화하고 질문에 답변하는 데 사용됩니다. 저는
              텍스트 기반 대화에 능하며, 모든 종류의 주제에 대해 정보를 제공하고 질문에 답을 할 수 있습니다. 예를 들어,
              여러. ChatGPT는 머신
            </TextContents>
            <MediaContents>
              <Media src="/images/default_user_image.png" onClick={togglePhotoModal} />
              <Media src="/images/test_user_image.jpg" onClick={togglePhotoModal} />
              <Media src="/images/test_user_image.jpg" onClick={togglePhotoModal} />
              <Media src="/images/test_user_image.jpg" onClick={togglePhotoModal} />
              <Media src="/images/test_user_image.jpg" onClick={togglePhotoModal} />
            </MediaContents>
          </ContentWrapper>
          <TwoButtons text1="확인" text2="삭제" onClick2={toggleDeletePostModal} />
        </MainInformationWrapper>
      </PageWrapper>
    </>
  );
}

const BasicInfoWrapper = styled.div(({ theme }) => ({
  flex: 1,
  maxHeight: "15vh",
  display: "flex",
  alignItems: "center",
  gap: 20,
  backgroundColor: "white",
  padding: `${theme.spacing.regular}px ${theme.spacing.regular}px`,
  borderRadius: 10,
}));

const MainInformationWrapper = styled.div(({ theme }) => ({
  flex: 15,
  borderRadius: 10,
  backgroundColor: "white",
  padding: `${theme.spacing.regular}px ${theme.spacing.small}px`,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  alignItems: "center",
  justifyContent: "center",
}));

const ContentWrapper = styled.div(({ theme }) => ({
  height: "100%",
  maxHeight: "65vh",
  maxWidth: "85vw",
  padding: `${theme.spacing.regular}px ${theme.spacing.small}px`,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  justifyContent: "start",
}));

const TextContents = styled.p(({ theme }) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.small}px`,
  color: theme.color.darkGray,
  lineHeight: 1.5,
  margin: 0,
  fontSize: 16,
  overflow: "scroll",
}));

const MediaContents = styled.div(({ theme }) => ({
  display: "flex",
  gap: 10,
  maxWidth: "100%",
  overflow: "scroll",
  alignItems: "center",
  minHeight: 250,
}));

const Media = styled.img(() => ({
  width: 200,
  height: 200,
  objectFit: "cover",
  cursor: "pointer",
  transition: "0.1s ease-in",

  "&:hover": {
    boxShadow: "0 4px 10px rgba(27, 27, 27, 0.081)",
  },
}));

export default CommunityDetailContainer;
