import styled from "styled-components";
import RegisterFormField from "./RegisterFormFields";
import BasicButton from "../../common/components/BasicButton";
import useGuardianRegisterForm from "./GuardianRegisterForm.hook";

function GuardianRegisterForm() {
  const {
    operations: { changeFormValue, changeImageFileValues },
  } = useGuardianRegisterForm();

  return (
    <Wrapper>
      <RegisterFormField key="전화번호" label="전화번호" onChange={event => changeFormValue(event, "phone")} />
      <RegisterFormField key="이름" label="이름" onChange={event => changeFormValue(event, "name")} />
      <RegisterFormField key="닉네임" label="닉네임" onChange={event => changeFormValue(event, "username")} />
      <RegisterFormField
        key="생년월일"
        label="생년월일"
        type="date"
        size="small"
        onChange={event => changeFormValue(event, "dateOfBirth")}
      />
      <RegisterFormField
        key="성별"
        label="성별"
        type="select"
        size="small"
        options={[
          { value: "MALE", text: "남성" },
          { value: "FEMALE", text: "여성" },
        ]}
        onChange={event => changeFormValue(event, "gender")}
      />
      <RegisterFormField
        key="유저이미지"
        label="유저 이미지"
        type="file"
        onChange={event => changeImageFileValues(event, "avatarInput")}
      />
      <SubmitButtonWrapper>
        <BasicButton text="보호자 추가" />
      </SubmitButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.form(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  padding: `${theme.spacing.regular}px 0`,
  width: 550,
}));

const SubmitButtonWrapper = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 30,
}));

export default GuardianRegisterForm;
