import styled from "styled-components";

export const Row = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "row",
}));

export const Col = styled.div<any>(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const Grid = styled.div<any>(() => ({
  display: "grid",
}));
