import usePointInfo from "./PointInfo.hook";
import { CommonUserInfo, UserType } from "global";
import InfoListWrapper from "./InfoListWrapper";
import Loader from "../../common/components/Loader";
import NoResultMessage from "./NoResultMessage";
import FetchMore from "../../common/components/FetchMore";
import TransactionItem from "./TransactionItem";

interface PointInfoProps {
  type: UserType;
  userInfo: CommonUserInfo;
}

function PointInfo({ type, userInfo }: PointInfoProps) {
  const {
    refs: { fetchMoreRef },
    models: { data, loading, pageInfo, fetchingMore, refetching, range },
    operations: { changeFrom, changeTo, applyRange, cancelRange },
  } = usePointInfo(type as "caregivers" | "guardians");

  return (
    <InfoListWrapper
      onFromChange={changeFrom}
      onToChange={changeTo}
      onApplyRange={applyRange}
      onCancelRange={cancelRange}
      to={range.to}
      from={range.from}
    >
      {loading || refetching ? (
        <Loader />
      ) : (
        <>
          {data?.length ? (
            data?.map((transaction, index) => (
              <TransactionItem key={index} transaction={transaction!} userInfo={userInfo} type={type} />
            ))
          ) : (
            <NoResultMessage />
          )}
          {fetchingMore && <Loader />}
          {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
        </>
      )}
    </InfoListWrapper>
  );
}

export default PointInfo;
