import React from "react";
import styled from "styled-components";
import useUpdateAnnouncementModal from "./UpdateAnnouncementModal.hook";
import BasicButton from "../../common/components/BasicButton";
import Editor from "../../common/components/Editor";
import LabeledInput from "../../common/components/LabeledInput";
import Loader from "../../common/components/Loader";
import Modal, { ModalProps } from "../../common/components/Modal";

interface UpdateAnnouncementModalProps extends ModalProps {
  selectedAnnouncementId: string | null;
}

function UpdateAnnouncementModal({ selectedAnnouncementId, open, onClose }: UpdateAnnouncementModalProps) {
  const {
    models: { state, data, loading, updateLoading },
    operations: { onInputChange, onBodyChange, onCheckboxChange, onMediaChange, isSubmitDisabled, onSubmit },
  } = useUpdateAnnouncementModal(selectedAnnouncementId);

  if (loading) return <Loader />;

  const { title, summary, body, thumbnailState, onBanner, active } = state;

  return (
    <Modal
      open={open}
      title={!!selectedAnnouncementId ? "공지사항 수정" : "공지사항 등록"}
      width="60%"
      height="90%"
      onClose={onClose}
    >
      <Wrapper>
        <TopRow>
          {!!selectedAnnouncementId && (
            <Id>
              ID: <span>{selectedAnnouncementId}</span>
            </Id>
          )}
          <Checkboxes>
            <Checkbox>
              <span>배너 표시 여부:</span>
              <input
                type="checkbox"
                checked={onBanner}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onCheckboxChange(event, "onBanner")}
              />
            </Checkbox>
            <Checkbox>
              <span>공개 여부:</span>
              <input
                type="checkbox"
                checked={onBanner}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onCheckboxChange(event, "active")}
              />
            </Checkbox>
          </Checkboxes>
        </TopRow>
        <LabeledInput
          label="제목"
          value={title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "title")}
        />
        <LabeledInput
          label="한 줄 요약"
          value={summary}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, "summary")}
        />
        <LabeledInput
          label="썸네일"
          type="file"
          accept="image/*"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onMediaChange(e)}
          mediaUri={!!thumbnailState ? URL.createObjectURL(thumbnailState) : data?.thumbnailMedia?.uri}
        />
        <Label>본문</Label>
        <Editor value={body} onChange={onBodyChange} />
        <ButtonWrapper>
          <BasicButton text="등록" disabled={loading || updateLoading || isSubmitDisabled()} onClick={onSubmit} />
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  position: "relative",
}));

const TopRow = styled.div(({ theme }) => ({
  display: "flex",
  width: "100%",
}));

const Checkboxes = styled.div(({ theme }) => ({
  display: "flex",
  gap: 20,
  marginLeft: "auto",
}));

const Checkbox = styled.div(({ theme }) => ({
  display: "flex",
  gap: 20,
  alignItems: "center",

  "&>span": {
    fontWeight: "bold",
    color: theme.color.darkGray,
  },
}));

const Id = styled.p(({ theme }) => ({
  display: "flex",
  margin: 0,
  color: theme.color.darkGray,
  fontWeight: "bold",
  gap: 10,

  "&>span": {
    color: theme.color.darkGray,
    fontWeight: "normal",
    fontStyle: "italic",
  },
}));

const Label = styled.div(({ theme }) => ({
  ...theme.font.body1,
  fontWeight: "bold",
  marginTop: theme.spacing.middle,
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  position: "sticky",
  zIndex: 9999,
  bottom: 0,
  width: "100%",
  display: "flex",
  justifyContent: "end",
}));

export default UpdateAnnouncementModal;
