import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetCaregiverInfosQuery } from "../../../lib/apollo/graphql/generated";
import { FETCH_SIZE } from "../../../utilities/constants";

function useInfoContainer() {
  const [selectedInfoId, setSelectedInfoId] = useState<string | null>(null);
  const [fetchingMore, setFetchingMore] = useState(false);

  function onViewChange(inView: boolean) {
    const pageInfo = data?.getCaregiverInfos.pageInfo;

    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: { first: FETCH_SIZE, after: pageInfo?.endCursor },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  const { data, loading, fetchMore } = useGetCaregiverInfosQuery({
    variables: {
      first: FETCH_SIZE,
    },
  });

  function onInfoSelect(id: string | null) {
    setSelectedInfoId(id);
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      data: data?.getCaregiverInfos.edges,
      pageInfo: data?.getCaregiverInfos.pageInfo,
      loading,
      fetchingMore,
      selectedInfoId,
    },
    operations: {
      onInfoSelect,
    },
  };
}

export default useInfoContainer;
