import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { FETCH_SIZE } from "../../../utilities/constants";
import {
  useGetReceiverRecordsAsAdminQuery,
  useGetReceiverRecordsLazyQuery,
} from "../../../lib/apollo/graphql/generated";
import { extractData } from "../../../utilities/format";
import { ExtractedData } from "response";

function useReceiverRecord() {
  const { userId } = useParams();

  const [fetchingMore, setFetchingMore] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [rangeApplied, setRangeApplied] = useState(false);
  const [range, setRange] = useState({ from: "", to: "" });
  const initialLazyFetchRef = useRef(true);

  const { data, loading, fetchMore, refetch } = useGetReceiverRecordsAsAdminQuery({
    variables: {
      receiverId: userId!,
      first: FETCH_SIZE,
    },
  });

  const [lazyFetchRangedRecords, { data: lazyData, refetch: lazyRefetch }] = useGetReceiverRecordsLazyQuery({
    variables: {
      receiverId: userId!,
      first: FETCH_SIZE,
      range,
    },
  });

  const changeFrom = (event: React.ChangeEvent) => {
    const inputDate = (event.target as HTMLInputElement).value;
    setRange(prev => ({ ...prev, from: inputDate }));
  };

  const changeTo = (event: React.ChangeEvent) => {
    const inputDate = (event.target as HTMLInputElement).value;
    setRange(prev => ({ ...prev, to: inputDate }));
  };

  const applyRange = async () => {
    if (!range.to || !range.from) return window.alert("날짜를 설정해주세요.");

    try {
      setRefetching(true);
      setRangeApplied(true);

      if (initialLazyFetchRef.current) {
        await lazyFetchRangedRecords({
          variables: {
            receiverId: userId!,
            first: FETCH_SIZE,
            range,
          },
        });

        initialLazyFetchRef.current = false;
      } else {
        await lazyRefetch({
          receiverId: userId!,
          first: FETCH_SIZE,
          range,
        });
      }
    } finally {
      setRefetching(false);
    }
  };

  const cancelRange = async () => {
    setRange({ from: "", to: "" });

    try {
      setRefetching(true);
      setRangeApplied(false);

      await refetch({
        receiverId: userId,
        first: FETCH_SIZE,
      });
    } finally {
      setRefetching(false);
    }
  };

  const onViewChange = (inView: boolean) => {
    const extracted: unknown = extractData(data);
    const pageInfo = (extracted as ExtractedData).pageInfo;

    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: { first: FETCH_SIZE, after: pageInfo?.endCursor },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      userId,
      range,
      refetching,
      fetchingMore,
      rangeApplied,
      rangedRecords: lazyData?.getReceiverRecords.edges,
      rangedPageInfo: lazyData?.getReceiverRecords.pageInfo,
      records: data ? (extractData(data) as ExtractedData).edges : [],
      pageInfo: data ? (extractData(data) as ExtractedData).pageInfo : [],
      loading,
    },
    operations: {
      changeFrom,
      changeTo,
      applyRange,
      cancelRange,
    },
  };
}

export default useReceiverRecord;
