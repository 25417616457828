import styled from "styled-components";
import BasicButton from "../../common/components/BasicButton";

interface ChangePointsAmountInputAreaProps {
  name: string;
  mode: string;
  changeAmount: string;
  changeFn: (event: React.ChangeEvent) => void;
  preConfirmFn: () => void;
}

function ChangePointAmountInputArea({
  name,
  mode,
  changeAmount,
  changeFn,
  preConfirmFn,
}: ChangePointsAmountInputAreaProps) {
  return (
    <>
      <GuideMessage>
        {name}님{mode === "add" ? "에게 포인트 추가" : "의 포인트 차감"}
      </GuideMessage>
      <PointInputArea>
        <PointInput type="number" min="0" value={changeAmount} onChange={changeFn} />
        <p>포인트</p>
      </PointInputArea>
      <BasicButton
        text={`포인트 ${mode === "add" ? "추가" : "삭제"}`}
        backgroundColor={mode === "add" ? "green" : "red"}
        changeOnHover={false}
        width="100px"
        height="40px"
        fontWeight="bold"
        onClick={() => preConfirmFn()}
      />
    </>
  );
}

const GuideMessage = styled.p(({ theme }) => ({
  width: "100%",
  padding: theme.spacing.small,
  fontWeight: "bold",
  color: theme.color.darkGray,
  fontSize: 18,
}));

const PointInputArea = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: 5,
  width: "100%",

  "&>p": {
    fontWeight: "bold",
    fontSize: 20,
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
}));

const PointInput = styled.input(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 5,
  width: "80%",
  padding: theme.spacing.small,
  fontWeight: "bold",
  fontSize: 20,
}));

export default ChangePointAmountInputArea;
