import { GeneralDataObject } from "global";
import { formatMultipleData } from "../../../utilities/format";

interface usePaginationDataTableParams<T extends GeneralDataObject> {
  data: T[];
  ignores: string[];
  order: string[];
  max?: number;
}

function usePaginationDataTable<T extends GeneralDataObject>({
  data,
  ignores,
  order,
  max,
}: usePaginationDataTableParams<T>) {
  const placeholderArray = Array(Math.max(max ?? 0, data.length)).fill("");
  const dataLength = data.length;
  const keysToRenderInOrder = [...order];

  const translatedData = formatMultipleData<(typeof data)[0]>(data);

  Object.keys(translatedData[0]).forEach((key, index) => {
    if (!keysToRenderInOrder.includes(key) && !ignores.includes(key)) keysToRenderInOrder.push(key);
  });

  return {
    models: { placeholderArray, dataLength, keysToRenderInOrder, translatedData },
  };
}

export default usePaginationDataTable;
