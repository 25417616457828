import {
  GetServicesDocument,
  Service,
  ServiceInput,
  useUpdateServiceAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useDeleteServiceModal(service: Service, onClose: () => void) {
  const [deleteService, { loading }] = useUpdateServiceAsAdminMutation({
    onCompleted: data => {
      if (data?.updateServiceAsAdmin) {
        onClose();
      }
    },
    refetchQueries: [
      {
        query: GetServicesDocument,
      },
    ],
  });

  const onDelete = async () => {
    const serviceInput: ServiceInput = {
      id: service.id,
      summary: service.summary,
      name: service.name,
      price: 0,
      active: false,
    };

    await deleteService({
      variables: { serviceInput },
    });
  };

  return {
    models: {
      loading,
    },
    operations: {
      onDelete,
    },
  };
}

export default useDeleteServiceModal;
