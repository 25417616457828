import styled from "styled-components";
import BasicButton from "./BasicButton";
import { wrap } from "module";

interface TwoButtonsProps {
  text1?: string;
  text2?: string;
  icon1?: React.ReactNode;
  icon2?: React.ReactNode;
  onClick1?: (event: React.MouseEvent) => void;
  onClick2?: (event: React.MouseEvent) => void;
  justifyContent?: string;
  background1?: string;
  background2?: string;
  changesOnHover?: boolean;
  hoverBackground1?: string;
  hoverBackground2?: string;
  fontWeight?: string;
  color1?: string;
  color2?: string;
  hoverColor1?: string;
  hoverColor2?: string;
  buttonWidth?: string;
  buttonHeight?: string;
  button1Disabled?: boolean;
  button2Disabled?: boolean;
  fontSize?: string;
  width?: string;
  wrapperWidth?: string;
}

interface WrapperProps {
  $justifyContent: string;
  $wrapperWidth: string;
}

function TwoButtons({
  text1,
  text2,
  icon1,
  icon2,
  onClick1,
  onClick2,
  justifyContent = "center",
  background1 = "green",
  background2 = "red",
  hoverBackground1 = "lightgreen",
  hoverBackground2 = "lightred",
  changesOnHover = true,
  fontWeight,
  color1,
  color2,
  hoverColor1,
  hoverColor2,
  buttonWidth,
  buttonHeight,
  button1Disabled,
  button2Disabled,
  fontSize = "14px",
  wrapperWidth = "100%",
}: TwoButtonsProps) {
  return (
    <Wrapper $justifyContent={justifyContent} $wrapperWidth={wrapperWidth}>
      <BasicButton
        text={text1}
        icon={icon1}
        backgroundColor={background1}
        hoverBackgroundColor={hoverBackground1}
        onClick={onClick1}
        changeOnHover={changesOnHover}
        color={color1}
        hoverColor={hoverColor1}
        width={buttonWidth}
        height={buttonHeight}
        fontWeight={fontWeight}
        disabled={button1Disabled}
        fontSize={fontSize}
      />
      <BasicButton
        text={text2}
        icon={icon2}
        backgroundColor={background2}
        hoverBackgroundColor={hoverBackground2}
        onClick={onClick2}
        changeOnHover={changesOnHover}
        color={color2}
        hoverColor={hoverColor2}
        width={buttonWidth}
        height={buttonHeight}
        fontWeight={fontWeight}
        disabled={button2Disabled}
        fontSize={fontSize}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ $justifyContent, $wrapperWidth }) => ({
  display: "flex",
  width: $wrapperWidth,
  justifyContent: $justifyContent,
  gap: 10,
  alignItems: "center",
}));

export default TwoButtons;
