import styled from "styled-components";
import { KR_EQUIVALENTS } from "../../../utilities/constants";

interface LabeledInfoProps {
  label: string;
  info?: string | number;
  labelWidth?: string;
  isLabelEndAlign?: boolean;
  fontSize?: string;
}

interface WrapperProps {
  $labelWidth: string;
  $isLabelEndAlign: boolean;
  $fontSize: string;
}

function LabeledInfo({ label, fontSize = "14px", info, labelWidth = "", isLabelEndAlign = false }: LabeledInfoProps) {
  const koreanLabel = KR_EQUIVALENTS[label as keyof typeof KR_EQUIVALENTS];
  const koreanInfo = KR_EQUIVALENTS[info as keyof typeof KR_EQUIVALENTS];

  return (
    <Wrapper $fontSize={fontSize} $labelWidth={labelWidth} $isLabelEndAlign={isLabelEndAlign}>
      <p>{koreanLabel ?? label}:</p>
      <p>{koreanInfo ?? info?.toString().length ? info : "-"}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, $fontSize, $labelWidth, $isLabelEndAlign }) => ({
  display: "flex",
  gap: 3,

  "&>p": {
    margin: 0,
    color: theme.color.darkGray,
    fontSize: $fontSize,
  },

  "&>p:first-of-type": {
    fontWeight: "bold",
    width: $labelWidth ? $labelWidth : "",
    textAlign: $isLabelEndAlign ? "end" : "start",
  },

  "&>p:last-of-type": {
    flex: 1,
  },
}));

export default LabeledInfo;
