import { useState } from "react";
import {
  GetReportReasonsDocument,
  ReportReason,
  useUpdateReportReasonAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useReportReasonItem(reportReasonData: ReportReason) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [deleteReportReason, { loading }] = useUpdateReportReasonAsAdminMutation({
    onCompleted: data => {
      if (data?.updateReportReasonAsAdmin) closeDeleteModal();
    },
    refetchQueries: [
      {
        query: GetReportReasonsDocument,
      },
    ],
  });

  const openDeleteModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDeleting(true);
  };

  const closeDeleteModal = () => {
    setIsDeleting(false);
  };

  const onHover = () => {
    setIsHovered(true);
  };

  const onHoverExit = () => {
    setIsHovered(false);
  };

  const onDelete = async () => {
    const reportReasonInput = {
      id: reportReasonData.id,
      reason: reportReasonData.reason,
      type: reportReasonData.type,
      active: false,
    };

    await deleteReportReason({
      variables: { reportReasonInput },
    });
  };

  return {
    models: { isDeleting, isHovered, loading },
    operations: { onHover, onHoverExit, closeDeleteModal, openDeleteModal, onDelete },
  };
}

export default useReportReasonItem;
