import usePostInfo from "./PostInfo.hook";
import InfoListWrapper from "./InfoListWrapper";
import Loader from "../../common/components/Loader";
import PostItem from "./PostItem";
import NoResultMessage from "./NoResultMessage";
import FetchMore from "../../common/components/FetchMore";

function PostInfo() {
  const {
    ref: { fetchMoreRef },
    models: { loading, data, pageInfo, fetchingMore },
  } = usePostInfo();

  return (
    <InfoListWrapper>
      {loading ? (
        <Loader />
      ) : (
        <>
          {data?.length ? data?.map((post, index) => <PostItem post={post!} key={index} />) : <NoResultMessage />}
          {fetchingMore && <Loader />}
          {pageInfo?.hasNextPage && <FetchMore fetchMoreRef={fetchMoreRef} />}
        </>
      )}
    </InfoListWrapper>
  );
}

export default PostInfo;
