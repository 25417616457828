import useReceiverRecord from "./ReceiverRecord.hook";
import Loader from "../../common/components/Loader";
import ReceiverRecordItem from "./ReceiverRecordItem";
import FetchMore from "../../common/components/FetchMore";
import NoResultMessage from "./NoResultMessage";
import { ReceiverRecord as RecordType } from "../../../lib/apollo/graphql/generated";
import InfoListWrapper from "./InfoListWrapper";

function ReceiverRecord() {
  const {
    refs: { fetchMoreRef },
    models: {
      records,
      loading,
      fetchingMore,
      rangeApplied,
      rangedRecords,
      rangedPageInfo,
      refetching,
      pageInfo,
      range,
    },
    operations: { changeFrom, changeTo, applyRange, cancelRange },
  } = useReceiverRecord();

  return (
    <InfoListWrapper
      onToChange={changeTo}
      onFromChange={changeFrom}
      onApplyRange={applyRange}
      onCancelRange={cancelRange}
      to={range.to}
      from={range.from}
    >
      {loading || refetching ? (
        <Loader />
      ) : (
        <>
          {rangeApplied && rangedRecords?.length ? (
            rangedRecords.map(record => <ReceiverRecordItem key={record?.id} record={record!} />)
          ) : !rangeApplied && records?.length ? (
            records.map((record: RecordType) => <ReceiverRecordItem key={record?.id} record={record!} />)
          ) : (
            <NoResultMessage />
          )}
          {fetchingMore && <Loader />}
          {(rangedPageInfo?.hasNextPage || pageInfo?.hasNextPage) && <FetchMore fetchMoreRef={fetchMoreRef} />}
        </>
      )}
    </InfoListWrapper>
  );
}

export default ReceiverRecord;
