import { useState } from "react";
import { Answer } from "global";

function useQuestionBox(answers: Answer[]) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddingAnswer, setIsAddingAnswer] = useState(false);
  const [isDeletingQuestion, setIsDeletingQuestion] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const currentAnswers = answers.length ? answers.map(answer => ({ ...answer, original: true })) : undefined;

  const toggleQuestionBox = () => {
    setIsOpen(prev => !prev);
  };

  const openAddNewAnswerModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsAddingAnswer(true);
  };

  const closeAddNewAnswerModal = () => {
    setIsAddingAnswer(false);

    if (isDirty) {
      window.location.reload();
      setIsDirty(false);
    }
  };

  const closeDeleteQuestionModal = () => {
    setIsDeletingQuestion(false);
  };

  const openDeleteQuestionModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDeletingQuestion(true);
  };

  const deleteQuestion = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const updateIsDirty = () => {
    setIsDirty(prev => !prev);
  };

  return {
    models: { isOpen, isAddingAnswer, currentAnswers, isDeletingQuestion },
    operations: {
      toggleQuestionBox,
      deleteQuestion,
      openAddNewAnswerModal,
      closeAddNewAnswerModal,
      closeDeleteQuestionModal,
      openDeleteQuestionModal,
      updateIsDirty,
    },
  };
}

export default useQuestionBox;
