import styled from "styled-components";

interface NoResultMessageProps {
  message?: string;
  fontSize?: string;
  backgroundColor?: string;
}

interface WrapperProps {
  $fontSize: string;
  $backgroundColor: string;
}

function NoResultMessage({ message = "N/A", fontSize = "20px", backgroundColor = "inherit" }: NoResultMessageProps) {
  return (
    <Wrapper $backgroundColor={backgroundColor} $fontSize={fontSize}>
      {message}
    </Wrapper>
  );
}

const Wrapper = styled.p<WrapperProps>(({ theme, $fontSize, $backgroundColor }) => ({
  width: "100%",
  height: "80%",
  fontSize: $fontSize,
  fontWeight: "bold",
  color: theme.color.gray,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: $backgroundColor,
}));

export default NoResultMessage;
