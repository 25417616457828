import styled from "styled-components";
import { ReportReason, ReportReasonType } from "../../../lib/apollo/graphql/generated";
import ShortGridItem from "../../common/components/ShortGridItem";
import ActiveStatusIcon from "../../common/components/ActiveStatusIcon";
import useReportReasonItem from "./ReportReasonItem.hook";
import DeleteIcon from "../../common/components/DeleteIcon";
import ConfirmDeleteModal from "../../common/components/ConfirmDeleteModal";

interface ReportReasonItemProps {
  reportReason: ReportReason;
}

const REASON_TYPE: {
  [key in ReportReasonType]: string;
} = {
  CAREGIVER_COMMUNITY: "게시물 신고",
  CAREGIVER_COMMUNITY_REPLY: "게시물 댓글 신고",
  CAREGIVER_INFO_REPLY: "정보글 댓글 신고",
  CAREGIVER: "사용자 차단",
};

function ReportReasonItem({ reportReason }: ReportReasonItemProps) {
  const {
    models: { isDeleting, isHovered, loading },
    operations: { onHover, onHoverExit, closeDeleteModal, openDeleteModal, onDelete },
  } = useReportReasonItem(reportReason);

  return (
    <>
      <ConfirmDeleteModal
        title="신고사유 삭제"
        isOpen={isDeleting}
        onClose={closeDeleteModal}
        onDelete={onDelete}
        deleteLoading={loading}
      />
      <ShortGridItem onMouseOver={onHover} onMouseOut={onHoverExit}>
        <Top>
          <Reason>{reportReason.reason}</Reason>
          <ActiveStatusIcon isActive={reportReason.active} />
        </Top>
        <div>
          <span>{REASON_TYPE[reportReason.type as keyof typeof REASON_TYPE]}</span>
        </div>
        <DeleteIcon onClick={openDeleteModal} isDisplayed={isHovered} />
      </ShortGridItem>
    </>
  );
}

const Top = styled.div(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
  display: "flex",
  alignItems: "center",
  gap: 10,
}));

const Reason = styled.div(({ theme }) => ({
  margin: 0,
  fontWeight: "bold",
  fontSize: 20,
  color: theme.color.navy,
}));

export default ReportReasonItem;
