import React, { memo } from "react";
import styled, { ThemedComponentProps } from "styled-components";
import { Col } from "../styles/commonStyles";

interface LabeledInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  mediaUri?: string;
}

function LabeledInput({ label, mediaUri, ...rest }: LabeledInputProps) {
  return (
    <Wrapper>
      {!!label && <Label>{label}</Label>}
      <Input {...rest} />
      {!!mediaUri && <Image src={mediaUri} />}
    </Wrapper>
  );
}

const Wrapper = styled(Col)<any>(() => ({
  alignItems: "flex-start",
  width: "100%",
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body1,
  fontWeight: "bold",
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  borderRadius: 4,
  width: "100%",
  padding: `${theme.spacing.small}px ${theme.spacing.normal}px`,
  ...theme.font.body1,
  border: `1px solid ${theme.color.gray}`,

  "&:focus": {
    borderColor: theme.color.black4,
  },
}));

const Image = styled.img<any>(() => ({
  width: 300,
  height: 300,
  objectFit: "contain",
}));

export default memo(LabeledInput);
