import { useState } from "react";
import {
  useGetManagementQuery,
  useUpdateManagementAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useManagementContainer() {
  const [state, setState] = useState({
    companyName: "",
    address: "",
    copyright: "",
    email: "",
    ceoName: "",
    registrationNumber: "",
    escroRegistration: "",
    phone: "",
    kakaoAddress: "",
    minimumTip: 0,
    invitationPoint: 0,
    shopAddress: "",
  });

  const { loading } = useGetManagementQuery({
    onCompleted: (data) => {
      if (data?.getManagement) {
        const {
          address,
          companyName,
          copyright,
          email,
          ceoName,
          registrationNumber,
          escroRegistration,
          phone,
          kakaoAddress,
          minimumTip,
          invitationPoint,
          shopAddress,
        } = data?.getManagement;

        setState({
          address: address || "",
          companyName: companyName || "",
          copyright: copyright || "",
          email: email || "",
          ceoName: ceoName || "",
          registrationNumber: registrationNumber || "",
          escroRegistration: escroRegistration || "",
          phone: phone || "",
          kakaoAddress: kakaoAddress || "",
          minimumTip,
          invitationPoint,
          shopAddress: shopAddress || "",
        });
      }
    },
  });

  const [updateManagementAsAdmin, { loading: updateLoading }] =
    useUpdateManagementAsAdminMutation();

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof state
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    const {
      address,
      companyName,
      copyright,
      email,
      ceoName,
      registrationNumber,
      escroRegistration,
      phone,
      kakaoAddress,
      minimumTip,
      invitationPoint,
      shopAddress,
    } = state;

    updateManagementAsAdmin({
      variables: {
        managementInput: {
          address,
          companyName,
          copyright,
          email,
          ceoName,
          registrationNumber,
          escroRegistration,
          phone,
          kakaoAddress,
          minimumTip: Number(minimumTip),
          invitationPoint: Number(invitationPoint),
          shopAddress,
        },
      },
    });
  }

  return {
    models: {
      loading,
      state,
      updateLoading,
    },
    operations: {
      onInputChange,
      onSubmit,
    },
  };
}

export default useManagementContainer;
