import { UserType } from "global";
import CaregiverList from "../components/CaregiverList";
import ReceiverList from "./ReceiverList";
import GuardianList from "./GuardianList";
import styled from "styled-components";
import useUserList from "./UserList.hook";
import TwoButtons from "../../common/components/TwoButtons";
import SearchFilterModal from "./SearchFilterModal";

interface UserListProps {
  type: UserType;
}

function UserList({ type }: UserListProps) {
  const {
    models: { isFilterApplied, isFilterModalOpen, filters },
    operations: { changeKeyword, toggleFilterModal, changeFilters, applyFilters, cancelFilters },
  } = useUserList();

  return (
    <Wrapper>
      <SearchFilterModal
        isOpen={isFilterModalOpen}
        onClose={toggleFilterModal}
        onFilterChange={changeFilters}
        filters={filters}
      />
      <Top>
        <FilterIcon className="bi bi-filter" onClick={toggleFilterModal} />
        <SearchWrapper>
          <SearchInput
            placeholder="사용자 이름, 아이디, 혹은 전화번호로 검색"
            value={filters.query}
            onChange={changeKeyword}
          />
          <TwoButtons
            text1="검색"
            text2="필터 제거"
            wrapperWidth="fit-content"
            fontWeight="bold"
            background1="navy"
            hoverBackground1="lightNavy"
            hoverBackground2="lightRed"
            onClick1={applyFilters}
            onClick2={cancelFilters}
          />
        </SearchWrapper>
      </Top>
      {type === "caregivers" && <CaregiverList filters={filters} isFilterApplied={isFilterApplied} />}
      {type === "receivers" && <ReceiverList filters={filters} isFilterApplied={isFilterApplied} />}
      {type === "guardians" && <GuardianList filters={filters} isFilterApplied={isFilterApplied} />}
    </Wrapper>
  );
}
const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.color.lightGray,
  padding: `${theme.spacing.regular}px`,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  flex: 1,
}));

const Top = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 10,
}));

const FilterIcon = styled.i(({ theme }) => ({
  fontSize: 25,
  color: theme.color.black,
  marginLeft: 5,
  cursor: "pointer",
  transition: "0.1s ease-in",

  "&:hover": {
    color: theme.color.gray,
  },
}));

const SearchWrapper = styled.form(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: "10px",
}));

const SearchInput = styled.input(({ theme }) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.small}px`,
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 10,
  width: "70%",
  fontSize: 14,
}));

export default UserList;
