import styled from "styled-components";
import useUserToggler from "./UserTypeToggler.hook";

interface UserTypeTogglerProps {
  type: string;
}

interface UserTypeConfigButtonProps {
  selected: boolean;
}

function UserTypeToggler({ type }: UserTypeTogglerProps) {
  const {
    operations: { toggleUserType },
  } = useUserToggler();

  return (
    <Wrapper>
      <UserTypeConfigButton selected={type === "caregivers"} onClick={() => toggleUserType("caregivers")}>
        보호사
      </UserTypeConfigButton>
      <UserTypeConfigButton selected={type === "guardians"} onClick={() => toggleUserType("guardians")}>
        보호자
      </UserTypeConfigButton>
      <UserTypeConfigButton selected={type === "receivers"} onClick={() => toggleUserType("receivers")}>
        수급자
      </UserTypeConfigButton>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: 5,
  backgroundColor: "white",
}));

const UserTypeConfigButton = styled.button<UserTypeConfigButtonProps>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.color.lightGray : "white",
  color: selected ? theme.color.navy : theme.color.darkGray,
  fontWeight: "bold",
  transition: "0.1s ease-in",
  borderRadius: "5px 5px 0 0",
  padding: `${theme.spacing.small}px ${theme.spacing.regular}px`,
  fontSize: 14,

  "&:hover": {
    color: theme.color.navy,
  },
}));

export default UserTypeToggler;
