import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetUserPointTransactionsAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { useParams } from "react-router-dom";
import { FETCH_SIZE } from "../../../utilities/constants";

const TYPE_TO_VARIABLE = {
  caregivers: "caregiverId",
  guardians: "guardianId",
};

function usePointInfo(type: keyof typeof TYPE_TO_VARIABLE) {
  const { userId } = useParams();
  const [fetchingMore, setFetchingMore] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [range, setRange] = useState({ from: "", to: "" });

  const { data, loading, fetchMore, refetch } = useGetUserPointTransactionsAsAdminQuery({
    variables: {
      [TYPE_TO_VARIABLE[type]]: userId,
      [TYPE_TO_VARIABLE[type === "caregivers" ? "guardians" : "caregivers"]]: null,
      first: FETCH_SIZE,
    },
  });

  const changeFrom = (event: React.ChangeEvent) => {
    const inputDate = (event.target as HTMLInputElement).value;
    setRange(prev => ({ ...prev, from: inputDate }));
  };

  const changeTo = (event: React.ChangeEvent) => {
    const inputDate = (event.target as HTMLInputElement).value;
    setRange(prev => ({ ...prev, to: inputDate }));
  };

  const applyRange = async () => {
    if (!range.to || !range.from) return window.alert("날짜를 설정해주세요.");

    try {
      setRefetching(true);
      await refetch({
        [TYPE_TO_VARIABLE[type]]: userId,
        [TYPE_TO_VARIABLE[type === "caregivers" ? "guardians" : "caregivers"]]: null,
        first: FETCH_SIZE,
        range,
      });
    } finally {
      setRefetching(false);
    }
  };

  const cancelRange = async () => {
    setRange({ from: "", to: "" });

    try {
      setRefetching(true);
      await refetch({
        [TYPE_TO_VARIABLE[type]]: userId,
        [TYPE_TO_VARIABLE[type === "caregivers" ? "guardians" : "caregivers"]]: null,
        first: FETCH_SIZE,
        range: null,
      });
    } finally {
      setRefetching(false);
    }
  };

  const onViewChange = (inView: boolean) => {
    const pageInfo = data?.getUserPointTransactionsAsAdmin.pageInfo;

    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: { first: FETCH_SIZE, after: pageInfo?.endCursor },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      data: data?.getUserPointTransactionsAsAdmin.edges,
      pageInfo: data?.getUserPointTransactionsAsAdmin.pageInfo,
      loading,
      fetchingMore,
      refetching,
      range,
    },
    operations: {
      changeFrom,
      changeTo,
      applyRange,
      cancelRange,
    },
  };
}

export default usePointInfo;
