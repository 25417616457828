import styled from "styled-components";
import Modal from "../../common/components/Modal";
import { Media } from "../../../lib/apollo/graphql/generated";
import { formatDate } from "../../../utilities/format";
import BasicButton from "../../common/components/BasicButton";
import Loader from "../../common/components/Loader";

interface ModalWithAuthorProps {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  authorName: string;
  authorUri?: string;
  createdAt: string;
  body: string;
  media: Media[];
  width?: string;
  height?: string;
  deleteFn?: () => void;
  deleteLoading?: boolean;
  deletedAt?: string;
}

function ModalWithAuthor({
  children,
  isOpen,
  onClose,
  title = "상세보기",
  authorName,
  authorUri = "/images/default_user_image.png",
  createdAt,
  body,
  media,
  width = "800px",
  height = "800px",
  deleteFn,
  deleteLoading,
  deletedAt,
}: ModalWithAuthorProps) {
  const buttonValueProps = deleteLoading ? { icon: <Loader /> } : { text: !!deletedAt ? "삭제됨" : "삭제" };

  return (
    <Modal title={title} open={isOpen} onClose={onClose} width={width} height={height}>
      <Wrapper>
        <AuthorInfo>
          <AuthorImage>
            <img src={authorUri} alt="Author Profile" />
          </AuthorImage>
          <AuthorName>{authorName}</AuthorName>
          <Date>작성일: {formatDate(createdAt, true)}</Date>
          {!!deletedAt && <Date>삭제일: {formatDate(deletedAt, true)}</Date>}
          {!!deleteFn && (
            <BasicButton
              {...buttonValueProps}
              backgroundColor="red"
              hoverBackgroundColor="lightRed"
              onClick={deleteFn}
              disabled={!!deletedAt}
            />
          )}
        </AuthorInfo>
        <Contents>
          <Body>
            <p>{body}</p>
            {media.length ? (
              <ImageGrid>
                {media.map((media: Media) => (
                  <Image key={media?.id} src={media.uri} alt="receiver record media" />
                ))}
              </ImageGrid>
            ) : (
              <NoMediaMessage>사진 없음</NoMediaMessage>
            )}
            {children}
          </Body>
        </Contents>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  gap: 10,
  height: "85%",
}));

const ImageGrid = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: 20,
  border: `1px dashed ${theme.color.gray}`,
  borderRadius: 5,
  padding: theme.spacing.small,
  overflow: "auto",
  height: 650,
}));

const Image = styled.img(({ theme }) => ({
  width: "100%",
  objectFit: "contain",
  backgroundColor: theme.color.black,
}));

const AuthorInfo = styled.div(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: 150,
}));

const Contents = styled.div(() => ({
  flex: 4,
  height: "90%",
}));

const Body = styled.div(() => ({
  maxHeight: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
}));

const AuthorImage = styled.div(() => ({
  flex: 1,
  transition: "0.1s ease-in",

  "&>img": {
    width: 100,
    height: 100,
    borderRadius: 60,
    objectFit: "cover",
  },
}));

const AuthorName = styled.p(({ theme }) => ({
  fontSize: 20,
  fontWeight: "bold",
  color: theme.color.darkGray,
  margin: 0,

  "&>span": {
    fontWeight: "normal",
  },
}));

const Date = styled.p(({ theme }) => ({
  fontSize: 10,
  fontWeight: "light",
  color: theme.color.gray,
  margin: 0,

  "&:last-of-type": {
    marginBottom: 20,
  },
}));

const NoMediaMessage = styled.div(({ theme }) => ({
  color: theme.color.gray,
  fontWeight: "bold",
  height: 400,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px dashed ${theme.color.gray}`,
  borderRadius: 5,
  padding: theme.spacing.small,
}));

export default ModalWithAuthor;
