import styled from "styled-components";
import BasicButton from "../../common/components/BasicButton";
import LabeledInput from "../../common/components/LabeledInput";
import Modal from "../../common/components/Modal";
import useAddReportReasonModal from "./AddReportReasonModal.hook";
import FieldsetInput from "../../common/components/FieldsetInput";
import Loader from "../../common/components/Loader";
import { ReportReasonType } from "../../../lib/apollo/graphql/generated";

interface AddReportReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  reportReason?: {
    id: string;
    type: ReportReasonType;
    reason: string;
    active: boolean;
  };
}

function AddReportReasonModal({ isOpen, onClose, reportReason }: AddReportReasonModalProps) {
  const {
    models: { state, loading },
    operations: { onStateChange, onSubmit },
  } = useAddReportReasonModal(onClose, reportReason);

  return (
    <Modal open={isOpen} onClose={onClose} title="신고사유 추가">
      <Form>
        <LabeledInput label="아이디" disabled />
        <LabeledInput label="신고사유" onChange={event => onStateChange("reason", event)} />
        <div>
          <GuideText>종류</GuideText>
          <ActiveStatusFieldset name="type" onChange={event => onStateChange("type", event)}>
            <FieldsetInput
              type="radio"
              id={ReportReasonType.CaregiverCommunity}
              name="type"
              text="게시물 신고"
              checked={!!(state.type === ReportReasonType.CaregiverCommunity)}
            />
            <FieldsetInput
              type="radio"
              id={ReportReasonType.CaregiverCommunityReply}
              name="type"
              text="게시물 댓글 신고"
              checked={!!(state.type === ReportReasonType.CaregiverCommunityReply)}
            />
            <FieldsetInput
              type="radio"
              id={ReportReasonType.CaregiverInfoReply}
              name="type"
              text="정보글 댓글 신고"
              checked={!!(state.type === ReportReasonType.CaregiverInfoReply)}
            />
            <FieldsetInput
              type="radio"
              id={ReportReasonType.Caregiver}
              name="type"
              text="사용자 차단"
              checked={!!(state.type === ReportReasonType.Caregiver)}
            />
          </ActiveStatusFieldset>
        </div>
        <div>
          <GuideText>활성 여부</GuideText>
          <ActiveStatusFieldset name="active" onChange={event => onStateChange("active", event)}>
            <FieldsetInput type="radio" id="active" name="active" text="활성" checked={!!state.active} />
            <FieldsetInput type="radio" id="inactive" name="active" text="비활성" checked={!state.active} />
          </ActiveStatusFieldset>
        </div>
      </Form>
      <ButtonWrapper>
        <BasicButton
          onClick={() => onSubmit()}
          text={loading ? "" : `${reportReason ? "수정" : "추가"}`}
          icon={loading && <Loader />}
        />
      </ButtonWrapper>
    </Modal>
  );
}

const Form = styled.form(({ theme }) => ({
  height: "70%",
  display: "flex",
  flexDirection: "column",
  gap: 20,
  padding: "20px 0",
}));

const GuideText = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.color.black,
  fontWeight: "bold",
}));

const ActiveStatusFieldset = styled.fieldset(() => ({
  display: "flex",
  gap: 10,
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 20,
}));

export default AddReportReasonModal;
