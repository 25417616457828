import styled from "styled-components";
import { ExtractedPolicies } from "response";
import PolicyTableItem from "./PolicyTableItem";

interface PoliciesTableProps {
  policies: ExtractedPolicies;
}

function PoliciesTables({ policies }: PoliciesTableProps) {
  return (
    <Wrapper>
      <Count>
        전체 <span>{policies.length}</span>개
      </Count>
      {policies.map((policy, index) => (
        <PolicyTableItem key={index} policy={policy} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.small,
  overflow: "auto",
  height: "90%",
}));

const Count = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",

  "&>span": {
    color: theme.color.black,
    fontWeight: "bold",
  },
}));

export default PoliciesTables;
