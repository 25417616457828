import styled from "styled-components";
import Modal from "../../common/components/Modal";
import useAddNewQuestionModal from "./AddNewQuestionModal.hook";
import BasicButton from "../../common/components/BasicButton";
import FieldsetInput from "../../common/components/FieldsetInput";

interface AddNewQuestionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AddNewQuestionModal({ isOpen, onClose }: AddNewQuestionModalProps) {
  const {
    models: { newQuestion, active },
    operations: { updateNewQuestionValue, submitNewQuestion, changeQuestionActiveStatus },
  } = useAddNewQuestionModal(onClose);

  return (
    <Modal open={isOpen} onClose={onClose} title="새로운 질문 추가">
      <NewQuestionForm onSubmit={submitNewQuestion}>
        <GuideText>새로운 질문</GuideText>
        <NewQuestionTextarea value={newQuestion} onChange={updateNewQuestionValue} />
        <GuideText>활성 상태</GuideText>
        <ActiveStatusFieldset name="active" onChange={changeQuestionActiveStatus}>
          <FieldsetInput type="radio" id="active" name="active" text="활성" checked={!!active} />
          <FieldsetInput type="radio" id="inactive" name="active" text="비활성" checked={!active} />
        </ActiveStatusFieldset>
        <ButtonWrapper>
          <BasicButton text="질문 생성" />
        </ButtonWrapper>
      </NewQuestionForm>
    </Modal>
  );
}

const NewQuestionForm = styled.form(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  height: "80%",
  padding: `${theme.spacing.regular}px ${theme.spacing.small}px`,
}));

const GuideText = styled.p(({ theme }) => ({
  margin: 0,
  color: theme.color.darkGray,
  fontWeight: "bold",
}));

const NewQuestionTextarea = styled.textarea(({ theme }) => ({
  width: "100%",
  height: "30%",
  padding: theme.spacing.small,
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 5,
  resize: "none",
}));

const ActiveStatusFieldset = styled.fieldset(() => ({
  display: "flex",
  gap: 10,
}));

const ButtonWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "auto",
}));

export default AddNewQuestionModal;
