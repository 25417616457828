import styled from "styled-components";

interface PageWrapperProps {
  children: React.ReactNode;
  isColumnFlex?: boolean;
  hasPadding?: boolean;
}

interface WrapperProps {
  $isColumnFlex: boolean;
  $hasPadding: boolean;
}

function PageWrapper({ children, hasPadding = true, isColumnFlex = false }: PageWrapperProps) {
  return (
    <Wrapper $hasPadding={hasPadding} $isColumnFlex={isColumnFlex}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, $hasPadding, $isColumnFlex }) => ({
  padding: $hasPadding ? `${theme.spacing.regular}px` : "none",
  height: "100%",
  maxHeight: "100%",
  display: "flex",
  flexDirection: $isColumnFlex ? "column" : "row",
  gap: 10,
  backgroundColor: theme.color.lightGray,
  overflowY: "auto",
}));

export default PageWrapper;
