import styled from "styled-components";

interface ItemsGridWrapperProps {
  children: React.ReactNode;
}

function ItemsGridWrapper({ children }: ItemsGridWrapperProps) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: theme.spacing.regular,
  overflow: "auto",
  padding: `${theme.spacing.small}px 0`,
}));

export default ItemsGridWrapper;
