import styled from "styled-components";
import Modal from "../../common/components/Modal";
import FieldsetInput from "../../common/components/FieldsetInput";
import useSearchFilterModal from "./SearchFilterModal.hook";
import { SearchFilters } from "global";
import Divider from "../../common/components/Divider";

interface SearchFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFilterChange: (event: React.ChangeEvent | React.FormEvent) => void;
  filters: SearchFilters;
}

function SearchFilterModal({ isOpen, onClose, onFilterChange, filters }: SearchFilterModalProps) {
  const {
    models: { cities, states },
  } = useSearchFilterModal(filters.state);

  return (
    <Modal title="검색 필터" open={isOpen} onClose={onClose} width="500px" height="500px">
      <Wrapper>
        <Divider />
        <div>
          <Label>성별:</Label>
          <GenderFieldset onChange={onFilterChange}>
            <FieldsetInput text="전체" id="" type="radio" name="gender" checked={filters.gender === ""} />
            <FieldsetInput text="남자" id="male" type="radio" name="gender" checked={filters.gender === "male"} />
            <FieldsetInput text="여자" id="female" type="radio" name="gender" checked={filters.gender === "female"} />
          </GenderFieldset>
        </div>
        <Divider />
        <LocationWrapper>
          <Label>지역:</Label>
          <LocationSelectWrapper>
            <LocationSelect name="state" onChange={onFilterChange}>
              <option value="">도</option>
              {states.map((state, index) => (
                <option key={index} value={state?.stateCode}>
                  {state?.name}
                </option>
              ))}
            </LocationSelect>
            <LocationSelect name="city" onChange={onFilterChange}>
              <option value="">시군</option>
              {cities?.length &&
                cities.map((city, index) => (
                  <option key={index} value={city?.name}>
                    {city?.name}
                  </option>
                ))}
            </LocationSelect>
          </LocationSelectWrapper>
        </LocationWrapper>
        <Divider />
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  height: "70%",
  gap: 30,
  justifyContent: "center",
}));

const GenderFieldset = styled.fieldset(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  fontSize: 18,
  fontWeight: "bold",
  color: theme.color.darkGray,
}));

const LocationSelect = styled.select(() => ({
  width: 150,
}));

const LocationWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));
const LocationSelectWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  gap: 10,
}));

const Label = styled.span(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
}));

export default SearchFilterModal;
