import styled from "styled-components";
import { Policy } from "response";
import Editor from "../../common/components/Editor";
import LabeledInfo from "../../common/components/LabeledInfo";
import usePolicyTableItem from "./PolicyTableItem.hook";

interface PolicyTableItemProps {
  policy: Policy;
}

interface BasicInformationProps {
  $isOpen: boolean;
}

function PolicyTableItem({ policy }: PolicyTableItemProps) {
  const {
    models: { isOpen },
    operations: { toggleIsOpen },
  } = usePolicyTableItem();

  return (
    <Wrapper onClick={toggleIsOpen}>
      <BasicInformation $isOpen={isOpen}>
        <LabeledInfo
          label="등록일"
          info={new Date(policy?.createdAt).toLocaleDateString()}
          fontSize="15px"
          labelWidth="50px"
        />
        <LabeledInfo label="아이디" info={policy?.id} fontSize="15px" labelWidth="50px" />
      </BasicInformation>
      {isOpen && <Editor height="70%" value={policy?.body} />}
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.regular,
  border: `1px solid ${theme.color.lightGray}`,
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "pointer",
  transition: "0.1s ease-in",

  "&:hover": {
    border: `1px solid ${theme.color.gray}`,
  },
}));

const BasicInformation = styled.div<BasicInformationProps>(({ $isOpen }) => ({
  display: "flex",
  gap: 10,
  marginBottom: $isOpen ? 20 : 0,

  "&>p": {
    margin: 0,
  },
}));

export default PolicyTableItem;
