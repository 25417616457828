import { useUpdateExampleQuestionAsAdminMutation } from "../../../lib/apollo/graphql/generated";

function useDeleteQuestionModal(questionId: string, question: string, onServerChange: () => void, onClose: () => void) {
  const [deleteExampleQuestion] = useUpdateExampleQuestionAsAdminMutation();

  const deleteQuestion = async () => {
    try {
      await deleteExampleQuestion({
        variables: {
          exampleQuestionInput: {
            id: questionId,
            active: false,
            question: question,
          },
        },
      });

      window.alert("질문이 성공적으로 삭제 되었습니다.");
      onServerChange();
      onClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    operations: { deleteQuestion },
  };
}

export default useDeleteQuestionModal;
