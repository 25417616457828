import { useState } from "react";

function useCommunityDetailContainer() {
  const [isZoomingPhoto, setIsZoomingPhoto] = useState(false);
  const [zoomedImageSrc, setZoomedImageSrc] = useState("");
  const [isDeletingPost, setIsDeletingPost] = useState(false);

  const togglePhotoModal = (event: React.MouseEvent) => {
    if (isZoomingPhoto) {
      setIsZoomingPhoto(false);
    } else {
      setZoomedImageSrc((event.target as HTMLImageElement).src);
      setIsZoomingPhoto(true);
    }
  };

  const toggleDeletePostModal = (event: React.MouseEvent) => setIsDeletingPost(prev => !prev);

  return {
    models: { isZoomingPhoto, zoomedImageSrc, isDeletingPost },
    operations: { togglePhotoModal, toggleDeletePostModal },
  };
}

export default useCommunityDetailContainer;
