import { useState } from "react";
import { useUpdateExampleQuestionAnswerAsAdminMutation } from "../../../lib/apollo/graphql/generated";
import { Answer } from "global";

function useAddNewAnswerModal(questionId: string, currentAnswers: Answer[], onServerChange: () => void) {
  const [answers, setAnswers] = useState(currentAnswers);

  const [updateExampleQuestionAnswers] = useUpdateExampleQuestionAnswerAsAdminMutation();

  const addNewAnswer = async (event: React.MouseEvent) => {
    event.stopPropagation();

    answers.forEach(async (answer, index) => {
      if (!answer.original && answer.answer) {
        try {
          const { data } = await updateExampleQuestionAnswers({
            variables: {
              exampleQuestionAnswerInput: {
                questionId,
                active: answer.active,
                answer: answer.answer,
              },
            },
          });

          setAnswers(prev =>
            prev.map((answer, loopIndex) => {
              if (loopIndex !== index) return answer;
              else return { ...answer, original: true, id: data?.updateExampleQuestionAnswerAsAdmin.id! };
            })
          );

          onServerChange();
        } catch (error) {
          console.log(error);
        }

        window.alert("새로운 답변을 성공적으로 추가했습니다.");
      }
    });
  };

  const generateFakeId = () => {
    const fakeIds: number[] = answers.map(({ id }) => (typeof id === "number" ? id : Number.MIN_SAFE_INTEGER));
    return fakeIds.length ? Math.max(...fakeIds) + 1 : 0;
  };

  const increaseNumberOfAnswers = () => {
    setAnswers(prev => [...prev, { id: generateFakeId(), answer: "", active: true }]);
  };

  const deleteAnswer = async (deleteId: string | number, answer: string) => {
    try {
      await updateExampleQuestionAnswers({
        variables: {
          exampleQuestionAnswerInput: {
            id: deleteId as string,
            questionId: questionId,
            active: false,
            answer,
          },
        },
      });

      onServerChange();
      window.alert("답변을 성공적으로 제거했습니다");
    } catch (error) {
      console.log(error);
    } finally {
      setAnswers(prev => {
        const filtered = prev.filter(({ id }) => {
          return id !== deleteId;
        });

        return filtered;
      });
    }
  };

  const changeAnswer = (event: React.ChangeEvent<HTMLInputElement>, targetId: number | string) => {
    setAnswers(prev => {
      return prev.map(answer =>
        answer.id === targetId ? { id: answer.id, active: answer.active, answer: event.target.value } : answer
      );
    });
  };

  return {
    models: { answers },
    operations: { increaseNumberOfAnswers, deleteAnswer, changeAnswer, addNewAnswer },
  };
}

export default useAddNewAnswerModal;
