import styled from "styled-components";
import RegisterFormField from "../../users/components/RegisterFormFields";
import { ChangeEvent } from "react";

interface RangeSetterProps {
  onFromChange: (event: ChangeEvent) => void;
  onToChange: (event: ChangeEvent) => void;
  to?: string;
  from?: string;
}

function RangeSetter({ onFromChange, onToChange, to, from }: RangeSetterProps) {
  return (
    <Wrapper>
      <RegisterFormField
        key="from"
        label="From"
        type="date"
        size="small"
        labelWidth="fit-content"
        inputWidth="fit-content"
        onChange={onFromChange}
        value={from}
      />
      <RegisterFormField
        key="to"
        label="To"
        type="date"
        size="small"
        labelWidth="fit-content"
        inputWidth="fit-content"
        onChange={onToChange}
        value={to}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div(() => ({
  display: "flex",
  width: "fit-content",
  marginRight: "auto",
}));

export default RangeSetter;
