import { useEffect, useState } from "react";
import {
  useGetCitiesByStateLazyQuery,
  useGetStatesQuery,
} from "../../../lib/apollo/graphql/generated";

function useAddInfoModalCitySelector(selectedStateCode: string) {
  const [stateCode, setStateCode] = useState<string | undefined>(
    selectedStateCode
  );

  const { loading, data } = useGetStatesQuery();

  const [getCitiesByState, { data: cityData }] = useGetCitiesByStateLazyQuery();

  useEffect(() => {
    if (selectedStateCode) {
      getCitiesByState({
        variables: {
          stateCode: selectedStateCode,
        },
      });
    }
  }, [selectedStateCode]);

  function onStateChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = e.target;

    setStateCode(value);
    getCitiesByState({
      variables: {
        stateCode: value,
      },
    });
  }

  return {
    models: {
      loading,
      data: data?.getStates,
      cityData: cityData?.getCitiesByState,
      stateCode,
    },
    operations: {
      onStateChange,
    },
  };
}

export default useAddInfoModalCitySelector;
