import { ModifiedReply } from "response";
import styled from "styled-components";
import { formatDate } from "../../../utilities/format";

interface ReplyProps {
  reply: ModifiedReply;
}

function Reply({ reply }: ReplyProps) {
  return (
    <Wrapper>
      <AuthorImage src={reply?.author.avatar?.uri ?? "/images/default_user_image.png"} />
      <div>
        <Info>
          <p>{reply?.author.name}</p>
          <p>{formatDate(reply?.createdAt, true)}</p>
        </Info>
        <Comment>{reply?.body}</Comment>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: 10,
}));

const AuthorImage = styled.img(() => ({
  width: 50,
  height: 50,
  borderRadius: 50,
  objectFit: "cover",
}));

const Info = styled.div(({ theme }) => ({
  display: "flex",
  gap: 10,
  height: "fit-content",
  alignItems: "end",

  "&>p": {
    margin: 0,
    fontSize: 14,
  },

  "&>p:first-of-type": {
    fontWeight: "bold",
    color: theme.color.darkGray,
  },

  "&>p:last-of-type": {
    color: theme.color.gray,
  },
}));

const Comment = styled.p(({ theme }) => ({
  margin: 0,
}));

export default Reply;
