import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useAddInfoModalCitySelector from "./AddInfoModalCitySelector.hook";
import BasicButton from "../../common/components/BasicButton";

interface AddInfoModalCitySelectorProps {
  city: {
    cityCode: string;
    stateCode: string;
  };
  onCityChange: (city: { cityCode: string; stateCode: string }) => void;
  onCityRemove: () => void;
}

function AddInfoModalCitySelector({
  city,
  onCityChange,
  onCityRemove,
}: AddInfoModalCitySelectorProps) {
  const {
    models: { loading, data, cityData, stateCode },
    operations: { onStateChange },
  } = useAddInfoModalCitySelector(city?.stateCode);

  if (loading) return <div />;

  return (
    <Wrapper>
      <Select value={stateCode} onChange={onStateChange}>
        {data?.map((state) => (
          <option key={state?.stateCode} value={state?.stateCode}>
            {state?.name}
          </option>
        ))}
      </Select>

      <Select
        value={city?.cityCode}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const { value } = e.target;
          onCityChange({
            cityCode: value,
            stateCode: stateCode!,
          });
        }}
      >
        {cityData?.map((city) => (
          <option key={city?.cityCode} value={city?.cityCode}>
            {city?.name}
          </option>
        ))}
      </Select>
      <BasicButton text="삭제" onClick={onCityRemove} />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.normal}px 0px`,
  width: "100%",
  display: "flex",
  gap: theme.spacing.small,
}));

const Select = styled.select(() => ({
  flex: 1,
}));

export default AddInfoModalCitySelector;
