import styled from "styled-components";
import BasicButton from "../../common/components/BasicButton";

interface RegisterFormFieldProps {
  label: string;
  labelWidth?: string;
  needsCheck?: boolean;
  checkFn?: (event: React.MouseEvent) => void;
  type?: string;
  size?: string;
  checkMsg?: string;
  placeholder?: string;
  options?: { value: string; text: string }[];
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent) => void;
  inputWidth?: string;
  value?: string | number;
}

interface WrapperProps {
  size: string;
}

interface LabelProps {
  $labelWidth: string;
}

interface InputProps {
  $inputWidth: string;
}

function RegisterFormField({
  type = "text",
  size = "regular",
  label,
  placeholder,
  options,
  needsCheck = false,
  checkMsg = "인증",
  checkFn = () => null,
  disabled = false,
  onChange,
  labelWidth = "90px",
  inputWidth = "65%",
  value,
}: RegisterFormFieldProps) {
  return type === "select" ? (
    <Wrapper size={size}>
      <Label $labelWidth={labelWidth}>{label}:</Label>
      <Select onChange={onChange} $inputWidth={inputWidth}>
        {options?.map(({ value, text }) => (
          <option value={value}>{text}</option>
        ))}
      </Select>
    </Wrapper>
  ) : (
    <Wrapper size={size}>
      <Label $labelWidth={labelWidth}>{label}:</Label>
      <Input
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        $inputWidth={inputWidth}
        value={value}
      />
      {needsCheck && <BasicButton text={checkMsg} onClick={checkFn} width="80px" disabled={disabled} />}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, size }) => ({
  display: "flex",
  width: size === "regular" ? "100%" : "50%",
}));

const Label = styled.p<LabelProps>(({ theme, $labelWidth }) => ({
  color: theme.color.darkGray,
  fontSize: 14,
  width: $labelWidth,
  marginRight: 10,
}));

const Input = styled.input<InputProps>(({ theme, $inputWidth }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 5,
  padding: theme.spacing.small,
  width: $inputWidth,
  marginRight: 10,
  transition: "0.3s ease-in",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  height: "80%",

  "&:disabled": {
    backgroundColor: theme.color.lightGray,
  },
}));

const Select = styled.select<InputProps>(({ theme, $inputWidth }) => ({
  border: `1px solid ${theme.color.gray}`,
  borderRadius: 5,
  padding: theme.spacing.small,
  width: $inputWidth,
}));

export default RegisterFormField;
