import styled from "styled-components";
import useAddUserModal from "./AddUserModal.hook";
import CaregiverRegisterForm from "./CaregiverRegisterForm";
import GuardianRegisterForm from "./GuardianRegisterForm";
import Modal from "../../common/components/Modal";
import ReceiverRegisterForm from "./ReceiverRegisterForm";

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FORMS = {
  caregivers: <CaregiverRegisterForm />,
  guardians: <GuardianRegisterForm />,
  receivers: <ReceiverRegisterForm />,
};

function AddUserModal({ onClose, isOpen }: AddUserModalProps) {
  const {
    models: { userType },
    operations: { changeUserType },
  } = useAddUserModal();

  return (
    <Modal open={isOpen} onClose={onClose} title="회원 추가" height="800px">
      <SelectLabel htmlFor="type">회원 종류 선택:</SelectLabel>
      <UserTypeSelect name="user-type" id="type" onChange={changeUserType}>
        <option value="select">회원 종류</option>
        <option value="caregivers">요양보호사</option>
        <option value="guardians">보호자</option>
        <option value="receivers">수급자</option>
      </UserTypeSelect>
      {FORMS[userType as keyof typeof FORMS] ?? <></>}
    </Modal>
  );
}

const SelectLabel = styled.label(({ theme }) => ({
  color: theme.color.darkGray,
  marginRight: theme.spacing.small,
}));

const UserTypeSelect = styled.select(() => ({
  margin: "10px 0",
}));

export default AddUserModal;
