import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { POLICY_TYPE, POLICY_TYPE_TEXT } from "../../../utilities/constants";
import {
  GetLatestPolicyDocument,
  PolicyType,
  useGetLatestPolicyQuery,
  useUpdatePolicyAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function usePolicyContainer() {
  const location = useLocation();

  const [isViewingPolicyHistory, setIsViewingPolicyHistory] = useState(false);
  const [type, setType] = useState(POLICY_TYPE.TERMS);
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");

  const { loading } = useGetLatestPolicyQuery({
    variables: {
      type: type as PolicyType,
    },
    onCompleted: data => {
      if (data?.getLatestPolicy?.body) {
        setBody(data?.getLatestPolicy?.body);
        setTitle(POLICY_TYPE_TEXT[data?.getLatestPolicy.type]);
      }
    },
  });

  const [updatePolicyAsAdmin] = useUpdatePolicyAsAdminMutation({
    variables: {
      policyInput: {
        type: type as PolicyType,
        body,
      },
    },
    refetchQueries: () => [
      {
        query: GetLatestPolicyDocument,
        variables: {
          type: type as PolicyType,
        },
      },
    ],
  });

  useEffect(() => {
    const newType = location?.pathname?.includes("terms") ? POLICY_TYPE.TERMS : POLICY_TYPE.PRIVACY;

    if (type !== newType) {
      setType(newType);
      setBody("");
    }
  }, [location]);

  const onBodyChange = (value: string) => {
    setBody(value);
  };

  const onSubmit = () => {
    updatePolicyAsAdmin();
  };

  const togglePolicyHistoryModal = () => {
    setIsViewingPolicyHistory(prev => !prev);
  };

  return {
    models: {
      loading,
      body,
      type,
      title,
      isViewingPolicyHistory,
    },
    operations: {
      onBodyChange,
      onSubmit,
      togglePolicyHistoryModal,
    },
  };
}

export default usePolicyContainer;
