import imageCompression from "browser-image-compression";
import { useEffect, useState } from "react";
import { COMPRESSION_OPTION } from "../../../utilities/constants";
import {
  AnnouncementInput,
  GetAnnouncementsDocument,
  useGetAnnouncementLazyQuery,
  useUpdateAnnouncementAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

interface State {
  title: string;
  summary: string;
  thumbnailState: File | null;
  body: string;
  active: boolean;
  onBanner: boolean;
}

function useUpdateAnnouncementModal(announcementId: string | null) {
  const [state, setState] = useState<State>({
    title: "",
    summary: "",
    thumbnailState: null,
    body: "",
    active: true,
    onBanner: true,
  });

  const [getAnnouncement, { loading, data }] = useGetAnnouncementLazyQuery({
    onCompleted: (data) => {
      if (data?.getAnnouncement) {
        setState((prev) => ({
          ...prev,
          title: data?.getAnnouncement?.title || "",
          summary: data?.getAnnouncement?.summary || "",
          body: data?.getAnnouncement?.body || "",
          active: data?.getAnnouncement?.active,
          onBanner: data?.getAnnouncement?.onBanner,
        }));
      }
    },
  });

  const [updateAnnouncementAsAdmin, { loading: updateLoading }] =
    useUpdateAnnouncementAsAdminMutation({
      refetchQueries: !announcementId
        ? () => [
            {
              query: GetAnnouncementsDocument,
              variables: {
                onBanner: null,
                active: null,
                first: 9,
              },
            },
          ]
        : undefined,
    });

  useEffect(() => {
    if (!!announcementId) {
      getAnnouncement({
        variables: {
          announcementId,
        },
      });
    }
  }, [announcementId]);

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof state
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onBodyChange(value: string) {
    setState((prev) => ({
      ...prev,
      body: value,
    }));
  }

  function onCheckboxChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof state
  ) {
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: checked,
    }));
  }

  function onMediaChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (!files) return;

    const file = files[0];

    setState((prev) => ({
      ...prev,
      thumbnailState: file,
    }));
  }

  function isSubmitDisabled() {
    const { title, body } = state;

    return !(!!title && !!body);
  }

  async function onSubmit() {
    const { title, body, thumbnailState, summary, onBanner, active } = state;

    const announcementInput: AnnouncementInput = {
      id: announcementId,
      title,
      body,
      summary,
      onBanner,
      active,
    };

    if (thumbnailState) {
      const compressedFile = await imageCompression(
        thumbnailState,
        COMPRESSION_OPTION
      );
      announcementInput.thumbnailMediaInput = { file: compressedFile };
    }

    updateAnnouncementAsAdmin({
      variables: {
        announcementInput,
      },
    });
  }

  return {
    models: {
      state,
      data: data?.getAnnouncement,
      loading,
      updateLoading,
    },
    operations: {
      onInputChange,
      onBodyChange,
      onCheckboxChange,
      onMediaChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateAnnouncementModal;
