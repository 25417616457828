import { useState } from "react";

function useServiceItem() {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const openDeleteModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDeleting(true);
  };

  const closeDeleteModal = () => {
    setIsDeleting(false);
  };

  const onHover = () => {
    setIsHovered(true);
  };

  const onHoverExit = () => {
    setIsHovered(false);
  };

  return {
    models: { isDeleting, isHovered },
    operations: { closeDeleteModal, openDeleteModal, onHover, onHoverExit },
  };
}

export default useServiceItem;
