import styled from "styled-components";
import { GeneralDataObject } from "global";
import ColoredLabel from "./ColoredLabel";
import NullTableData from "./NullTableData";

const LABELS = {
  positive: <ColoredLabel text="있음" backgroundColor="green" />,
  negative: <ColoredLabel text="없음" backgroundColor="red" />,
  MALE: <ColoredLabel icon={<i className="bi bi-gender-male" />} backgroundColor="blue" />,
  FEMALE: <ColoredLabel icon={<i className="bi bi-gender-female" />} backgroundColor="red" />,
};

interface DataTableBodyRowProps<TDataRow extends GeneralDataObject> {
  row: TDataRow;
  onDataClick?: (id: string | number) => void;
  dataLength: number;
  index: number;
  ignores?: string[];
  keys: string[];
  pastMax: boolean;
}

interface WrapperProps {
  $dataLength: number;
  $index: number;
  $isDeleted: boolean;
}

function DataTableBodyRow<TDataRow extends GeneralDataObject>({
  row,
  onDataClick = () => {},
  index,
  keys,
  dataLength,
  pastMax,
}: DataTableBodyRowProps<TDataRow>) {
  const isDeleted = !!row["삭제일"];

  return (
    <Wrapper $index={index} $isDeleted={isDeleted} $dataLength={dataLength} onClick={() => onDataClick(row.아이디)}>
      {keys.map((key, index) => {
        const value = row[key as keyof typeof row];

        if (pastMax) return <td key={index}></td>;
        if (key === "성별") return <td key={index}>{LABELS[value as keyof typeof LABELS]}</td>;
        if (key === "자격번호" || key === "자격증 사진" || key === "등록번호" || key === "등록증 사진")
          return <td key={index}>{LABELS[value ? "positive" : "negative"]}</td>;

        return <td key={index}>{value ? value : <NullTableData />}</td>;
      })}
    </Wrapper>
  );
}

const Wrapper = styled.tr<WrapperProps>(({ theme, $dataLength, $index, $isDeleted }) => ({
  borderBottom: `1px solid ${theme.color.gray}`,
  transition: "0.1s ease-in",
  cursor: "pointer",
  height: 50,
  minHeight: 50,
  textAlign: "center",
  fontSize: 13,
  backgroundColor: $isDeleted ? theme.color.minimal : "none",

  "&>td": {
    padding: theme.spacing.small,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  "&:last-of-type": {
    borderBottom: "none",
  },

  ...($dataLength < $index + 1 && {
    borderBottom: "none",
    cursor: "initial",
  }),

  ...($dataLength > $index && {
    "&:hover": {
      color: theme.color.navy,
      fontWeight: "bold",
    },
  }),
}));

export default DataTableBodyRow;
