import styled from "styled-components";
import { FormattedExampleQuestion } from "response";
import useQuestionBox from "./QuestionBox.hook";
import AnswerList from "./AnswerList";
import TwoButtons from "../../common/components/TwoButtons";
import AddNewAnswerModal from "./AddNewAnswerModal";
import DeleteQuestionModal from "./DeleteQuestionModal";

interface QuestionBoxProps {
  number: number | string;
  question: FormattedExampleQuestion;
}

interface WrapperProps {
  $isActive: boolean;
}

function QuestionBox({ number, question }: QuestionBoxProps) {
  const isActive = question.Active;

  const {
    models: { isOpen, isAddingAnswer, currentAnswers, isDeletingQuestion },
    operations: {
      toggleQuestionBox,
      openAddNewAnswerModal,
      closeAddNewAnswerModal,
      closeDeleteQuestionModal,
      openDeleteQuestionModal,
      updateIsDirty,
    },
  } = useQuestionBox(question.__answers);

  return (
    <>
      {isActive && (
        <>
          <AddNewAnswerModal
            isOpen={isAddingAnswer}
            onClose={closeAddNewAnswerModal}
            questionId={question["아이디"]}
            currentAnswers={currentAnswers}
            onServerChange={updateIsDirty}
          />
          <DeleteQuestionModal
            isOpen={isDeletingQuestion}
            onClose={closeDeleteQuestionModal}
            question={question}
            onServerChange={updateIsDirty}
          />
        </>
      )}
      <Wrapper $isActive={isActive} onClick={() => toggleQuestionBox()}>
        <QuestionBoxHeader>
          <div>
            <Question>
              <span>{number}. </span>
              {question["질문"]}
            </Question>
            <AnswerCount>총 답변 수: {question.__answers.length}</AnswerCount>
          </div>
          <div>
            {isActive && (
              <TwoButtons
                icon1={<i className="bi bi-plus-lg" />}
                icon2={<i className="bi bi-trash3" />}
                background1="green"
                hoverBackground1="lightGreen"
                background2="red"
                hoverBackground2="lightRed"
                buttonWidth="30px"
                buttonHeight="70%"
                fontSize="10px"
                onClick1={openAddNewAnswerModal}
                onClick2={openDeleteQuestionModal}
              />
            )}
          </div>
        </QuestionBoxHeader>
        <AnswerList isOpen={isOpen} answers={question.__answers} />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div<WrapperProps>(({ theme, $isActive }) => ({
  padding: theme.spacing.regular,
  border: `1px solid ${theme.color.lightGray}`,
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: $isActive ? "none" : theme.color.lightGray,

  "&:hover": {
    border: `1px solid ${theme.color.gray}`,
  },
}));

const Question = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontWeight: "bold",
  fontSize: 18,
  margin: 0,
}));

const AnswerCount = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontSize: 13,
  fontWeight: "bold",
  margin: 0,
}));

const QuestionBoxHeader = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export default QuestionBox;
