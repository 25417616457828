import { useState } from "react";
import { ProfileInput, Gender, useCreateGuardianAsAdminMutation } from "../../../lib/apollo/graphql/generated";

function useGuardianRegisterForm() {
  const [formValues, setFormValues] = useState<ProfileInput>({
    phone: "",
    gender: Gender.Male,
    name: "",
    username: "",
    dateOfBirth: "",
    avatarInput: {
      file: "",
      thumbnailFile: "",
    },
  });

  const [createNewGuardian, { loading }] = useCreateGuardianAsAdminMutation();

  // Updates formValue state onChange of inputs used in the register form
  const changeFormValue = (event: React.ChangeEvent, key: string) => {
    const value = (event.target as HTMLInputElement).value;

    if (key === "gender") {
      return setFormValues(prev => ({ ...prev, gender: value === "Male" ? Gender.Male : Gender.Female }));
    }

    setFormValues(prev => ({ ...prev, [key]: value }));
  };

  // Changes image files
  const changeImageFileValues = (event: React.ChangeEvent, key: string) => {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    setFormValues(prev => ({ ...prev, [key]: { file, thumbnailFile: file } }));
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!formValues.gender || !formValues.phone || !formValues.name || !formValues.username || !formValues.dateOfBirth)
      return window.alert("필수 정보를 모두 입력하세요.");

    const phoneNumberWithoutDashes = formValues.phone?.replace(/-/g, "");
    if (phoneNumberWithoutDashes.length !== 11) return window.alert("유효한 전화번호를 입력하세요.");

    const phoneNumberWith82 = phoneNumberWithoutDashes.replace(/0/, "+82");

    try {
      const { data } = await createNewGuardian({
        variables: {
          phoneNumber: phoneNumberWith82,
          profileInput: { ...formValues, phone: phoneNumberWithoutDashes },
        },
      });

      if (!loading) console.log(data?.createGuardianAsAdmin?.id, ":", data?.createGuardianAsAdmin?.name);

      window.alert("새로운 회원이 추가 되었습니다!");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    operations: { changeFormValue, changeImageFileValues, onSubmit },
  };
}

export default useGuardianRegisterForm;
