import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCaregiverCommunitiesByAuthorAsAdminQuery } from "../../../lib/apollo/graphql/generated";
import { FETCH_SIZE } from "../../../utilities/constants";
import { useInView } from "react-intersection-observer";

function usePostInfo() {
  const { userId } = useParams();
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore } = useGetCaregiverCommunitiesByAuthorAsAdminQuery({
    variables: {
      caregiverId: userId!,
      first: FETCH_SIZE,
    },
  });

  const onViewChange = (inView: boolean) => {
    const pageInfo = data?.getCaregiverCommunitiesByAuthorAsAdmin.pageInfo;

    if (inView && !fetchingMore && pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: { first: FETCH_SIZE, after: pageInfo?.endCursor },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  };

  const [fetchMoreRef] = useInView({
    threshold: 0.3,
    onChange: onViewChange,
  });

  return {
    ref: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getCaregiverCommunitiesByAuthorAsAdmin.edges,
      pageInfo: data?.getCaregiverCommunitiesByAuthorAsAdmin.pageInfo,
      fetchingMore,
    },
    operations: {},
  };
}

export default usePostInfo;
