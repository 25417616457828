import Modal from "../../common/components/Modal";
import usePolicyHistoryModal from "./PolicyHistoryModal.hook";
import Loader from "../../common/components/Loader";
import { POLICY_TYPE_TEXT } from "../../../utilities/constants";
import { PolicyType } from "../../../lib/apollo/graphql/generated";
import PoliciesTables from "./PoliciesTable";

interface PolicyHistoryModalProps {
  onClose: () => void;
  isOpen: boolean;
  type: PolicyType;
}

function PolicyHistoryModal({ onClose, isOpen, type }: PolicyHistoryModalProps) {
  const {
    models: { loading, policies },
  } = usePolicyHistoryModal(type);

  return (
    <Modal title={`${POLICY_TYPE_TEXT[type]} 전체보기`} onClose={onClose} open={isOpen} height="95%" width="80%">
      {loading ? <Loader /> : <PoliciesTables policies={policies} />}
    </Modal>
  );
}

export default PolicyHistoryModal;
